import { GRAY_700, GRAY_900 } from 'constants/styling/theme'
import { ThermalBridgeType, useConstructionMethod } from './_main/ConstructionMethod.context'
import { Trans, useTranslation } from 'react-i18next'

import React from 'react'
import { SectionedBorderBox } from 'components/common/SectionedBorderBox'
import Stack from '@mui/material/Stack'
import { ToggleButton } from 'components/common/ToggleButton'
import Typography from '@mui/material/Typography'

/**
 * @component ThermalBridges renders options for the user to select the type of thermal bridges.
 *
 * @example
 *   <ThermalBridges />
 */
export const ThermalBridges: React.FC = () => {

  const { t } = useTranslation(['purchase_flow'], { keyPrefix: 'energy_certificate_step.construction_method_page' })

  const {
    thermalBridgeType,
    setThermalBridgeType
  } = useConstructionMethod()

  return (
    <SectionedBorderBox
      title={
        <Typography variant='text-md' fontWeight={600} color={GRAY_900}>
          {t('thermal_bridge_title')}
        </Typography>
      }
    >
      <Stack
        sx={{
          flexDirection: { xs: 'column', lg: 'row' }
        }}
        width="100%"
      >
        {/* THERMAL BRIDGES OPTIONS */}
        <Stack gap={2} flexDirection="row" flexWrap="nowrap" justifyContent="space-between">
          {Object.values(ThermalBridgeType).map((type) => (
            <ToggleButton
              key={type}
              isSelected={thermalBridgeType === type}
              label={
                <Stack textAlign='center'>

                  <Typography variant='text-md' fontWeight="medium" color={GRAY_900}>
                    {t(`thermal_bridge_type.${type}`)}
                  </Typography>

                  <Typography variant="text-xs" color={GRAY_700}>
                    <Trans t={t} i18nKey={`thermal_bridge_type_description.${type}`}><span>{'<'}</span></Trans>
                  </Typography>

                </Stack>
              }
              sx={{ width: '26.4rem', height: '10.2rem', justifyContent: 'center' }}
              onClick={() => setThermalBridgeType(type)}
              typographyVariant='text-md'
            />
          ))}
        </Stack>

      </Stack>

    </SectionedBorderBox>
  )
}

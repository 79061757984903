import { usePurchaseFlowConfig, usePurchaseFlowProducts } from '../../_main/contexts'

import { DocumentUploadType } from 'constants/documents'
import { PurchaseFlowEndpoint } from 'constants/API'
import constate from 'constate'
import { useFileAPI } from 'components/common/FileAPI'
import { useState } from 'react'

export enum EnergyCertificateReason {
  NEW_RENTAL = 'NEW_RENTAL',
  SALE = 'SALE',
  OTHER = 'OTHER'
}

export enum AdditionalInformationUploadType {
  EXTERIOR_VIEW = 'EXTERIOR_VIEW',
  HEATER_NAMEPLATE = 'HEATER_NAMEPLATE',
  CONSTRUCTION_DESCRIPTION = 'CONSTRUCTION_DESCRIPTION'
}

export const [AdditionalInformationContextProvider, useAdditionalInformation] = constate(() => {

  const { sessionId } = usePurchaseFlowConfig()
  const { selectedProducts } = usePurchaseFlowProducts()

  const [energyCertificateReason, setEnergyCertificateReason] = useState<EnergyCertificateReason>(EnergyCertificateReason.NEW_RENTAL)
  const [message, setMessage] = useState<string>('')

  const useAdditionalInformationUpload = (type: AdditionalInformationUploadType) => useFileAPI(type, {
    uploadUrlResolver: sessionId && selectedProducts ? (fileEntry, api) => (api.post(
      PurchaseFlowEndpoint.GET_DOCUMENT_UPLOAD_URL,
      { sessionId: sessionId!.toString() },
      {
        productId: Object.values(selectedProducts)[0].id.toString(),
        documentMetadata: {
          filename: fileEntry.originalFilename,
          contentType: fileEntry.fileObject.type,
          type: DocumentUploadType.INPUT,
        }
      },
      true
    ))
      : undefined,
    deleteHandler: sessionId && selectedProducts ? (_, api) => api.delete(
      PurchaseFlowEndpoint.DELETE_UPLOADED_DOCUMENT,
      { sessionId: sessionId!.toString() },
      true
    ) : undefined
  })

  const exteriorViewUpload = useAdditionalInformationUpload(AdditionalInformationUploadType.EXTERIOR_VIEW)
  const heaterNameplateUpload = useAdditionalInformationUpload(AdditionalInformationUploadType.HEATER_NAMEPLATE)
  const constructionDescriptionUpload = useAdditionalInformationUpload(AdditionalInformationUploadType.CONSTRUCTION_DESCRIPTION)

  return {
    message,
    setMessage,
    energyCertificateReason,
    setEnergyCertificateReason,
    useAdditionalInformationUpload,
    exteriorViewUpload,
    heaterNameplateUpload,
    constructionDescriptionUpload
  }
})

import { HeatingCombinationType, useVentilationAndHeating } from '../_main/VentilationAndHeating.context'
import React, { useMemo } from 'react'
import { Stack, Typography } from '@mui/material'

import { GRAY_700 } from 'constants/styling/theme'
import { SelectableButtonsGrid } from 'components/common/SelectableButtonsGrid'
import { ToggleFilterValue } from 'components/common/ToggleFilter'
import { VentilationHeatingProps } from '../_main/VentilationAndHeating.component'
import { useTranslation } from 'react-i18next'

/**
 * @component HeatingCombinedWith 
 * @description Renders options for the user to provide information about the heating combination.
 * 
 * @example
 * <HeatingCombinedWith id="heating-system-1" />
 */
export const HeatingCombinedWith: React.FC<VentilationHeatingProps> = ({ id }) => {
  const { t } = useTranslation(['purchase_flow'], { keyPrefix: 'energy_certificate_step.energy_heating_page.heating_system' })
  const { currentHeatingSystem, handleUpdateHeatingSystemItem } = useVentilationAndHeating()
  const { heatingCombinedWith } = currentHeatingSystem(id) || {}

  // Available heating combinations system types mapped to objects
  const heatingCombinedWithToggleValues: ToggleFilterValue<HeatingCombinationType>[] = useMemo(
    () => {
      const availableTypes = Object.values(HeatingCombinationType)

      return availableTypes.map((type) => {
        return {
          value: type,
          displayName: t(`heating_combined_with_type.${type}`),
        }
      })
      // [t] is not a dependency because it is a constant function
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []
  )

  return (
    <Stack gap={1.6} width={{ xs: '100%' }}>

      <Typography variant='text-sm' fontWeight="medium" color={GRAY_700}>
        {t('heating_combined_with_title')}
      </Typography>

      <Stack gap={1.6} width={{ xs: '100%', lg: 'max-content' }}>
        <SelectableButtonsGrid
          values={heatingCombinedWithToggleValues}
          selectedValue={Array.from(heatingCombinedWith || [])}
          onSelect={type => handleUpdateHeatingSystemItem(id, { heatingCombinedWith: new Set([...(heatingCombinedWith || []), type]) })}
          onUnselect={type => handleUpdateHeatingSystemItem(id, { heatingCombinedWith: new Set([...heatingCombinedWith || []].filter(item => item !== type)) })}
          showCheckbox
          activeButtonClickable
        />
      </Stack>

    </Stack>
  )
}

import { useEffect, useMemo, useState } from 'react'

import constate from 'constate'
import { debounce } from 'lodash'
import { Nullable } from 'models/helpers'

export enum BuildingLocationType {
  FREESTANDING = 'FREESTANDING',
  TERRACED_HOUSE = 'TERRACED_HOUSE',
  SEMI_DETACHED_HOUSE = 'SEMI_DETACHED_HOUSE',
}

export enum BuildingType {
  SINGLE_FAMILY_HOUSE = 'SINGLE_FAMILY_HOUSE',
  TWO_FAMILY_HOUSE = 'TWO_FAMILY_HOUSE',
  APARTMENT_BUILDING = 'APARTMENT_BUILDING',
  MIXED_BUILDING = 'MIXED_BUILDING',
}

export enum BasementType {
  NO_BASEMENT = 'NO_BASEMENT',
  HEATED_BASEMENT = 'HEATED_BASEMENT',
  UNHEATED_BASEMENT = 'UNHEATED_BASEMENT',
}

export enum VentilationType {
  STANDARD_WINDOW_VENTILATION = 'STANDARD_WINDOW_VENTILATION',
  SHAFT_VENTILATION = 'SHAFT_VENTILATION',
  VENTILATION_SYSTEM_WITH_HEAT_RECOVERY = 'VENTILATION_SYSTEM_WITH_HEAT_RECOVERY',
  VENTILATION_SYSTEM_WITHOUT_HEAT_RECOVERY = 'VENTILATION_SYSTEM_WITHOUT_HEAT_RECOVERY',
}

export const [PropertyInformationContextProvider, usePropertyInformation] = constate(() => {
  const [buildingType, setBuildingType] = useState<Nullable<BuildingType>>(null)
  const [buildingLocationType, setBuildingLocationType] = useState<Nullable<BuildingLocationType>>(null)
  const [residentialUnit, setResidentialUnit] = useState<number>(1)
  const [commercialArea, setCommercialArea] = useState<string>('')
  const [constructionYear, setConstructionYear] = useState<string>('')
  const [isConstructionConfirmed, setIsConstructionConfirmed] = useState<boolean>(false)
  const [livingSpace, setLivingSpace] = useState<string>('')
  const [yearOfRenovation, setYearOfRenovation] = useState<string>('')
  const [basementType, setBasementType] = useState<BasementType>(BasementType.NO_BASEMENT)
  const [ventilationType, setVentilationType] = useState<VentilationType>(VentilationType.STANDARD_WINDOW_VENTILATION)

  const [debouncedConstructionYear, setDebouncedConstructionYear] = useState(constructionYear)
  const [debouncedResidentialUnit, setDebouncedResidentialUnit] = useState(residentialUnit)

  useEffect(() => {
    const handler = debounce(() => {
      setDebouncedConstructionYear(constructionYear)
      setDebouncedResidentialUnit(residentialUnit)
    }, 500)

    handler()

    return () => {
      handler.cancel()
    }
  }, [constructionYear, residentialUnit])

  const isShowConstructionNote = useMemo(() =>
    debouncedResidentialUnit < 5
    && parseInt(debouncedConstructionYear) < 1978
    && debouncedConstructionYear.length >= 4,
    [debouncedConstructionYear, debouncedResidentialUnit])

  return {
    buildingType,
    setBuildingType,
    buildingLocationType,
    setBuildingLocationType,
    residentialUnit,
    setResidentialUnit,
    constructionYear,
    setConstructionYear,
    basementType,
    setBasementType,
    ventilationType,
    setVentilationType,
    livingSpace,
    setLivingSpace,
    yearOfRenovation,
    setYearOfRenovation,
    commercialArea,
    setCommercialArea,
    isConstructionConfirmed,
    setIsConstructionConfirmed,
    isShowConstructionNote
  }
})

import { useMemo, useState } from 'react'

import constate from 'constate'

/** Enum of available information types */
export enum InformationType {
  PROPERTY_INFORMATION = 'PROPERTY_INFORMATION',
  BUILDING_LAYOUT = 'BUILDING_LAYOUT',
  CONSTRUCTION_METHOD = 'CONSTRUCTION_METHOD',
  ENERGY_AND_HEATING = 'ENERGY_AND_HEATING',
  ADDITIONAL_INFORMATION = 'ADDITIONAL_INFORMATION',
}

export const [EnergyCertificateStepContextProvider, useEnergyCertificateStep] = constate(() => {
  const [infoType, setInfoType] = useState<InformationType>(InformationType.PROPERTY_INFORMATION)

  const isPropertyInformationValid = useMemo(() => true, [])
  const isBuildingLayoutValid = useMemo(() => true, [])
  const isConstructionMethodValid = useMemo(() => true, [])
  const isEnergyAndHeatingValid = useMemo(() => true, [])

  return {
    infoType,
    setInfoType,
    isPropertyInformationValid,
    isBuildingLayoutValid,
    isConstructionMethodValid,
    isEnergyAndHeatingValid,
  }
})

import constate from 'constate'
import { Nullable } from 'models/helpers'
import { useState } from 'react'

export enum GlobalShapeType {
  SOLID_CONSTRUCTION = 'SOLID_CONSTRUCTION',
  LIGHTWEIGHT_CONSTRUCTION = 'LIGHTWEIGHT_CONSTRUCTION',
}

export enum SolidConstructionType {
  DOUBLE_SHELL_WALL_STRUCTURES_WITHOUT_INSULATION_LAYER = 'DOUBLE_SHELL_WALL_STRUCTURES_WITHOUT_INSULATION_LAYER',
  OTHER_SOLID_WALL_CONSTRUCTIONS_UP_TO_20_CM_WALL_THICKNESS = 'OTHER_SOLID_WALL_CONSTRUCTIONS_UP_TO_20_CM_WALL_THICKNESS',
  OTHER_SOLID_WALL_STRUCTURES_WITH_A_WALL_THICKNESS_OF_MORE_THAN_20_CM = 'OTHER_SOLID_WALL_STRUCTURES_WITH_A_WALL_THICKNESS_OF_MORE_THAN_20_CM',
  SOLID_BRICK_WALL_OVER_30_CM_THICK = 'SOLID_BRICK_WALL_OVER_30_CM_THICK',
  SOLID_WALL_MADE_OF_PERFORATED_WOODEN_BRICKS = 'SOLID_WALL_MADE_OF_PERFORATED_WOODEN_BRICKS',
  SOLID_WALL_MADE_OF_SOLID_BRICKS_UP_TO_20_CM_WALL_THICKNESS = 'SOLID_WALL_MADE_OF_SOLID_BRICKS_UP_TO_20_CM_WALL_THICKNESS',
  SOLID_WALL_MADE_OF_SOLID_BRICKS_WITH_20_30_CM_WALL_THICKNESS = 'SOLID_WALL_MADE_OF_SOLID_BRICKS_WITH_20_30_CM_WALL_THICKNESS'
}

export enum LightweightConstructionType {
  HALF_TIMBERED_WALL_WITH_CLAY_BRICK_INFILL = 'HALF_TIMBERED_WALL_WITH_CLAY_BRICK_INFILL',
  HALF_TIMBERED_WITH_SOLID_BRICK_INFILL = 'HALF_TIMBERED_WITH_SOLID_BRICK_INFILL',
  OTHER_WOODEN_CONSTRUCTION = 'OTHER_WOODEN_CONSTRUCTION',
  SOLID_WOOD_WALL = 'SOLID_WOOD_WALL'
}

export enum RoofConditionType {
  NOT_FINISHED_TOP_FLOOR_INSULATED = 'NOT_FINISHED_TOP_FLOOR_INSULATED',
  NOT_FINISHED_UNINSULATED = 'NOT_FINISHED_UNINSULATED',
  NOT_REMOVED_ROOF_INSULATED = 'NOT_REMOVED_ROOF_INSULATED',
  REMOVED_ROOF_INSULATED = 'REMOVED_ROOF_INSULATED',
  REMOVED_UNINSULATED = 'REMOVED_UNINSULATED'
}

export enum RoofPitchType {
  FLAT = 'FLAT',
  MEDIUM = 'MEDIUM',
  STEEP = 'STEEP'
}

export enum BasementType {
  NO_CELLAR = 'NO_CELLAR',
  UNHEATED_BASEMENT_NO_WINDOW = 'UNHEATED_BASEMENT_NO_WINDOW',
  UNHEATED_BASEMENT_WINDOWS = 'UNHEATED_BASEMENT_WINDOWS',
  HEATED_CELLAR_NO_WINDOWS = 'HEATED_CELLAR_NO_WINDOWS',
  HEATED_CELLAR_WINDOWS = 'HEATED_CELLAR_WINDOWS',
}

export enum BasementCeilingFloorType {
  BASEMENT_CEILING_SOLID_REINFORCED_CONCRETE = 'BASEMENT_CEILING_SOLID_REINFORCED_CONCRETE',
  BASEMENT_CEILING_AS_WOODEN_BEAM_CEILING = 'BASEMENT_CEILING_AS_WOODEN_BEAM_CEILING',
  BASEMENT_CEILING_AS_BRICK_OR_HOLLOW_BRICK_CONSTRUCTION = 'BASEMENT_CEILING_AS_BRICK_OR_HOLLOW_BRICK_CONSTRUCTION',
}

export enum WindowType {
  WOODEN_WINDOWS_SINGLE_GLAZING = 'WOODEN_WINDOWS_SINGLE_GLAZING',
  WOODEN_WINDOWS_DOUBLE_GLAZING = 'WOODEN_WINDOWS_DOUBLE_GLAZING',
  PLASTIC = 'PLASTIC',
  ALU_STEEL = 'ALU_STEEL',
  TRIPLE_THERMAL_INSULATION_GLAZING = 'TRIPLE_THERMAL_INSULATION_GLAZING'
}

export enum ThermalBridgeType {
  DEFAULT_WITHOUT_TAKING_THERMAL_BRIDGES_INTO_ACCOUNT = 'DEFAULT_WITHOUT_TAKING_THERMAL_BRIDGES_INTO_ACCOUNT',
  NEW_BUILDING_WITH_DESIGN_OF_THE_COMPONENT_CONNECTIONS_ACCORDING_TO_DIN_4108_2 = 'NEW_BUILDING_WITH_DESIGN_OF_THE_COMPONENT_CONNECTIONS_ACCORDING_TO_DIN_4108_2',
  INTERIOR_WALL_INSULATION_OVER_50_PERCENT_WITH_INTERIOR_WALL_INSULATION = 'INTERIOR_WALL_INSULATION_OVER_50_PERCENT_WITH_INTERIOR_WALL_INSULATION'
}

export const [ConstructionMethodContextProvider, useConstructionMethod] = constate(() => {
  const [globalShapeType, setGlobalShapeType] = useState<GlobalShapeType>(GlobalShapeType.SOLID_CONSTRUCTION)
  const [lightweightConstructionType, setLightweightConstructionType] = useState<LightweightConstructionType | null>(null)
  const [solidConstructionType, setSolidConstructionType] = useState<SolidConstructionType | null>(null)
  const [roofConditionType, setRoofConditionType] = useState<RoofConditionType | null>(null)
  const [basementType, setBasementType] = useState<BasementType | null>(null)
  const [basementCeilingFloorType, setBasementCeilingFloorType] = useState<BasementCeilingFloorType>(BasementCeilingFloorType.BASEMENT_CEILING_SOLID_REINFORCED_CONCRETE)
  const [windowType, setWindowType] = useState<WindowType | null>(null)
  const [thermalBridgeType, setThermalBridgeType] = useState<ThermalBridgeType>(ThermalBridgeType.DEFAULT_WITHOUT_TAKING_THERMAL_BRIDGES_INTO_ACCOUNT)
  const [subsequentInsulation, setSubsequentInsulation] = useState<Nullable<number>>(null)
  const [roofPitchType, setRoofPitchType] = useState<RoofPitchType>(RoofPitchType.FLAT)
  const [numberOfFullFloors, setNumberOfFullFloors] = useState<Nullable<number>>(null)
  const [floorHeight, setFloorHeight] = useState<Nullable<number>>(null)
  const [yearOfManufacture, setYearOfManufacture] = useState<Nullable<number>>(null)

  return {
    globalShapeType,
    setGlobalShapeType,
    basementType,
    setBasementType,
    lightweightConstructionType,
    setLightweightConstructionType,
    roofConditionType,
    setRoofConditionType,
    solidConstructionType,
    setSolidConstructionType,
    basementCeilingFloorType,
    setBasementCeilingFloorType,
    windowType,
    setWindowType,
    thermalBridgeType,
    setThermalBridgeType,
    subsequentInsulation,
    setSubsequentInsulation,
    roofPitchType,
    setRoofPitchType,
    numberOfFullFloors,
    setNumberOfFullFloors,
    floorHeight,
    setFloorHeight,
    yearOfManufacture,
    setYearOfManufacture,
  }
})

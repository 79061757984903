import { Auth0Roles, Auth0User } from 'models/auth'
import { FC, createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import createAuth0Client, { RedirectLoginOptions } from '@auth0/auth0-spa-js'
import { getInitRoles, getRolesFromUser } from './authRolesCompanion'
import { logAnalyticsEvent, trackUser, unTrackUser } from '../analytics/analyticsUtils'

import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client'
import { Nullable } from 'models/helpers'
import history from 'utils/helpers/history'
import { jsonEqual } from 'utils/validators'
import { reloadAllResources } from 'translations/i18n'

/** Type of the AppState returned by Auth0 */
type AppState = Nullable<{
  [x: string]: any
  targetUrl: any
}>

type LoginOptions = RedirectLoginOptions<AppState>

export interface LogoutOptions {
  returnTo: string
}

export interface Auth0ContextInterface {
  isAuthenticated: boolean,
  user: Auth0User,
  roles: Auth0Roles,
  loading: boolean,
  popupOpen: boolean,
  refreshUser: () => Promise<Auth0User>,
  loginWithPopup: () => void,
  loginWithRedirect: (options?: LoginOptions) => void,
  handleRedirectCallback?: (appState?: AppState) => void,
  getIdTokenClaims: (...p: any[]) => any,
  getTokenSilently: (...p: any[]) => any,
  getTokenWithPopup: (...p: any[]) => any,
  logout: (...p: any[]) => any,
}

/** A function that routes the user to the right place after login */
const DEFAULT_REDIRECT_CALLBACK = (appState?: AppState) => {
  const path = window.location.pathname
  const url = appState?.targetUrl ?? path
  history.push(url)
}

export const clearAuth0LocalStorage = () => {
  for (const key in window.localStorage) {
    if (key.includes('auth0')) {
      window.localStorage.removeItem(key)
      window.location.reload()
    }
  }
}

const defaultContext: Auth0ContextInterface = {
  isAuthenticated: false,
  user: null,
  roles: getInitRoles(),
  loading: false,
  popupOpen: false,
  refreshUser: async () => null,
  loginWithPopup: (...p: any[]) => { },
  loginWithRedirect: (...p: any[]) => { },
  handleRedirectCallback: DEFAULT_REDIRECT_CALLBACK,
  getIdTokenClaims: (...p: any[]) => null,
  getTokenSilently: (...p: any[]) => null,
  getTokenWithPopup: (...p: any[]) => null,
  logout: (...p: any[]) => null,
}

export let auth0ClientModule: Auth0Client
export const Auth0Context = createContext(defaultContext)
export const useAuth0 = () => useContext(Auth0Context)
export const Auth0Provider: FC<{
  [x: string]: any
  onRedirectCallback?: ((appState?: AppState) => void)
}> = ({
  children,
  onRedirectCallback,
  ...initOptions
}) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
    const [user, setUser] = useState<Auth0User>()
    const [auth0Client, setAuth0] = useState<Auth0Client>()
    const [loading, setLoading] = useState(true)
    const [popupOpen, setPopupOpen] = useState(false)

    const roles = useMemo(() => getRolesFromUser(user), [user])

    const onRedirectCallbackMemoized = useCallback((appState?: AppState) => {
      if (onRedirectCallback) return onRedirectCallback(appState)
      const params = appState?.preservedParams ?? ''
      const path = window.location.pathname
      const url = appState?.targetUrl ?? path

      window.location.replace(`${url}${params}`)
    }, [onRedirectCallback])

    const initOptionsRef = useRef(initOptions)
    const onRedirectCallbackRef = useRef(onRedirectCallbackMemoized)

    // Initialize Auth0 Provider
    useEffect(() => {
      const initAuth0 = async () => {
        try {
          const auth0FromHook = await createAuth0Client({
            domain: initOptionsRef.current.domain,
            client_id: initOptionsRef.current.client_id,
            ...initOptionsRef.current
          })

          setAuth0(auth0FromHook)
          auth0ClientModule = auth0FromHook

          try {
            if (window.location.search.includes('code=')) {
              const { appState } = await auth0FromHook.handleRedirectCallback()
              onRedirectCallbackRef.current(appState)
            }
          } catch (e) {
            console.error(e)
            onRedirectCallbackRef.current(null)
          }

          const receivedIsAuthenticated = await auth0FromHook.isAuthenticated()
          setIsAuthenticated(receivedIsAuthenticated)

          if (receivedIsAuthenticated) {
            const receivedUser = await auth0FromHook.getUser<NonNullable<Auth0User>>()
            setUser(receivedUser)
            logAnalyticsEvent('login', { method: 'initAuth0' })
            trackUser(receivedUser)
            reloadAllResources()
          }

          setLoading(false)
        } catch (e) {
          clearAuth0LocalStorage()
          setLoading(false)
        }
      }
      initAuth0()
    }, [])

    const refreshUser = async () => {
      try {
        if (!auth0Client) return
        await auth0Client.checkSession({ ignoreCache: true })

        const receivedIsAuthenticated = await auth0Client.isAuthenticated()
        if (isAuthenticated !== receivedIsAuthenticated) setIsAuthenticated(receivedIsAuthenticated)

        let receivedUser: Auth0User = null

        if (receivedIsAuthenticated) {
          receivedUser = await auth0Client.getUser<NonNullable<Auth0User>>()
          if (!jsonEqual(receivedUser, user)) {
            setUser(receivedUser)
            logAnalyticsEvent('login', { method: 'refreshUser' })
            trackUser(receivedUser)
            reloadAllResources()
          }
        } else {
          if (!jsonEqual(null, user)) {
            setUser(null)
          }
        }

        return receivedUser
      } catch (e) {
        clearAuth0LocalStorage()
      }
    }

    const loginWithPopup = async (params = {}) => {
      if (!auth0Client) return
      setPopupOpen(true)
      try {
        await auth0Client.loginWithPopup(params)
      } catch (error) {
        console.error(error)
      } finally {
        setPopupOpen(false)
      }
      const user = await auth0Client.getUser<NonNullable<Auth0User>>()
      setUser(user)
      logAnalyticsEvent('login', { method: 'loginWithPopup' })
      trackUser(user)
      reloadAllResources()
      if (user) setIsAuthenticated(true)
    }

    const loginWithRedirect = (options: LoginOptions = {
      appState: {
        targetUrl: window.location.pathname,
      }
    }) => {
      if (!auth0Client) return
      auth0Client.loginWithRedirect({
        ...options,
        appState: {
          ...options.appState,
          preservedParams: window.location.search,
        }
      })
    }

    return (
      <Auth0Context.Provider
        value={{
          isAuthenticated,
          user,
          roles,
          loading,
          popupOpen,
          refreshUser,
          loginWithPopup,
          loginWithRedirect,
          getIdTokenClaims: (...p: any[]) => auth0Client?.getIdTokenClaims(...p),
          getTokenSilently: (...p: any[]) => auth0Client?.getTokenSilently(...p),
          getTokenWithPopup: (...p: any[]) => auth0Client?.getTokenWithPopup(...p),
          logout: (params: LogoutOptions = {
            returnTo: window.location.origin
          }) => {
            unTrackUser()
            return auth0Client?.logout({
              ...params
            })
          },
        }}
      >
        {children}
      </Auth0Context.Provider>
    )
  }

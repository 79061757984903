import { FC, ReactNode } from 'react'

import { AdditionalInformationContextProvider } from 'components/pages/PurchaseFlow/common/AdditionalInformation'
import { BuildingLayoutContextProvider } from '../BuildingLayout/_main/BuildingLayout.context'
import { ConstructionMethodContextProvider } from '../ConstructionMethod/_main/ConstructionMethod.context'
import { EnergyCertificateStepContextProvider } from './EnergyCertificateStep.context'
import { PropertyInformationContextProvider } from '../PropertyInformation'
import { VentilationAndHeatingContextProvider } from '../VentilationAndHeating/_main/VentilationAndHeating.context'

/**
 * Stack of providers for energy certificate step.
 * 
 * @example
 * <EnergyCertificateStepStackProvider>
 *   <Component />
 * </EnergyCertificateStepStackProvider>
 */
export const EnergyCertificateStepStackProvider: FC<{
  children?: ReactNode
}> = ({
  children
}) => (
    <PropertyInformationContextProvider>
      <BuildingLayoutContextProvider>
        <ConstructionMethodContextProvider>
          <VentilationAndHeatingContextProvider>
            <AdditionalInformationContextProvider>
              <EnergyCertificateStepContextProvider>
                {children}
              </EnergyCertificateStepContextProvider>
            </AdditionalInformationContextProvider>
          </VentilationAndHeatingContextProvider>
        </ConstructionMethodContextProvider>
      </BuildingLayoutContextProvider>
    </PropertyInformationContextProvider>
  )

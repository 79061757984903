/** Enum of all Esoft products */
export enum ESProduct {
  // RETOUCHING

  // Retouching Basic / BAB
  RETOUCHING_BASIC = 'RETOUCHING_BASIC',

  // Retouching Standard / BAB
  RETOUCHING_STANDARD = 'RETOUCHING_STANDARD',

  RETOUCHING_STANDARD_3_RETOUCH = 'RETOUCHING_STANDARD_3_RETOUCH',
  RETOUCHING_STANDARD_5_RETOUCH = 'RETOUCHING_STANDARD_5_RETOUCH',
  RETOUCHING_STANDARD_8_RETOUCH = 'RETOUCHING_STANDARD_8_RETOUCH',

  // Retouching Advanced / BAB
  RETOUCHING_ADVANCED = 'RETOUCHING_ADVANCED',

  // Retouching Extreme / BAB
  RETOUCHING_EXTREME = 'RETOUCHING_EXTREME',

  // Retouching Intermediate / BAB
  RETOUCHING_INTERMEDIATE = 'RETOUCHING_INTERMEDIATE',

  // 2D FLOOR PLAN MATTERPORT INPUT
  FLOORPLAN_2D_ADVANCED_MATTERPORT_100 = 'FLOORPLAN_2D_ADVANCED_MATTERPORT_100',
  FLOORPLAN_2D_ADVANCED_MATTERPORT_200 = 'FLOORPLAN_2D_ADVANCED_MATTERPORT_200',
  FLOORPLAN_2D_ADVANCED_MATTERPORT_300 = 'FLOORPLAN_2D_ADVANCED_MATTERPORT_300',
  FLOORPLAN_2D_ADVANCED_MATTERPORT_500 = 'FLOORPLAN_2D_ADVANCED_MATTERPORT_500',
  FLOORPLAN_2D_ADVANCED_MATTERPORT_700 = 'FLOORPLAN_2D_ADVANCED_MATTERPORT_700',
  FLOORPLAN_2D_ADVANCED_MATTERPORT_1000 = 'FLOORPLAN_2D_ADVANCED_MATTERPORT_1000',

  // 2D FLOOR PLAN SKETCH INPUT
  FLOORPLAN_2D_ADVANCED = 'FLOORPLAN_2D_ADVANCED',
  FLOORPLAN_2D_ADVANCED_100 = 'FLOORPLAN_2D_ADVANCED_100',
  FLOORPLAN_2D_ADVANCED_200 = 'FLOORPLAN_2D_ADVANCED_200',
  FLOORPLAN_2D_ADVANCED_300 = 'FLOORPLAN_2D_ADVANCED_300',
  FLOORPLAN_2D_ADVANCED_500 = 'FLOORPLAN_2D_ADVANCED_500',
  FLOORPLAN_2D_ADVANCED_700 = 'FLOORPLAN_2D_ADVANCED_700',
  FLOORPLAN_2D_ADVANCED_1000 = 'FLOORPLAN_2D_ADVANCED_1000',

  // 2D FLOOR PLAN MAGIC-PLAN
  FLOORPLAN_2D_ADVANCED_MAGIC_PLAN = 'FLOORPLAN_2D_ADVANCED_MAGIC_PLAN',
  FLOORPLAN_2D_ADVANCED_MAGIC_PLAN_50 = 'FLOORPLAN_2D_ADVANCED_MAGIC_PLAN_50',

  // New sketch floor plan products
  SKETCH_FLOORPLANNER_0_200 = 'SKETCH_FLOORPLANNER_0_200',
  SKETCH_FLOORPLANNER_200_400 = 'SKETCH_FLOORPLANNER_200_400',
  SKETCH_FLOORPLANNER_400_600 = 'SKETCH_FLOORPLANNER_400_600',
  SKETCH_FLOORPLANNER_600_800 = 'SKETCH_FLOORPLANNER_600_800',
  SKETCH_FLOORPLANNER_800_1000 = 'SKETCH_FLOORPLANNER_800_1000',
  SKETCH_FLOORPLANNER_CORRECTION = 'SKETCH_FLOORPLANNER_CORRECTION',

  // 3D Floor plans
  SKETCH_FLOOR_PLAN_3D_0_200 = 'SKETCH_FLOOR_PLAN_3D_0_200',
  SKETCH_FLOOR_PLAN_3D_200_400 = 'SKETCH_FLOOR_PLAN_3D_200_400',
  SKETCH_FLOOR_PLAN_3D_400_600 = 'SKETCH_FLOOR_PLAN_3D_400_600',
  SKETCH_FLOOR_PLAN_3D_600_800 = 'SKETCH_FLOOR_PLAN_3D_600_800',
  SKETCH_FLOOR_PLAN_3D_800_1000 = 'SKETCH_FLOOR_PLAN_3D_800_1000',
  FLOOR_PLAN_3D_STANDARD_CORRECTION = 'FLOOR_PLAN_3D_STANDARD_CORRECTION',

  // New Matterport floor plan products
  MATTERPORT_FLOORPLANNER_0_200 = 'MATTERPORT_FLOORPLANNER_0_200',
  MATTERPORT_FLOORPLANNER_200_400 = 'MATTERPORT_FLOORPLANNER_200_400',
  MATTERPORT_FLOORPLANNER_400_600 = 'MATTERPORT_FLOORPLANNER_400_600',
  MATTERPORT_FLOORPLANNER_600_800 = 'MATTERPORT_FLOORPLANNER_600_800',
  MATTERPORT_FLOORPLANNER_800_1000 = 'MATTERPORT_FLOORPLANNER_800_1000',
  MATTERPORT_FLOORPLANNER_CORRECTION = 'MATTERPORT_FLOORPLANNER_CORRECTION',

  // Photo

  // Picture Editing Standard / Real Estate
  PICTURE_EDITING_STANDARD_REAL_ESTATE = 'PICTURE_EDITING_STANDARD_REAL_ESTATE',

  // Picture Editing Standard / Event
  PICTURE_EDITING_STANDARD_EVENT = 'PICTURE_EDITING_STANDARD_EVENT',

  // Picture Editing Standard / Real Estate - Special Attention
  PICTURE_EDITING_STANDARD_REAL_ESTATE_SPECIAL_ATTENTION = 'PICTURE_EDITING_STANDARD_REAL_ESTATE_SPECIAL_ATTENTION',

  // Picture Editing Standard / Food
  PICTURE_EDITING_STANDARD_FOOD = 'PICTURE_EDITING_STANDARD_FOOD',

  // Picture editing premium / Food
  PICTURE_EDITING_PREMIUM_FOOD = 'PICTURE_EDITING_PREMIUM_FOOD',

  // Portrait Editing Standard / BAB
  PORTRAIT_EDITING_STANDARD = 'PORTRAIT_EDITING_STANDARD',

  // Portrait Editing Standard / w. Background Replacement
  PORTRAIT_EDITING_STANDARD_WITH_BACKGROUND_REPLACEMENT = 'PORTRAIT_EDITING_STANDARD_WITH_BACKGROUND_REPLACEMENT',

  // Advenced RE photo editing
  REAL_ESTATE_EDITING_ADVANCED = 'REAL_ESTATE_EDITING_ADVANCED',

  // Video

  // Video Editing Advanced / Event
  VIDEO_EDITING_ADVANCED_EVENT = 'VIDEO_EDITING_ADVANCED_EVENT',

  // Video Editing Advanced / Real Estate
  VIDEO_EDITING_ADVANCED_REAL_ESTATE = 'VIDEO_EDITING_ADVANCED_REAL_ESTATE',
  VIDEO_EDITING_REAL_ESTATE_RELAXED = 'VIDEO_EDITING_REAL_ESTATE_RELAXED',
  VIDEO_EDITING_REAL_ESTATE_DYNAMIC = 'VIDEO_EDITING_REAL_ESTATE_DYNAMIC',

  // Video advanced / Corporate video
  VIDEO_ADVANCED_CORPORATE_VIDEO = 'VIDEO_ADVANCED_CORPORATE_VIDEO',

  // Virtual Staging

  // Virtual Staging / Ordering
  VIRTUAL_STAGING_ORDERING = 'VIRTUAL_STAGING_ORDERING',

  // Virtual Staging Commercial / BAB
  VIRTUAL_STAGING_COMMERCIAL = 'VIRTUAL_STAGING_COMMERCIAL',

  // Virtual Staging Commercial / 360
  VIRTUAL_STAGING_COMMERCIAL_360 = 'VIRTUAL_STAGING_COMMERCIAL_360',

  // Virtual Staging Commercial / 360 Renovation
  VIRTUAL_STAGING_COMMERCIAL_360_RENOVATION = 'VIRTUAL_STAGING_COMMERCIAL_360_RENOVATION',

  // Virtual Staging Residential / Standard Style
  VIRTUAL_STAGING_RESIDENTIAL_STANDARD_STYLE = 'VIRTUAL_STAGING_RESIDENTIAL_STANDARD_STYLE',

  // Virtual Staging Residential / Premium Design = 'Design', Template
  VIRTUAL_STAGING_RESIDENTIAL_PREMIUM_DESIGN_TEMPLATE = 'VIRTUAL_STAGING_RESIDENTIAL_PREMIUM_DESIGN_TEMPLATE',

  // Virtual Staging Residential / Renovation
  VIRTUAL_STAGING_RESIDENTIAL_RENOVATION = 'VIRTUAL_STAGING_RESIDENTIAL_RENOVATION',

  // Virtual Staging Residential / 360
  VIRTUAL_STAGING_RESIDENTIAL_360 = 'VIRTUAL_STAGING_RESIDENTIAL_360',

  // Virtual Staging Residential / 360 Renovation
  VIRTUAL_STAGING_RESIDENTIAL_360_RENOVATION = 'VIRTUAL_STAGING_RESIDENTIAL_360_RENOVATION',

  // Timelapse
  VIRTUAL_DUSK_PREMIUM_TIMELAPSE = 'VIRTUAL_DUSK_PREMIUM_TIMELAPSE',

  FLOORPLAN_3D_ADVANCED_100 = 'FLOORPLAN_3D_ADVANCED_100',
  FLOORPLAN_3D_ADVANCED_200 = 'FLOORPLAN_3D_ADVANCED_200',
  FLOORPLAN_3D_ADVANCED_300 = 'FLOORPLAN_3D_ADVANCED_300',
  FLOORPLAN_3D_ADVANCED_500 = 'FLOORPLAN_3D_ADVANCED_500',
  FLOORPLAN_3D_ADVANCED_700 = 'FLOORPLAN_3D_ADVANCED_700',
  FLOORPLAN_3D_ADVANCED_1000 = 'FLOORPLAN_3D_ADVANCED_1000',

  QC_MATTERPORT = 'QC_MATTERPORT',
  MATTERPORT_PANORAMA = 'MATTERPORT_PANORAMA',
  SLIDESHOW = 'SLIDESHOW',
}

import { useEffect, useMemo, useRef } from 'react'
import { usePurchaseFlowConfig, usePurchaseFlowRealEstateProperty, useTargetOrderUser } from 'components/pages/PurchaseFlow/_main/contexts'

import GoogleMap from 'components/common/GoogleMap/GoogleMap'
import Stack from '@mui/material/Stack'
import { StepWrapper } from 'components/pages/PurchaseFlow/common/StepWrapper'
import { SystemMessage } from 'components/common/SystemMessage'
import TransitionAppear from 'components/common/TransitionAppear/TransitionAppear'
import { isAddressValid } from 'utils/validators'
import { logAnalyticsEvent } from 'utils/analytics'
import { useAuth0 } from 'utils/auth'
import { useTranslation } from 'react-i18next'

/**
 * @component
 * AddressStep component responsible for handling the address selection step in the purchase flow.
 *
 * @example
 * <AddressStepController />
 */
export const AddressStepController: React.FC = () => {
  const { i18n } = useTranslation()
  const { t } = useTranslation('order')
  const { roles } = useAuth0()
  const { isPropertyPrefilled, requestedPropertyData, getPropertyQuery } = usePurchaseFlowRealEstateProperty()
  const {
    selectedCategory,
    getAddressConfig,
    selectedAssignmentPlace,
    sessionId,
    setSelectedAssignmentPlace,
  } = usePurchaseFlowConfig()

  const { targetUser, adminSelectedUserEmail } = useTargetOrderUser()

  const addressIsValid = useMemo(() => isAddressValid(selectedAssignmentPlace), [selectedAssignmentPlace])

  // Fetch the address configuration
  useEffect(() => {
    if (selectedCategory === null || !sessionId) return
    if (roles.isAdmin && !adminSelectedUserEmail) return
    if (!getAddressConfig.isIdle) return

    getAddressConfig.mutate({
      sessionId,
      email: adminSelectedUserEmail,
      category: selectedCategory,
    })
  }, [adminSelectedUserEmail, getAddressConfig, roles.isAdmin, selectedCategory, sessionId])

  /** Logs entering this page with the selected category information. */
  useEffect(() => {
    logAnalyticsEvent('enters_order_address_selection_screen', {
      category: selectedCategory,
    })
  }, [selectedCategory])

  const { initialAddress, initialMarker } = useMemo(() => {
    if (selectedAssignmentPlace) {
      return {
        initialAddress: selectedAssignmentPlace.formatted_address,
        initialMarker: selectedAssignmentPlace.geometry.location
      }
    }

    if (isPropertyPrefilled && requestedPropertyData?.address) {
      const { street, streetNumber, city, postalCode, country, coordinates } = requestedPropertyData.address
      return {
        initialAddress: `${street} ${streetNumber}, ${city}, ${postalCode}, ${country}`,
        initialMarker: coordinates,
      }
    }

    return {
      initialAddress: undefined,
      initialMarker: undefined,
    }
  }, [isPropertyPrefilled, requestedPropertyData?.address, selectedAssignmentPlace])

  const prefilled = useRef<boolean>(false)

  return (
    <TransitionAppear visible>
      <StepWrapper title={t('step_address.title')} subtitle={t('step_address.text')}>

        {/* Google Map for Address Selection */}
        <Stack>

          {getPropertyQuery.isError &&
            <SystemMessage
              variant="warning"
              message={t('step_address.property_prefill_fail')}
              sx={{ mb: 4 }}
            />
          }

          <GoogleMap
            height={'40rem'}
            inputPosition="above"
            language={i18n.language}
            countryCode={targetUser?.country}
            initialAddress={initialAddress}
            initialMarker={initialMarker}
            handleChange={(newGooglePlace, isInitialLookup) => {
              if (!isInitialLookup) setSelectedAssignmentPlace(newGooglePlace)
              if (isInitialLookup && isPropertyPrefilled && !prefilled.current) {
                prefilled.current = true
                setSelectedAssignmentPlace(newGooglePlace)
              }
            }}
            onInputChange={e => setSelectedAssignmentPlace(null)}
            error={selectedAssignmentPlace && !addressIsValid ? t('step_address.address_not_selected') : undefined}
          />

        </Stack>

      </StepWrapper>
    </TransitionAppear>
  )
}

import { EnergyCertificateReason, useAdditionalInformation } from './AdditionalInformation.context'
import { Trans, useTranslation } from 'react-i18next'
import { supportedDocumentFileTypes, supportedImageFileTypes } from 'constants/misc'

import { FC } from 'react'
import { FileAPIUpload } from 'components/common/FileAPIUpload'
import { GRAY_700 } from 'constants/styling/theme'
import { MUIInputField } from 'components/common/MUIInputField'
import { SectionedBorderBox } from 'components/common/SectionedBorderBox'
import Stack from '@mui/material/Stack'
import { ToggleButton } from 'components/common/ToggleButton'
import { Typography } from '@mui/material'

/**
 * @interface Props Input properties
 */
interface Props {
  /** Allow open construction upload section or not */
  allowConstructionUpload?: boolean
}

/**
 * Content for the AdditionalInformation for both the ConsumptionCertificateStepController and the EnergyCertificateStepController.
 * 
 * @example
 * <AdditionalInformation />
 */
export const AdditionalInformation: FC<Props> = ({
  allowConstructionUpload = false
}) => {

  const { t } = useTranslation(['purchase_flow'], { keyPrefix: 'additional_information_page' })

  const {
    message,
    setMessage,
    energyCertificateReason,
    setEnergyCertificateReason,
    exteriorViewUpload,
    heaterNameplateUpload,
    constructionDescriptionUpload
  } = useAdditionalInformation()

  const maximumUploads = 1

  return (
    <SectionedBorderBox title={t('title')}>
      <Stack gap="1.6rem" position="relative">

        <Stack gap=".6rem">

          <Typography variant='text-sm' fontWeight='medium' color={GRAY_700}>
            {t('reason_for_issue_certificate')}
          </Typography>

          <Stack flexDirection="row" gap={1} alignItems="center" flexWrap="wrap">
            {Object.values(EnergyCertificateReason).map(reason => (
              <ToggleButton
                key={reason}
                sx={{ height: '4rem', justifyContent: 'center' }}
                isSelected={energyCertificateReason === reason}
                label={t(`energy_certificate_reason.${reason}`)}
                onClick={() => setEnergyCertificateReason(reason)}
              />
            ))}
          </Stack>

        </Stack>

        <FileAPIUpload
          title={<Trans
            t={t}
            i18nKey="exterior_view"
            values={{ uploadCount: exteriorViewUpload.allFilesArray.length, maximumUploads }}
          />}
          maxNumberOfFiles={1}
          files={exteriorViewUpload.allFilesArray}
          acceptedFileTypes={[...supportedImageFileTypes, ...supportedDocumentFileTypes]}
          isDraggingFile={false}
          uploadHandler={(acceptedFiles) => exteriorViewUpload.uploadFiles(acceptedFiles)}
          onDelete={(fileId) => exteriorViewUpload.deleteFiles([fileId])}
        />

        <FileAPIUpload
          title={<Trans
            t={t}
            i18nKey="heater_nameplate"
            values={{ uploadCount: heaterNameplateUpload.allFilesArray.length, maximumUploads }}
          />}
          maxNumberOfFiles={1}
          files={heaterNameplateUpload.allFilesArray}
          acceptedFileTypes={[...supportedImageFileTypes, ...supportedDocumentFileTypes]}
          isDraggingFile={false}
          uploadHandler={(acceptedFiles) => heaterNameplateUpload.uploadFiles(acceptedFiles)}
          onDelete={(fileId) => heaterNameplateUpload.deleteFiles([fileId])}
        />

        {allowConstructionUpload &&
          <FileAPIUpload
            title={<Trans
              t={t}
              i18nKey="construction_description"
              values={{ uploadCount: constructionDescriptionUpload.allFilesArray.length, maximumUploads }}
            />}
            maxNumberOfFiles={1}
            files={constructionDescriptionUpload.allFilesArray}
            acceptedFileTypes={[...supportedImageFileTypes, ...supportedDocumentFileTypes]}
            isDraggingFile={false}
            uploadHandler={(acceptedFiles) => constructionDescriptionUpload.uploadFiles(acceptedFiles)}
            onDelete={(fileId) => constructionDescriptionUpload.deleteFiles([fileId])}
          />
        }

        <MUIInputField
          label={t('message_the_expert')}
          placeholder={t('message_the_expert_placeholder')}
          isMultiline
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />

      </Stack>
    </SectionedBorderBox>
  )
}

import { useEffect, useMemo, useState } from 'react'

import constate from 'constate'
import { debounce } from 'lodash'
import { Nullable } from 'models/helpers'

export enum BuildingType {
  SINGLE_FAMILY_HOUSE = 'SINGLE_FAMILY_HOUSE',
  TWO_FAMILY_HOUSE = 'TWO_FAMILY_HOUSE',
  APARTMENT_BUILDING = 'APARTMENT_BUILDING',
  MIXED_BUILDING = 'MIXED_BUILDING',
}

export enum BuildingLocationType {
  FREESTANDING = 'FREESTANDING',
  TERRACED_HOUSE = 'TERRACED_HOUSE',
  SEMI_DETACHED_HOUSE = 'SEMI_DETACHED_HOUSE',
}

export const [PropertyInformationContextProvider, usePropertyInformation] = constate(() => {
  const [buildingType, setBuildingType] = useState<Nullable<BuildingType>>(null)
  const [buildingLocationType, setBuildingLocationType] = useState<Nullable<BuildingLocationType>>(null)
  const [residentialUnit, setResidentialUnit] = useState<number>(1)
  const [commercialArea, setCommercialArea] = useState('')
  const [commercialAreaType, setCommercialAreaType] = useState('')
  const [constructionYear, setConstructionYear] = useState('')
  const [livingSpace, setLivingSpace] = useState('')
  const [isConstructionConfirmed, setIsConstructionConfirmed] = useState<boolean>(false)

  const [debouncedConstructionYear, setDebouncedConstructionYear] = useState(constructionYear)
  const [debouncedResidentialUnit, setDebouncedResidentialUnit] = useState(residentialUnit)

  useEffect(() => {
    const handler = debounce(() => {
      setDebouncedConstructionYear(constructionYear)
      setDebouncedResidentialUnit(residentialUnit)
    }, 500)

    handler()

    return () => {
      handler.cancel()
    }
  }, [constructionYear, residentialUnit])

  const isShowConstructionNote = useMemo(() =>
    debouncedResidentialUnit < 5
    && parseInt(debouncedConstructionYear) < 1978
    && debouncedConstructionYear.length >= 4,
    [debouncedConstructionYear, debouncedResidentialUnit])

  const isPropertyInformationValid = useMemo(() => {
    if (isShowConstructionNote && !isConstructionConfirmed) return false


    if (buildingType === BuildingType.SINGLE_FAMILY_HOUSE || buildingType === BuildingType.TWO_FAMILY_HOUSE || buildingType === BuildingType.APARTMENT_BUILDING) {
      return !!residentialUnit && !!constructionYear && !!livingSpace
    }

    if (buildingType === BuildingType.MIXED_BUILDING) {
      return !!residentialUnit && !!constructionYear && !!residentialUnit && !!commercialArea && !!commercialAreaType
    }

    return false
  }, [isShowConstructionNote, isConstructionConfirmed, buildingType, residentialUnit, constructionYear, livingSpace, commercialArea, commercialAreaType])

  return {
    buildingType,
    setBuildingType,
    buildingLocationType,
    setBuildingLocationType,
    residentialUnit,
    setResidentialUnit,
    constructionYear,
    setConstructionYear,
    livingSpace,
    setLivingSpace,
    commercialArea,
    setCommercialArea,
    commercialAreaType,
    setCommercialAreaType,
    isPropertyInformationValid,
    isConstructionConfirmed,
    setIsConstructionConfirmed,
    isShowConstructionNote,
  }
})

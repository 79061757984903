import { EntityKeys, QueryType } from 'utils/reactQuery'

import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { PropertyEndpoint } from 'constants/API'
import { Nullable } from 'models/helpers'
import { RealEstatePropertyDTO } from 'models/property'
import { useAPI } from 'utils/API'

// -- QUERIES
export function useGetPropertyDetail(propertyId: Nullable<string>, workspaceId: Nullable<string>, allowRetry = true) {
  const api = useAPI<PropertyEndpoint>()

  return useQuery<RealEstatePropertyDTO, AxiosError>({
    queryKey: [EntityKeys.PROPERTY, QueryType.GET_ONE, { propertyId: propertyId!, workspaceId: workspaceId! }],
    queryFn: () => api.get<RealEstatePropertyDTO>(
      PropertyEndpoint.GET_PROPERTY_DETAIL,
      {
        workspaceId: workspaceId!,
        propertyId: propertyId!,
      },
      false
    ),
    enabled: !!propertyId && !!workspaceId,
    retry: allowRetry,
  })
}

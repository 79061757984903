import { FC, useMemo } from 'react'
import { GalleryStagingFlowContextProvider, GalleryStagingStackProvider } from 'components/pages/Gallery/GalleryStagingFlow'
import { Outlet, useParams } from 'react-router-dom'

import { AvailableStagingsListContextProvider } from 'components/pages/Gallery/AvailableStagingsList'
import { ClientGalleryContextProvider } from './contexts/ClientGallery.context'
import { GalleryAdditionalVisualsContextProvider } from 'components/pages/Gallery/_main/contexts/GalleryAdditionalVisuals.context'
import { GalleryAssignmentContextProvider } from 'components/pages/Gallery/_main/contexts/GalleryAssignment.context'
import { GalleryDealContextProvider } from 'components/pages/Gallery/_main/contexts/GalleryDeal.context'
import { GalleryNavigationContextProvider } from 'components/pages/Gallery/_main/contexts/GalleryNavigation.context'
import { GalleryOrderContextProvider } from 'components/pages/Gallery/_main/contexts/GalleryOrder.context'
import { GalleryProductContextProvider } from 'components/pages/Gallery/_main/contexts/GalleryProduct.context'
import { GalleryStagingContextProvider } from 'components/pages/GalleryStaging/GalleryStaging.context'
import { GalleryVirtualVisitContextProvider } from 'components/pages/Gallery/_main/contexts/GalleryVirtualVisit.context'
import { GalleryVisualSelectionContextProvider } from 'components/pages/Gallery/_main/contexts/GalleryVisualSelection.context'
import { GalleryVisualTypeContextProvider } from 'components/pages/Gallery/_main/contexts/GalleryVisualType.context'
import { GalleryVisualsContextProvider } from 'components/pages/Gallery/_main/contexts/GalleryVisuals.context'
import { GalleryVisualsDownloadArchiveContextProvider } from 'components/pages/Gallery/_main/contexts/GalleryVisualsDownloadArchive.context'
import { GalleryVisualsEditingStackProvider } from 'components/pages/Gallery/_main/contexts/GalleryVisualsEditingStackProvider'
import { GalleryVisualsMetaContextProvider } from 'components/pages/Gallery/_main/contexts/GalleryVisualsMeta.context'
import NotFound from 'components/pages/General/NotFound/NotFound'
import { PageTransition } from 'utils/animations'
import ThemeProvider from '@mui/material/styles/ThemeProvider'
import { ThreadContextProvider } from 'components/common/Threads/context'
import { redesignMUITheme } from 'constants/styling/redesignTheme'

export const ClientGallery: FC = () => {
  const { id } = useParams<{ id?: string }>()
  const assignmentId = useMemo(() => id || undefined, [id])

  if (!assignmentId) return <NotFound />

  return (
    <ThemeProvider theme={redesignMUITheme}>
      <GalleryAssignmentContextProvider assignmentId={assignmentId}>
        <GalleryDealContextProvider assignmentId={assignmentId} keepPreviousData={true}>
          <GalleryVisualTypeContextProvider assignmentId={assignmentId}>
            <GalleryProductContextProvider assignmentId={assignmentId}>
              <GalleryOrderContextProvider assignmentId={assignmentId}>
                <GalleryVisualsMetaContextProvider assignmentId={assignmentId}>
                  <GalleryVisualsContextProvider assignmentId={assignmentId}>
                    <GalleryVirtualVisitContextProvider assignmentId={assignmentId}>
                      <GalleryVisualSelectionContextProvider assignmentId={assignmentId}>
                        <GalleryAdditionalVisualsContextProvider assignmentId={assignmentId}>
                          <GalleryVisualsDownloadArchiveContextProvider assignmentId={assignmentId}>
                            <GalleryVisualsEditingStackProvider assignmentId={assignmentId}>
                              <GalleryStagingStackProvider>
                                <GalleryStagingFlowContextProvider>
                                  <GalleryStagingContextProvider>
                                    <AvailableStagingsListContextProvider>
                                      <ThreadContextProvider>

                                        <ClientGalleryContextProvider assignmentId={assignmentId}>
                                          <GalleryNavigationContextProvider>

                                            <PageTransition>
                                              <Outlet></Outlet>
                                            </PageTransition>

                                          </GalleryNavigationContextProvider>
                                        </ClientGalleryContextProvider>

                                      </ThreadContextProvider>
                                    </AvailableStagingsListContextProvider>
                                  </GalleryStagingContextProvider>
                                </GalleryStagingFlowContextProvider>
                              </GalleryStagingStackProvider>
                            </GalleryVisualsEditingStackProvider>
                          </GalleryVisualsDownloadArchiveContextProvider>
                        </GalleryAdditionalVisualsContextProvider>
                      </GalleryVisualSelectionContextProvider>
                    </GalleryVirtualVisitContextProvider>
                  </GalleryVisualsContextProvider>
                </GalleryVisualsMetaContextProvider>
              </GalleryOrderContextProvider>
            </GalleryProductContextProvider>
          </GalleryVisualTypeContextProvider>
        </GalleryDealContextProvider>
      </GalleryAssignmentContextProvider>
    </ThemeProvider>
  )
}

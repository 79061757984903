import React, { useMemo } from 'react'

import { EmptyThread } from '../EmptyThread'
import { GRAY_900 } from 'constants/styling/theme'
import { MUIButton } from 'components/common/MUIButton'
import { MessageThreadDTO } from 'models/messageThreads'
import { Options } from 'components/common/FileAPI'
import { SectionedBorderBox } from 'components/common/SectionedBorderBox'
import Stack from '@mui/material/Stack'
import { Thread } from '../Thread'
import Typography from '@mui/material/Typography'
import { useThreads } from '../context'

/** @interface Props for the RequestThread component. */
interface Props {
  /** Title of the request threads. */
  title: string
  /** ID of the assignment associated with the thread. */
  assignmentId?: string
  /** Array of message thread items. */
  threadMessageItems?: MessageThreadDTO[]
  /** Options for the file upload API handler. */
  fileUploadOptions: Options
}

/**
 * @component
 * RequestThreadController displays a thread request and manages the display and interaction of thread of messages.
 *
 * @example 
 * <RequestThreadController />
 */
export const RequestThreadController: React.FC<Props> = ({ title, assignmentId, threadMessageItems, fileUploadOptions }) => {

  const { headerButtonText, onHeaderButtonClick } = useThreads()

  /** List of thread messages sorted by state and creation date. */
  const threadList = useMemo(() => {
    if (!threadMessageItems || threadMessageItems.length === 0) return []

    return threadMessageItems.sort((a, b) => {
      if (a.state === 'OPEN' && b.state !== 'OPEN') return -1
      if (a.state !== 'OPEN' && b.state === 'OPEN') return 1
      return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
    })
  }, [threadMessageItems])

  return (
    <SectionedBorderBox
      title={
        <Stack flexDirection="row" gap={1} justifyContent="space-between" alignItems="center">

          <Typography color={GRAY_900} variant="text-lg" fontWeight={600}>
            {title}
          </Typography>

          {headerButtonText &&
            <MUIButton size="xs" type="secondaryBorder" onClick={onHeaderButtonClick}>
              {headerButtonText}
            </MUIButton>
          }

        </Stack>
      }
      sx={{ minHeight: '21.5rem' }}
    >
      {/** EMPTY CONTENT */}
      {threadList.length === 0 && <EmptyThread />}

      {/** THREADS LIST */}
      <Stack gap={2}>
        {threadList.length > 0 && threadList.map((threadData) => (
          <Thread
            key={threadData.id}
            assignmentId={assignmentId ?? ''}
            fileUploadOptions={fileUploadOptions}
            {...threadData}
          />
        ))}
      </Stack>

    </SectionedBorderBox>
  )
}

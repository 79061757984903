import { BuildingType, usePropertyInformation } from '../PropertyInformation'
import { useMemo, useState } from 'react'

import constate from 'constate'
import { useAdditionalInformation } from 'components/pages/PurchaseFlow/common/AdditionalInformation'
import { useConsumptionInformation } from '../ConsumptionInformation'
import { useHeatingSystem } from '../HeatingSystem/HeatingSystem.context'

/** Enum of available information types */
export enum InformationType {
  PROPERTY_INFORMATION = 'PROPERTY_INFORMATION',
  HEATING_SYSTEM = 'HEATING_SYSTEM',
  CONSUMPTION_INFORMATION = 'CONSUMPTION_INFORMATION',
  ADDITIONAL_INFORMATION = 'ADDITIONAL_INFORMATION',
}

export const [ConsumptionCertificateStepContextProvider, useConsumptionCertificateStep] = constate(() => {

  const { heatingSystemItems } = useHeatingSystem()
  const { exteriorViewUpload, heaterNameplateUpload } = useAdditionalInformation()

  const {
    buildingType,
    constructionYear,
    livingSpace,
    yearOfRenovation,
    commercialArea,
    isConstructionConfirmed,
    isShowConstructionNote
  } = usePropertyInformation()

  const {
    hasPropertyVacant,
    startDate,
    endDate,
    area
  } = useConsumptionInformation()

  const [infoType, setInfoType] = useState<InformationType>(InformationType.PROPERTY_INFORMATION)

  const isPropertyInformationValid = useMemo(() => {
    if (isShowConstructionNote && !isConstructionConfirmed) return false

    if (buildingType === BuildingType.MIXED_BUILDING && !commercialArea) return false

    if (!constructionYear || constructionYear.length !== 4) return false

    if (yearOfRenovation && yearOfRenovation.length !== 4) return false

    if (!livingSpace) return false

    return true
  }, [buildingType, commercialArea, constructionYear, isConstructionConfirmed, isShowConstructionNote, livingSpace, yearOfRenovation])

  /** Determines if the heating system is valid checking each item has a value. */
  const isHeatingSystemValid = useMemo(() => {
    return heatingSystemItems.every(({ fuelType, hotWaterType, unitCaptureType, yearOfManufacture, billingPeriodItems }) =>
      fuelType !== undefined &&
      hotWaterType !== undefined &&
      unitCaptureType !== undefined &&
      yearOfManufacture !== null &&
      billingPeriodItems.every(({ startDate, endDate, consumption }) =>
        startDate !== null &&
        endDate !== null &&
        consumption !== undefined
      )
    )
  }, [heatingSystemItems])

  const isConsumptionInformationValid = useMemo(() => {
    if (hasPropertyVacant) {
      if (!startDate || !endDate || !area) return false
    }

    return true
  }, [area, endDate, hasPropertyVacant, startDate])

  const isAdditionalInformationValid = useMemo(() => {
    if (!exteriorViewUpload.allFilesArray.length || !heaterNameplateUpload.allFilesArray.length) return false

    return true
  }, [exteriorViewUpload.allFilesArray.length, heaterNameplateUpload.allFilesArray.length])

  return {
    infoType,
    setInfoType,
    isPropertyInformationValid,
    isHeatingSystemValid,
    isConsumptionInformationValid,
    isAdditionalInformationValid,
  }
})

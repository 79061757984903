import { DescriptionOutlined, UploadFileOutlined } from '@mui/icons-material'
import { FC, Fragment, ReactNode } from 'react'

import { AssignmentDetailsCard } from 'components/pages/Gallery/AssignmentDetailsCard'
import { CreativeAssignmentCard } from 'components/pages/Gallery/CreativeAssignmentCard'
import { DocumentUploadType } from 'constants/documents'
import { MUIButton } from 'components/common/MUIButton'
import { useGalleryDocumentsContext } from 'components/pages/Gallery/_main/contexts/GalleryDocuments.context'
import { useTranslation } from 'react-i18next'

interface AdminGalleryDocumentsPanelProps {
  /** Assignment ID */
  assignmentId: string
}

const typeIconMap: Record<DocumentUploadType, ReactNode> = {
  [DocumentUploadType.INPUT]: <UploadFileOutlined fontSize="large" />,
  [DocumentUploadType.OUTPUT]: <DescriptionOutlined fontSize="large" />,
}

/**
 * Admin gallery documents panel component
 * 
 * @example
 * <AdminGalleryDocumentsPanel assignmentId={assignmentId}/>
 */
export const AdminGalleryDocumentsPanel: FC<AdminGalleryDocumentsPanelProps> = ({ assignmentId }) => {
  const { t } = useTranslation(['gallery'])

  const {
    documentTypeSelect,
    setDocumentTypeSelect,
  } = useGalleryDocumentsContext()

  return (
    <Fragment>

      <AssignmentDetailsCard assignmentId={assignmentId} />

      <CreativeAssignmentCard />

      <div className="details">
        <div className="sticky">
          <div className="flex">
            <div className="documentTypeTabs">
              {Object.values(DocumentUploadType).map(type => (
                <MUIButton
                  key={type}
                  type={documentTypeSelect === type ? 'defaultPrimary' : 'secondaryBorder'}
                  startIcon={typeIconMap[type]}
                  onClick={() => {
                    setDocumentTypeSelect(type)
                  }}
                >
                  <span>{t(`document_type.${type}`)}</span>
                </MUIButton>
              )
              )}
            </div>
          </div>
        </div>
      </div>

    </Fragment>
  )
}

import { ImmutableMap, Nullable } from 'models/helpers'
import { useCallback, useMemo, useState } from 'react'

import constate from 'constate'
import { uniqueId } from 'lodash'

/** @interface HeatingSystemItem Represents an item in the heating system. */
interface HeatingSystemItem {
  photovoltaicSystem: Nullable<PhotovoltaicSystemType>,
  areaOfFacility: Nullable<number>,
  peakPower: Nullable<number>,
  ventilationSystem: Set<VentilationSystemType>,
  distribution: Nullable<DistributionType>,
  ventilation: Nullable<VentilationType>,
  fans: Nullable<FansType>,
  ventilationDetails: Nullable<VentilationDetailsType>,
  isHydraulicBalancing: boolean,
  heatingFuel: Nullable<HeatingFuelType>,
  heatingFuelOption: string
  heatingDesignation: Nullable<number>,
  orientationType: OrientationType,
  heaterLocation: Nullable<HeaterLocationType>,
  waterLocation: Nullable<WaterLocationType>,
  nightService: Nullable<NightServiceType>,
  heatTransferType: Nullable<HeatTransferType>,
  heatLineType: Nullable<HeatingLineType>,
  circulationPumpsType: Nullable<CirculationPumpsType>,
  generatedBy: GeneratedByType,
  generatorYearOfManufacture: Nullable<number>
  heatingPower: Nullable<number>,
  yearOfConstruction: Nullable<number>,
  decentralizedHeatingType: Nullable<DecentralizedElectricHeatingType | DecentralizedGasHeatingType | DecentralizedHeatPumpHeatingType>
  totalHeatingOutput: Nullable<number>,
  heatingCombinedWith: Set<HeatingCombinationType>,
  heatRecovery: Nullable<number>,
  isHotWaterCirculation: boolean,
}

/**
 * Enum of available photovoltaic system types
 */
export enum PhotovoltaicSystemType {
  YES = 'YES',
  NO = 'NO'
}

/** Enum of available ventilation system types */
export enum VentilationSystemType {
  WINDOW_VENTILATION = 'WINDOW_VENTILATION',
  VENTILATION_SYSTEM_AVAILABLE = 'VENTILATION_SYSTEM_AVAILABLE'
}

/** Enum of available distribution types */
export enum DistributionType {
  WITHIN_THERMAL_ENVELOPE = 'WITHIN_THERMAL_ENVELOPE',
  OUTSIDE_THERMAL_ENVELOPE_ROOF = 'OUTSIDE_THERMAL_ENVELOPE_ROOF',
  OUTSIDE_THERMAL_ENVELOPE_BASEMENT = 'OUTSIDE_THERMAL_ENVELOPE_BASEMENT'
}

/** Enum of available ventilation types */
export enum VentilationType {
  CENTRALIZED = 'CENTRALIZED',
  DECENTRALIZED = 'DECENTRALIZED',
  EXHAUST_AIR_WITHOUT_HEAT_RECOVERY = 'EXHAUST_AIR_WITHOUT_HEAT_RECOVERY'
}

/** Enum of available fans types */
export enum FansType {
  AC_FAN = 'AC_FAN',
  DC_FAN = 'DC_FAN'
}

/** Enum of available ventilation details types */
export enum VentilationDetailsType {
  DISTRIBUTION = 'DISTRIBUTION',
  VENTILATION_TYPE = 'VENTILATION_TYPE',
  FANS = 'FANS'
}

/** Enum of available heating fuel types */
export enum HeatingFuelType {
  OIL = 'OIL',
  GAS = 'GAS',
  WOOD_PELLETS_SOLIDS = 'WOOD_PELLETS_SOLIDS',
  DISTRICT_HEATING = 'DISTRICT_HEATING',
  HEAT_PUMP = 'HEAT_PUMP',
  ELECTRIC_HEATING = 'ELECTRIC_HEATING',
  SINGLE_OVEN = 'SINGLE_OVEN'
}

/** Enum of available orientation types */
export enum OrientationType {
  NORTH_WEST = 'NORTH_WEST',
  NORTH = 'NORTH',
  NORTH_EAST = 'NORTH_EAST',
  WEST = 'WEST',
  CENTERED = 'CENTERED',
  EAST = 'EAST',
  SOUTH_WEST = 'SOUTH_WEST',
  SOUTH = 'SOUTH',
  SOUTH_EAST = 'SOUTH_EAST',
}

/** Enum of available heater location types */
export enum HeaterLocationType {
  APARTMENT = 'APARTMENT',
  BOILER_ROOM = 'BOILER_ROOM'
}

/** Enum of available centralized water location types */
export enum WaterLocationType {
  APARTMENT = 'APARTMENT',
  BOILER_ROOM = 'BOILER_ROOM'
}

/** Enum of available night service types */
export enum NightServiceType {
  NO_SHUTDOWN_REDUCTION = 'NO_SHUTDOWN_REDUCTION',
  NIGHT_SHUTDOWN = 'NIGHT_SHUTDOWN',
  NIGHT_REDUCTION = 'NIGHT_REDUCTION'
}

/** Enum of available heating combination types */
export enum HeatingCombinationType {
  CENTRAL_HOT_WATER_GENERATION = 'CENTRAL_HOT_WATER_GENERATION',
  SOLAR_THERMAL_ENERGY_FOR_HEATING_SUPPORT = 'SOLAR_THERMAL_ENERGY_FOR_HEATING_SUPPORT',
  SOLAR_THERMAL_ENERGY_FOR_HOT_WATER_SUPPORT = 'SOLAR_THERMAL_ENERGY_FOR_HOT_WATER_SUPPORT'
}

/** Enum of available generated by types */
export enum GeneratedByType {
  ELECTRIC = 'ELECTRIC',
  GAS = 'GAS',
  HEAT_PUMP = 'HEAT_PUMP'
}

/** Enum of available decentralized electric heating types */
export enum DecentralizedElectricHeatingType {
  ELECTRIC_DAY_STORAGE = 'ELECTRIC_DAY_STORAGE',
  ELECTRIC_NIGHT_STORAGE_HEATERS = 'ELECTRIC_NIGHT_STORAGE_HEATERS',
  SMALL_ELECTRIC_STORAGE_UNITS = 'SMALL_ELECTRIC_STORAGE_UNITS',
  ELECTRIC_INSTANTANEOUS_WATER_HEATER = 'ELECTRIC_INSTANTANEOUS_WATER_HEATER',
  ELECTRIC_STORAGE_HEATING_ELEMENT = 'ELECTRIC_STORAGE_HEATING_ELEMENT'
}

/** Enum of available decentralized gas heating types */
export enum DecentralizedGasHeatingType {
  GAS_STORAGE_WATER_HEATER = 'GAS_STORAGE_WATER_HEATER',
  GAS_INSTANTANEOUS_WATER_HEATER = 'GAS_INSTANTANEOUS_WATER_HEATER'
}

/** Enum of available decentralized heat pump heating types */
export enum DecentralizedHeatPumpHeatingType {
  HEAT_PUMP_EXHAUST_AIR_SUPPLY_AIR = 'THEAT_PUMP_EXHAUST_AIR_SUPPLY_AIR',
  HEAT_PUMP_BASEMENT_AIR = 'HEAT_PUMP_BASEMENT_AIR'
}

/** Enum of available oil fuel types */
export enum OilFuelType {
  STANDARD_FAN_BOILER = 'STANDARD_FAN_BOILER',
  STANDARD_BURNER_REPLACEMENT = 'STANDARD_BURNER_REPLACEMENT',
  LOW_TEMPERATURE_FAN_BOILER = 'LOW_TEMPERATURE_FAN_BOILER',
  LOW_TEMPERATURE_BURNER_REPLACEMENT_ONLY_FAN_BOILER = 'LOW_TEMPERATURE_BURNER_REPLACEMENT_ONLY_FAN_BOILER',
  CONDENSING_BOILER = 'CONDENSING_BOILER'
}

/** Enum of available gas fuel types */
export enum GasFuelType {
  STANDARD_GAS_SPECIAL_HEATING_BOILER = 'STANDARD_GAS_SPECIAL_HEATING_BOILER',
  STANDARD_BURNER_REPLACEMENT = 'STANDARD_BURNER_REPLACEMENT',
  LOW_TEMPERATURE_GAS_SPECIAL_HEATING_BOILER = 'LOW_TEMPERATURE_GAS_SPECIAL_HEATING_BOILER',
  LOW_TEMPERATURE_BURNER_REPLACEMENT = 'LOW_TEMPERATURE_BURNER_REPLACEMENT',
  CONDENSING_BOILER = 'CONDENSING_BOILER'
}

/** Enum of available wood pellet fuel types */
export enum WoodPelletsFuelType {
  SOLID_STOD_BOILER_TYPE_AFTER_1994 = 'SOLID_STOD_BOILER_TYPE_AFTER_1994',
  PELLET_CENTRAL_BOILER_AFTER_1994 = 'PELLET_CENTRAL_BOILER_AFTER_1994',
  TILED_STOVE_FIREPLACE = 'TILED_STOVE_FIREPLACE'
}

/** Enum of available district heating fuel types */
export enum DistrictHeatingFuelType {
  CHP_FOSSIL_FUEL = 'CHP_FOSSIL_FUEL',
  CHP_RENEWABLE_FUEL = 'CHP_RENEWABLE_FUEL',
  HW_FOSSIL_FUEL = 'HW_FOSSIL_FUEL',
  HW_RENEWABLE_FUEL = 'HW_RENEWABLE_FUEL'
}

/** Enum of available heat pump fuel types */
export enum HeatPumpFuelType {
  WATER_WATER = 'WATER_WATER',
  SOIL_WATER = 'SOIL_WATER',
  AIR_WATER = 'AIR_WATER',
  EXHAUST_AIR_WATER = 'EXHAUST_AIR_WATER'
}

/** Enum of available electric heating fuel types */
export enum ElectricHeatingFuelType {
  DIRECT_HEATING = 'DIRECT_HEATING',
  STORAGE_HEATING = 'STORAGE_HEATING'
}

/** Enum of available single oven fuel types */
export enum SingleOvenFuelType {
  GAS_CHAMBER_HEATER_TIED_TO_CHIMNEY = 'GAS_CHAMBER_HEATER_TIED_TO_CHIMNEY',
  GAS_SPACE_HEATER_EXTERNAL_WALL_UNIT = 'GAS_SPACE_HEATER_EXTERNAL_WALL_UNIT',
  OIL_FIRED_SINGLE_FURNACE_WITH_EVAPORATIVE_BURNER = 'OIL_FIRED_SINGLE_FURNACE_WITH_EVAPORATIVE_BURNER',
  TILED_STOVE_OR_FIREPLACE = 'TILED_STOVE_OR_FIREPLACE',
  COAL_FIRED_IRON_FURNACE = 'COAL_FIRED_IRON_FURNACE'
}

/** Enum of available heat transfer types */
export enum HeatTransferType {
  HEATING_ELEMENT = 'HEATING_ELEMENT',
  UNDERFLOOR_HEATING = 'UNDERFLOOR_HEATING',
  RADIATOR_FLOOR_HEATING_RATIO_30_70 = 'RADIATOR_FLOOR_HEATING_RATIO_30_70',
  RADIATOR_FLOOR_HEATING_RATIO_50_50 = 'RADIATOR_FLOOR_HEATING_RATIO_50_50'
}

/** Enum of available heating line types */
export enum HeatingLineType {
  UNDERFLOOR_HEATING = 'UNDERFLOOR_HEATING',
  RADIATOR_FLOOR_HEATING_RATIO_70_30 = 'RADIATOR_FLOOR_HEATING_RATIO_70_30'
}

/** Enum of available circulation pumps types */
export enum CirculationPumpsType {
  UNREGULATED = 'UNREGULATED',
  REGULATED_IN_SEVERAL_STAGES = 'REGULATED_IN_SEVERAL_STAGES',
  HIGH_EFFICIENCY_PUMP = 'HIGH_EFFICIENCY_PUMP'
}

// Helper functions
/**
 * Adds a heating system item to the state.
 * @returns A new immutable map with the added heating system item.
 */
const addItem = (state: ImmutableMap<string, HeatingSystemItem>, itemId: string, item: HeatingSystemItem) =>
  state.set(itemId, item)

/**
 * Updates an item in the state with the given new properties.
 * @returns The updated state with the modified item.
 */
const updateItem = (state: ImmutableMap<string, HeatingSystemItem>, itemId: string, newItem: Partial<HeatingSystemItem>) =>
  state.update(itemId, (value) => ({ ...value!, ...newItem }))

/**
 * Removes an item from the heating system state.
 * @returns A new immutable map with the specified item removed.
 */
const removeItem = (state: ImmutableMap<string, HeatingSystemItem>, itemId: string) =>
  state.delete(itemId)

/** Generates a unique identifier for a heating system. */
const _generateHeatingSystemId = (): string => uniqueId('heating-system-')

const defaultVentilationAndHeatingState = {
  photovoltaicSystem: null,
  areaOfFacility: null,
  peakPower: null,
  orientationType: OrientationType.NORTH,
  ventilationSystem: new Set([]),
  distribution: null,
  ventilation: null,
  fans: null,
  isHydraulicBalancing: false,
  ventilationDetails: null,
  heatingFuel: null,
  heatingFuelOption: '',
  heatingDesignation: null,
  heaterLocation: null,
  waterLocation: null,
  nightService: null,
  circulationPumpsType: null,
  heatTransferType: null,
  heatLineType: null,
  generatedBy: GeneratedByType.ELECTRIC,
  generatorYearOfManufacture: null,
  heatingPower: null,
  yearOfConstruction: null,
  decentralizedHeatingType: null,
  totalHeatingOutput: 100,
  heatingCombinedWith: new Set([]),
  heatRecovery: null,
  isHotWaterCirculation: false,
}

export const [VentilationAndHeatingContextProvider, useVentilationAndHeating] = constate(() => {

  const [ventilationAndHeatingSystem, setVentilationAndHeatingSystem] = useState<ImmutableMap<string, HeatingSystemItem>>(ImmutableMap({ 'heating-system-1': defaultVentilationAndHeatingState }))

  /** Adds a new heating system item to the list of heating system items. */
  const handleAddHeatingSystemItem = useCallback(() => {
    const itemId = _generateHeatingSystemId()
    setVentilationAndHeatingSystem(prevState => addItem(prevState, itemId, defaultVentilationAndHeatingState))
  }, [])

  /** Updates a heating system item with the given ID using the provided partial item data. */
  const handleUpdateHeatingSystemItem = useCallback((id: string, newItem: Partial<HeatingSystemItem>) => {
    setVentilationAndHeatingSystem(prevState => updateItem(prevState, id, newItem))
  }, [])

  /** Removes a heating system item from the list based on the provided item ID. */
  const handleRemoveHeatingSystemItem = useCallback((itemId: string) => {
    setVentilationAndHeatingSystem(prevState => removeItem(prevState, itemId))
  }, [])

  const currentHeatingSystem = useMemo(() => (id: string) => ventilationAndHeatingSystem.get(id), [ventilationAndHeatingSystem])

  return {
    ventilationAndHeatingSystem,
    currentHeatingSystem,
    handleAddHeatingSystemItem,
    handleRemoveHeatingSystemItem,
    handleUpdateHeatingSystemItem
  }
})

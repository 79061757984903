import { Box, Grid } from '@mui/material'
import React, { useState } from 'react'
import { Wall, useBuildingLayout } from './_main/BuildingLayout.context'

import { GRAY_900 } from 'constants/styling/theme'
import { MUICheckbox } from 'components/common/MUICheckBox'
import { MUINumberField } from 'components/common/MUINumberField'
import { SectionedBorderBox } from 'components/common/SectionedBorderBox'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'

export const BuildingLayoutWalls: React.FC = () => {

  const { t } = useTranslation(['purchase_flow'], { keyPrefix: 'energy_certificate_step.building_layout_page' })

  const {
    walls,
    setWalls
  } = useBuildingLayout()

  const [showError, setShowError] = useState<boolean>(false)

  // If any of the required fields are empty, show error for all of them.
  const handleOnInputBlur = () => {
    if (!showError) setShowError(true)
  }

  return (
    <SectionedBorderBox
      title={
        <Typography variant='text-md' fontWeight={600} color={GRAY_900}>
          {t('wall_length.wall_length_title')}
        </Typography>
      }
      sx={{ marginTop: '1.6rem' }}
    >

      <Box flexGrow={1}>
        <Grid container spacing={2}>
          {Object.values(Wall).filter(type => Object.keys(walls).includes(type)).map((type, index) => (
            <Grid item xs={12} md={6} key={index}>
              <SectionedBorderBox
                title={
                  <Typography variant='text-md' fontWeight={600} color={GRAY_900}>
                    {t('wall_length.wall')}{type}
                  </Typography>
                }
              >
                <Stack gap={0.8} alignItems='flex-start'>

                  <Stack flexDirection="row" justifyContent="flex-start" alignItems="center" gap=".8rem">

                    <MUICheckbox
                      checked={walls[type]?.mounted}
                      onChange={(e) => setWalls({ ...walls, [type]: { ...walls[type], mounted: e.target.checked } })}
                    />

                    <Typography variant="text-md" fontWeight="medium" color={GRAY_900}>{t('wall_length.mounted_wall')}</Typography>

                  </Stack>


                  <Stack flexDirection="row" justifyContent="flex-start" alignItems="center" gap=".8rem">

                    <MUICheckbox
                      checked={walls[type]?.insulated}
                      onChange={(e) => setWalls({ ...walls, [type]: { ...walls[type], insulated: e.target.checked } })}
                    />

                    <Typography variant="text-md" fontWeight="medium" color={GRAY_900}>{t('wall_length.subsequently_insulated')}</Typography>

                  </Stack>

                  <Stack flexDirection="row" justifyContent="flex-start" alignItems="center" gap=".8rem" marginTop={.8}>
                    <Box width={{ xs: '80%', lg: '45%' }}>
                      <MUINumberField
                        min={0}
                        required
                        suffix='M'
                        inputBoxWidth='100%'
                        label={t('wall_length.length')}
                        value={walls[type]?.length}
                        isError={showError && !walls[type]?.length}
                        onBlur={handleOnInputBlur}
                        onChange={(value) => setWalls({ ...walls, [type]: { ...walls[type], length: value } })}
                      />
                    </Box>


                    <Box width={{ xs: '80%', lg: '45%' }}>
                      <MUINumberField
                        min={0}
                        required={walls[type]?.insulated}
                        disabled={!walls[type]?.insulated}
                        suffix='Cm'
                        inputBoxWidth='100%'
                        label={t('wall_length.insulation')}
                        value={walls[type]?.insulation}
                        isError={showError && !walls[type]?.insulation && walls[type]?.insulated}
                        onBlur={handleOnInputBlur}
                        onChange={(value) => setWalls({ ...walls, [type]: { ...walls[type], insulation: value } })}
                      />
                    </Box>
                  </Stack>

                </Stack>

              </SectionedBorderBox>
            </Grid>
          ))}
        </Grid>
      </Box>

    </SectionedBorderBox>
  )
}

import { AssignmentAutomationStatus, minHoursForAutomation } from 'constants/assignment/assignmentAutomation'
import { AssignmentDTOIsAdministratorDTO, DealDTOIsAdministratorDTO } from 'utils/typeguards'
import { AssignmentStage, CreativeState } from 'constants/assignment'
import { useEffect, useMemo, useRef } from 'react'

import { OrderAutomationStatus } from 'models/deal'
import { ProductKind } from 'constants/product'
import { SuggestedCreativeDTO } from 'models/creative'
import { bigFromFee } from 'utils/price'
import constate from 'constate'
import { isEditingCategory } from 'utils/validators'
import moment from 'moment-timezone'
import { useAuth0 } from 'utils/auth'
import { useGalleryAssignment } from '../_main/contexts/GalleryAssignment.context'
import { useGalleryConstants } from '../_main/contexts/GalleryConstants.context'
import { useGalleryDeal } from '../_main/contexts/GalleryDeal.context'
import { useGalleryProduct } from '../_main/contexts/GalleryProduct.context'
import { useGetSuggestCreatives } from 'dataQueries'
import { useTranslation } from 'react-i18next'

/** Enumeration of all possible states of the creative assignment card in relation to creative details and automation */
export enum AssignmentCreativeCardState {
  ASSIGN_AUTOMATION = 'ASSIGN_AUTOMATION',
  ASSIGN_MANUALLY = 'ASSIGN_MANUALLY',
  CT_ACCEPTED = 'CT_ACCEPTED',
  CT_DELIVERED = 'CT_DELIVERED',
  WAITING_FOR_ACCEPT = 'WAITING_FOR_ACCEPT',
  WAITING_FOR_AUTOMATION = 'WAITING_FOR_AUTOMATION',
  AUTOMATION_AS_PART_OF_ORDER = 'AUTOMATION_AS_PART_OF_ORDER',
  NO_CT_NEEDED = 'NO_CT_NEEDED'
}

export const ctDeliveredAssignmentStages = new Set([
  AssignmentStage.VISUALS_SENT_BY_BKBN_TO_EDITOR,
  AssignmentStage.VISUALS_SENT_BY_CT_TO_BKBN,
  AssignmentStage.VISUALS_SENT_BY_EDITOR_TO_BKBN,
  AssignmentStage.VISUALS_SENT_TO_CLIENT
])

export const AssignmentAutomatedStates = new Set([
  AssignmentAutomationStatus.AUTOMATED,
  AssignmentAutomationStatus.AUTOMATION_AS_PART_OF_ORDER
])

interface ContextProps {
  assignmentId: string
}

export const [CreativeAssignmentCardContextProvider, useCreativeAssignmentCard] = constate(
  ({ assignmentId }: ContextProps) => {
    const { roles } = useAuth0()
    const { t } = useTranslation(['gallery'])

    const {
      stagesUnlockingAssignCreative,
      productKindsLockingAssignCreative,
    } = useGalleryConstants()

    const {
      isFloorPlanWithTypeVirtualVisit,
      isDroneProduct,
    } = useGalleryProduct()

    const {
      assignmentData,
      assignmentStage,
      product,
      assignmentStart,
      creative,
      selectedFavouriteCreativeId,
    } = useGalleryAssignment()

    const {
      dealData,
      containsKeysPickup,
    } = useGalleryDeal()

    const assignmentCreativeState = useMemo(() => (assignmentData && AssignmentDTOIsAdministratorDTO(assignmentData)) ? assignmentData.creativeState : undefined, [assignmentData])
    const assignmentAutomationStatus = useMemo(() => (assignmentData && AssignmentDTOIsAdministratorDTO(assignmentData)) ? assignmentData.assignmentAutomationStatus : undefined, [assignmentData])
    const creativeAlreadyAssigned = useMemo(() => assignmentCreativeState !== CreativeState.UNASSIGNED, [assignmentCreativeState])
    const isCreativeRequired = useMemo(() => !!product?.category && !isEditingCategory(product?.category) && !productKindsLockingAssignCreative.has(product.kind) && !isFloorPlanWithTypeVirtualVisit, [product, isFloorPlanWithTypeVirtualVisit, productKindsLockingAssignCreative])
    const canAssignCreative = useMemo(() => roles.isAdmin && isCreativeRequired && !creativeAlreadyAssigned && !creative && !!assignmentStage && stagesUnlockingAssignCreative.has(assignmentStage), [roles, isCreativeRequired, creativeAlreadyAssigned, creative, assignmentStage, stagesUnlockingAssignCreative])
    const isLessThanMinHoursForAutomation = useMemo(() => !!assignmentStart && moment.duration(moment(assignmentStart).diff(moment())).asHours() < minHoursForAutomation, [assignmentStart])
    const canAssignPreferredCreative = useMemo(() => !!selectedFavouriteCreativeId, [selectedFavouriteCreativeId])
    const assignmentScheduledByCT = useMemo(() => !!assignmentData && AssignmentDTOIsAdministratorDTO(assignmentData) && assignmentData.scheduledByCT, [assignmentData])

    // Assignment automation
    const isAssignmentAutomatedFromOrder = useMemo(() => {
      if (!dealData || !DealDTOIsAdministratorDTO(dealData)) return false
      if (assignmentAutomationStatus !== AssignmentAutomationStatus.AUTOMATION_AS_PART_OF_ORDER) return false
      if (dealData.orderAutomationStatus === OrderAutomationStatus.AUTOMATED_INDIVIDUAL_ASSIGNMENTS
        || dealData.orderAutomationStatus === OrderAutomationStatus.AUTOMATED
      ) return true
      return false
    }, [assignmentAutomationStatus, dealData])

    const canAutomateOrder = useMemo(
      () => (
        dealData && DealDTOIsAdministratorDTO(dealData)
        && dealData.orderAutomationStatus === OrderAutomationStatus.AUTOMATION_READY
        && assignmentAutomationStatus === AssignmentAutomationStatus.AUTOMATION_READY
      ),
      [assignmentAutomationStatus, dealData]
    )

    const canAssignmentBeAutomated = useMemo(
      () => (
        !isAssignmentAutomatedFromOrder
        && canAssignCreative
        && (assignmentAutomationStatus === AssignmentAutomationStatus.AUTOMATION_READY)
        && !canAssignPreferredCreative
      ),
      [isAssignmentAutomatedFromOrder, canAssignCreative, assignmentAutomationStatus, canAssignPreferredCreative]
    )

    const cannotAssignmentBeAutomatedInfo = useMemo(() => {
      if (!assignmentStart) return t('no_shooting_date')
      if (isDroneProduct) return t('automation_drone_product')
      if (containsKeysPickup) return t('automation_keys_pickup')
      if (isLessThanMinHoursForAutomation) return t('automation_starting_within_36_hours')

      return t('automation_not_possible')
    }, [assignmentStart, containsKeysPickup, isDroneProduct, isLessThanMinHoursForAutomation, t])

    const canStopAutomation = useMemo(() => {
      // Whether the assignment is part of an automated order
      if (isAssignmentAutomatedFromOrder) return false
      // Whether the assignment is automated and CT has not accepted yet
      return assignmentCreativeState === CreativeState.WAITING_FOR_ACCEPT && assignmentAutomationStatus === AssignmentAutomationStatus.AUTOMATED
    }, [assignmentAutomationStatus, assignmentCreativeState, isAssignmentAutomatedFromOrder])

    const creativeCardState = useMemo(() => {
      // No Creative assignment required - specific kinds like Slideshow
      if (product?.kind === ProductKind.SLIDESHOW) return AssignmentCreativeCardState.NO_CT_NEEDED
      // Creative can be automated
      if (canAssignmentBeAutomated) return AssignmentCreativeCardState.ASSIGN_AUTOMATION
      // Creative has to be assigned manually
      if (canAssignCreative && !isAssignmentAutomatedFromOrder) return AssignmentCreativeCardState.ASSIGN_MANUALLY
      // Creative is being paired by the automation
      if (assignmentAutomationStatus)
        if (AssignmentAutomatedStates.has(assignmentAutomationStatus) && assignmentCreativeState === CreativeState.UNASSIGNED)
          return AssignmentCreativeCardState.WAITING_FOR_AUTOMATION
      // Waiting for Creative to accept
      if (assignmentCreativeState === CreativeState.WAITING_FOR_ACCEPT) return AssignmentCreativeCardState.WAITING_FOR_ACCEPT
      // Creative already send the visuals
      if (!!assignmentStage && ctDeliveredAssignmentStages.has(assignmentStage)
        && (assignmentData && AssignmentDTOIsAdministratorDTO(assignmentData))
        && assignmentData?.creative
      ) return AssignmentCreativeCardState.CT_DELIVERED
      // Creative accepted the assignment
      if (creativeAlreadyAssigned) return AssignmentCreativeCardState.CT_ACCEPTED
      // If automation is AUTOMATION_OUT_OF_SCOPE && Creative is unassigned for some reason
      if (
        canAssignCreative &&
        assignmentAutomationStatus === AssignmentAutomationStatus.AUTOMATION_OUT_OF_SCOPE &&
        assignmentCreativeState === CreativeState.UNASSIGNED
      ) return AssignmentCreativeCardState.ASSIGN_MANUALLY
    }, [assignmentAutomationStatus, assignmentCreativeState, assignmentData, assignmentStage, canAssignCreative, canAssignmentBeAutomated, creativeAlreadyAssigned, isAssignmentAutomatedFromOrder, product])

    // Suggested creatives
    const suggestedCreatives = useGetSuggestCreatives(assignmentId, creativeCardState !== AssignmentCreativeCardState.ASSIGN_AUTOMATION)
    const suggestedCreativesData = useMemo(() => suggestedCreatives.data?.data, [suggestedCreatives.data?.data])

    // Cache data to prevent sudden disappearing when opening Choose manually popup which causes request data refresh.
    // Simpler and more space efficient than extending indexing of request store slice
    const cachedFirstOutreachedCT = useRef<SuggestedCreativeDTO | null>(null)
    const firstOutreachedCreative = useMemo(() => {

      if (suggestedCreatives.isSuccess && !!cachedFirstOutreachedCT.current) return cachedFirstOutreachedCT.current

      if (!suggestedCreativesData || !suggestedCreativesData?.length) return cachedFirstOutreachedCT.current || null

      cachedFirstOutreachedCT.current = suggestedCreativesData?.sort((creativeA, creativeB) => bigFromFee(creativeA.transportRemuneration)
        .minus(bigFromFee(creativeB.transportRemuneration))
        .toNumber()
      )[0]

      return cachedFirstOutreachedCT.current
    }, [suggestedCreatives.isSuccess, suggestedCreativesData])

    const noCTFound = useMemo(() => !firstOutreachedCreative && suggestedCreatives.isSuccess, [firstOutreachedCreative, suggestedCreatives.isSuccess])

    // Fetch suggested CTs when automation ready and prevent adding this suggestion to history on BE (TODO: temporary flag workaround)
    useEffect(() => {
      if (creativeCardState === AssignmentCreativeCardState.ASSIGN_AUTOMATION) {
        suggestedCreatives.refetch()
      }
      // Only trigger once when refetch otherwise goes to infinite loop
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [creativeCardState])

    return {
      assignmentAutomationStatus,
      assignmentCreativeState,
      canAssignCreative,
      canAssignmentBeAutomated,
      canAutomateOrder,
      cannotAssignmentBeAutomatedInfo,
      canStopAutomation,
      creativeAlreadyAssigned,
      creativeCardState,
      isCreativeRequired,
      isAssignmentAutomatedFromOrder,
      firstOutreachedCreative,
      noCTFound,
      assignmentScheduledByCT,
      suggestedCreatives,
      suggestedCreativesData,
    }
  })

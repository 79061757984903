import { MessageCreateDTO, MessageThreadDTO } from 'models/messageThreads'
import { EntityKeys, QueryType, getMutation } from 'utils/reactQuery'

import { useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { StatusResponse } from 'models/redux'
import { useAPI } from 'utils/API'

enum Endpoints {
  ASSIGNMENT_SEND_MESSAGE_THREADS = '/assignment/{assignmentId}/message-threads/{threadId}',
  ASSIGNMENT_GET_MESSAGE_THREADS = '/assignment/{assignmentId}/message-threads',
  ORDER_MESSAGE_GET_COUNT = '/order/{orderId}/message-threads/count-open',
}

// QUERIES

export function useGetAssignmentMessageThreads(assignmentId: string) {
  const api = useAPI<Endpoints>()

  return useQuery({
    queryKey: [EntityKeys.ASSIGNMENT, QueryType.LIST, { assignmentId }],
    queryFn: () => api.get<MessageThreadDTO[]>(
      Endpoints.ASSIGNMENT_GET_MESSAGE_THREADS,
      { assignmentId },
      true
    ),
  })
}

// MUTATIONS

export interface SendMessageThreadPayload extends MessageCreateDTO {
  assignmentId: string
  threadId: string
}

export function useSendAssignmentMessageThread() {
  const api = useAPI<Endpoints>()

  return getMutation<AxiosResponse<StatusResponse<MessageThreadDTO>>, SendMessageThreadPayload>(
    ({ assignmentId, threadId, content, attachmentFilenames }) => api.post(
      Endpoints.ASSIGNMENT_SEND_MESSAGE_THREADS,
      { assignmentId, threadId },
      { content, attachmentFilenames },
      true
    ),
    (client) => {
      client.invalidateQueries({
        queryKey: [EntityKeys.ASSIGNMENT, QueryType.LIST], // Invalidate the threads query list
      })
    }
  )
}

export function useGetOrderMessageThreadCount() {
  const api = useAPI<Endpoints>()

  return getMutation<AxiosResponse<number>, { orderId: number }>(
    ({ orderId }) => api.get(
      Endpoints.ORDER_MESSAGE_GET_COUNT,
      { orderId: orderId.toString() },
      true
    )
  )
}

import { PlatformUserBase } from 'models/user'

// Not 100% defined, just for purpose of ts-check
interface BeckaService {
  settings: {
    language: string
    extraPrechatFormDetails: Array<{
      label: string
      value: string
      transcriptFields: Array<string>
      preChatApiName: string
    }>
  }
  init: (token: string, name: string, url: string, options: object) => void
  userVerificationAPI: {
    setIdentityToken: (params: { identityTokenType: string, identityToken: string }) => unknown
  }
}

declare global {
  interface Window {
    embeddedservice_bootstrap: BeckaService
  }
}

const BECKA_ID = process.env.REACT_APP_BECKA_ID
const BECKA_NAME = process.env.REACT_APP_BECKA_NAME
const BECKA_URL = process.env.REACT_APP_BECKA_URL
const BECKA_SCRT_URL = process.env.REACT_APP_BECKA_SCRT_URL

export function initBecka(token: string, userData: PlatformUserBase, isoLanguage: string = 'en_US') {
  if (!BECKA_ID) throw new Error('BECKA_ID is undefined')
  if (!BECKA_NAME) throw new Error('BECKA_NAME is undefined')
  if (!BECKA_URL) throw new Error('BECKA_URL is undefined')
  if (!BECKA_SCRT_URL) throw new Error('BECKA_SCRT_URL is undefined')

  try {
    // Hopefully is gonna work well, but might be not triggered as the script is loaded earlier?
    window.addEventListener('onEmbeddedMessagingReady', () => {
      window.embeddedservice_bootstrap.userVerificationAPI.setIdentityToken({
        identityTokenType: 'JWT',
        identityToken: token,
      })
    })

    window.embeddedservice_bootstrap.settings.language = isoLanguage // For example, enter 'en' or 'en-US'

    window.embeddedservice_bootstrap.settings.extraPrechatFormDetails = [
      {
        label: 'Page URL',
        value: window.location.href,
        transcriptFields: ['Page_URL__c'],
        preChatApiName: 'PlatformPageURL',
      },
      {
        label: 'User ID',
        value: userData.publicId ?? '',
        transcriptFields: ['User_ID__c'],
        preChatApiName: 'PlatformUserID',
      },
      {
        label: 'Email',
        value: userData.email ?? '',
        transcriptFields: ['User_Email__c'],
        preChatApiName: 'PlatformUserEmail',
      }
    ]

    window.embeddedservice_bootstrap.init(
      BECKA_ID,
      BECKA_NAME,
      BECKA_URL,
      {
        scrt2URL: BECKA_SCRT_URL,
      }
    )
    return true
  } catch (err) {
    console.error('Error loading Becka: ', err)
    return false
  }
}

import { BLUE_600, GRAY_700 } from 'constants/styling/theme'
import { Box, Typography } from '@mui/material'
import React, { Dispatch, SetStateAction } from 'react'

import Stack from '@mui/material/Stack'
import { ToggleButton } from 'components/common/ToggleButton'
import { useTranslation } from 'react-i18next'

/** Enum of available orientation types */
export enum OrientationType {
  NORTH_WEST = 'NORTH_WEST',
  NORTH = 'NORTH',
  NORTH_EAST = 'NORTH_EAST',
  WEST = 'WEST',
  CENTERED = 'CENTERED',
  EAST = 'EAST',
  SOUTH_WEST = 'SOUTH_WEST',
  SOUTH = 'SOUTH',
  SOUTH_EAST = 'SOUTH_EAST',
}

interface Props {
  /** Onclick action to be called */
  onClick: Dispatch<SetStateAction<OrientationType>>
  /** Selected orientation type */
  type: OrientationType
}

/*
  ASCII Representation of Layout based on design:

  [NO]  [North]  [NE]

  [West]  (•)  [East]

  [SO]  [South]  [SE]

  Explanation of using Flexbox over MUI Grid:
  - NO, North, NE are on the top row, evenly spaced.
  - West, (•), and East are in the middle row, with West and East flanking the center dot.
  - SO, South, SE are on the bottom row, again evenly spaced.

  The key challenge based on design is that the center element (the dot, (•)) needs to adjust dynamically based on 
  the sizes of the "West" and "East" buttons, which overflow into the center. This behavior is difficult to achieve 
  with a strict grid layout, as grid elements are often tightly constrained into proportionate gaps.
  Using flexbox allows the center dot (•) to stay centered and adjust its size dynamically 
  based on the space taken by "West" and "East."  
*/
export const BuildingOrientation: React.FC<Props> = ({ onClick, type }) => {
  const { t } = useTranslation(['purchase_flow'])

  const TOP = 'top'
  const MIDDLE = 'middle'
  const BOTTOM = 'bottom'

  const orientations = [
    { type: OrientationType.NORTH_WEST, row: TOP },
    { type: OrientationType.NORTH, row: TOP },
    { type: OrientationType.NORTH_EAST, row: TOP },
    { type: OrientationType.WEST, row: MIDDLE },
    { type: OrientationType.EAST, row: MIDDLE },
    { type: OrientationType.SOUTH_WEST, row: BOTTOM },
    { type: OrientationType.SOUTH, row: BOTTOM },
    { type: OrientationType.SOUTH_EAST, row: BOTTOM },
  ]

  const renderToggleButton = (orientation: { type: OrientationType, row: string }) => {
    const isFullWidth = [OrientationType.WEST, OrientationType.NORTH, OrientationType.SOUTH, OrientationType.EAST].includes(orientation.type)
    return (
      <ToggleButton
        key={orientation.type}
        isSelected={type === orientation.type}
        label={t(`orientation_type.${orientation.type}`)}
        sx={{
          width: isFullWidth ? '6.4rem' : '4.6rem',
          height: '4rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          ...(orientation.row === MIDDLE && { flexShrink: 0 }),
        }}
        onClick={() => onClick?.(orientation.type)}
        typographyVariant='text-md'
      />
    )
  }

  const OrientationRow = ({ orientations }: { orientations: { type: OrientationType, row: string }[] }) => (
    <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'center', width: '100%' }}>
      {orientations.map(renderToggleButton)}
    </Box>
  )

  return (
    <Stack gap={1}>

      <Typography variant='text-sm' fontWeight="medium" color={GRAY_700}>
        {t('orientation_title')}
      </Typography>

      <Stack flexDirection="row" flexWrap="wrap" justifyContent="center">
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative', gap: '1rem' }}>

          <OrientationRow orientations={orientations.filter(o => o.row === TOP)} />

          <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
            {renderToggleButton({ type: OrientationType.WEST, row: MIDDLE })}
            <Box sx={{ width: '2.8rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Box sx={{ width: '.7rem', height: '.7rem', borderRadius: '50%', backgroundColor: BLUE_600 }} />
            </Box>
            {renderToggleButton({ type: OrientationType.EAST, row: MIDDLE })}
          </Box>

          <OrientationRow orientations={orientations.filter(o => o.row === BOTTOM)} />

        </Box>
      </Stack>
    </Stack>
  )
}

import { DistributionType, FansType, VentilationSystemType, VentilationType, useVentilationAndHeating } from './_main/VentilationAndHeating.context'
import { GRAY_700, GRAY_900 } from 'constants/styling/theme'
import React, { useMemo, useState } from 'react'

import { BorderBoxWrapper } from 'components/common/BorderBoxWrapper'
import { Box } from '@mui/material'
import { MUINumberField } from 'components/common/MUINumberField'
import { MUIRadio } from 'components/common/MUIRadio'
import { PRIMARY_FIELD_WIDTH } from './HeatingSystem/_main/HeatingSystem.component'
import { SectionedBorderBox } from 'components/common/SectionedBorderBox'
import { SelectableButtonsGrid } from 'components/common/SelectableButtonsGrid'
import Stack from '@mui/material/Stack'
import { ToggleFilterValue } from 'components/common/ToggleFilter'
import Typography from '@mui/material/Typography'
import { VentilationHeatingProps } from './_main/VentilationAndHeating.component'
import { useTranslation } from 'react-i18next'

/**
 * @component VentilationSystem 
 * @description Renders options for the user to provide information about the ventilation. 
 * 
 * @example
 *   <VentilationSystem id="heating-system-1" />
 */
export const VentilationSystem: React.FC<VentilationHeatingProps> = ({ id }) => {

  const { t } = useTranslation(['purchase_flow'], { keyPrefix: 'energy_certificate_step.energy_heating_page.ventilation_system' })

  const {
    handleUpdateHeatingSystemItem,
    currentHeatingSystem
  } = useVentilationAndHeating()

  const {
    ventilationSystem,
    distribution,
    ventilation,
    fans,
    heatRecovery,
  } = currentHeatingSystem(id) || {}

  // Available ventilation system types mapped to objects
  const ventilationSystemToggleValues: ToggleFilterValue<VentilationSystemType>[] = useMemo(
    () => {
      const availableTypes = Object.values(VentilationSystemType)

      return availableTypes.map((type) => {
        return {
          value: type,
          displayName: t(`ventilation_system_type.${type}`),
        }
      })
      // [t] is not a dependency because it is a constant function
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []
  )

  const [showError, setShowError] = useState<boolean>(false)

  // If any of the required fields are empty, show error for all of them.
  const handleOnInputBlur = () => {
    if (!showError) setShowError(true)
  }

  return (
    <SectionedBorderBox
      title={
        <Typography variant='text-md' fontWeight={600} color={GRAY_900}>
          {t('ventilation_system_title')}
        </Typography>
      }
    >
      <Stack
        sx={{
          flexDirection: { xs: 'column', lg: 'row' }
        }}
        spacing={2}
        width="100%"
      >
        <Stack alignItems="flex-start" gap={1.6} width={{ xs: '100%' }} marginBottom={{ xs: '2.8rem', sm: 0 }}>

          <Typography variant='text-sm' fontWeight="medium" color={GRAY_700}>
            {t('ventilation_system_title')}
          </Typography>

          <SelectableButtonsGrid
            values={ventilationSystemToggleValues}
            selectedValue={Array.from(ventilationSystem || [])}
            onSelect={type => handleUpdateHeatingSystemItem(id, { ventilationSystem: new Set([...(ventilationSystem || []), type]) })}
            onUnselect={type => handleUpdateHeatingSystemItem(id, { ventilationSystem: new Set([...ventilationSystem || []].filter(item => item !== type)) })}
            showCheckbox
            activeButtonClickable
          />

          {!!ventilationSystem?.size && ventilationSystem.has(VentilationSystemType.VENTILATION_SYSTEM_AVAILABLE) &&
            <BorderBoxWrapper padding={2} width='100%'>

              {/* DISTRIBUTION */}
              <Stack gap=".8rem">

                <Typography variant="text-sm" fontWeight="medium" color={GRAY_700}>
                  {t('distribution')}
                </Typography>

                {Object.values(DistributionType).map(type => {
                  return (
                    <Stack key={type} gap=".8rem" flexDirection="row">
                      <MUIRadio
                        checked={distribution === type}
                        onChange={() => handleUpdateHeatingSystemItem(id, { distribution: type })}
                      />

                      <Typography variant="text-sm" fontWeight="medium" color={GRAY_900}>
                        {t(`distribution_type.${type}`)}
                      </Typography>
                    </Stack>
                  )
                })}

              </Stack>


              {/* TYPE OF VENTILATION */}
              <Stack gap=".8rem" paddingTop={1.6}>

                <Typography variant="text-sm" fontWeight="medium" color={GRAY_700}>
                  {t('ventilation')}
                </Typography>

                {Object.values(VentilationType).map(type => {
                  return (
                    <Stack key={type} gap=".8rem" flexDirection="row">
                      <MUIRadio
                        checked={ventilation === type}
                        onChange={() => handleUpdateHeatingSystemItem(id, { ventilation: type })}
                      />

                      <Typography variant="text-sm" fontWeight="medium" color={GRAY_900}>
                        {t(`ventilation_type.${type}`)}
                      </Typography>
                    </Stack>
                  )
                })}

              </Stack>


              {/* FANS */}
              <Stack gap=".8rem" paddingTop={1.6}>

                <Typography variant="text-sm" fontWeight="medium" color={GRAY_700}>
                  {t('fans')}
                </Typography>

                {Object.values(FansType).map(type => {
                  return (
                    <Stack key={type} gap=".8rem" flexDirection="row">
                      <MUIRadio
                        checked={fans === type}
                        onChange={() => handleUpdateHeatingSystemItem(id, { fans: type })}
                      />

                      <Typography variant="text-sm" fontWeight="medium" color={GRAY_900}>
                        {t(`fans_type.${type}`)}
                      </Typography>
                    </Stack>
                  )
                })}
              </Stack>

              {/* HEAT RECOVERY */}
              <Box width={{ xs: '80%', lg: PRIMARY_FIELD_WIDTH }} paddingTop={1.6}>
                <MUINumberField
                  min={0}
                  max={100}
                  suffix='%'
                  required
                  inputBoxWidth='100%'
                  label={t('heat_recovery')}
                  value={heatRecovery}
                  isError={showError && !heatRecovery}
                  onBlur={handleOnInputBlur}
                  onChange={(value) => handleUpdateHeatingSystemItem(id, { heatRecovery: value })}
                />
              </Box>

            </BorderBoxWrapper>
          }

        </Stack>
      </Stack>
    </SectionedBorderBox>
  )
}

import { Dispatch, FC, SetStateAction, useMemo } from 'react'
import { Stack, useMediaQuery } from '@mui/material'

import { AssignmentStage } from 'constants/assignment'
import { ClientGalleryDocuments } from '../ClientGalleryDocuments'
import { ClientGalleryEmptyContent } from '../ClientGalleryEmptyContent'
import { ClientGalleryPhoto } from '../ClientGalleryPhoto'
import { ClientGalleryVirtualVisit } from '../ClientGalleryVirtualVisit'
import { FeatureFlag } from 'utils/featureFlags'
import { MOBILE_VIEW_QUERY } from 'constants/styling/theme'
import { Tab } from '../_main/ClientGallery.controller'
import { useFlag } from '@unleash/proxy-client-react'
import { useGalleryAssignment } from 'components/pages/Gallery/_main/contexts/GalleryAssignment.context'
import { useGalleryDeal } from 'components/pages/Gallery/_main/contexts/GalleryDeal.context'
import { useGalleryProduct } from 'components/pages/Gallery/_main/contexts/GalleryProduct.context'
import { useGalleryVirtualVisit } from 'components/pages/Gallery/_main/contexts/GalleryVirtualVisit.context'
import { useTranslation } from 'react-i18next'

export const AssignmentStageWithDocumentSendToClient = new Set([
  AssignmentStage.DOCUMENTS_SENT_TO_CLIENT,
  AssignmentStage.VISUALS_SENT_TO_CLIENT
])

/**
 * @interface Props
 */
interface Props {
  /** Set current active tab */
  setActiveTab: Dispatch<SetStateAction<Tab>>
}

/**
 * Client gallery content.
 *
 * @example <ClientGalleryContent />
 */
export const ClientGalleryContent: FC<Props> = ({ setActiveTab }) => {
  const allowAuthoritiesDocs = useFlag(FeatureFlag.ALLOW_AUTHORITIES_DOCUMENTS)
  const { t } = useTranslation(['gallery'])

  const isMobileView = useMediaQuery(MOBILE_VIEW_QUERY)
  const { isVirtualVisit, isDocumentProduct } = useGalleryProduct()
  const { virtualVisitID } = useGalleryVirtualVisit()
  const { assignmentStage } = useGalleryAssignment()
  const { requiresPowerOfAttorneyDocuments } = useGalleryDeal()

  const isVisualSendToClient = useMemo(() => assignmentStage === AssignmentStage.VISUALS_SENT_TO_CLIENT, [assignmentStage])
  const isDocSendToClient = useMemo(() => assignmentStage && AssignmentStageWithDocumentSendToClient.has(assignmentStage), [assignmentStage])
  const isWaitingPoADoc = useMemo(() => assignmentStage === AssignmentStage.WAITING_FOR_POWER_OF_ATTORNEY_DOCUMENTS, [assignmentStage])
  const isDocumentInStageProgress = useMemo(() =>
    assignmentStage === AssignmentStage.WAITING_FOR_CLIENT_TO_UPLOAD_DOCUMENTS
    || assignmentStage === AssignmentStage.REQUEST_SENT_TO_PROCESSOR
    || assignmentStage === AssignmentStage.DOCUMENTS_RECEIVED_FROM_PROCESSOR, [assignmentStage])

  const isShowDefaultInProgressContent = useMemo(() => {
    if (isVisualSendToClient) return false
    if (isDocSendToClient || isWaitingPoADoc) return false

    return true
  }, [isDocSendToClient, isVisualSendToClient, isWaitingPoADoc])

  const hasMessageRequests = useMemo(() => {
    if (!allowAuthoritiesDocs) return false
    if (!isDocumentProduct) return false
    if (!isDocumentInStageProgress) return false
    if (isDocSendToClient) return false

    // TODO: Implement the requests logic later when BE sends the available requests per assignment
    const hasMessageRequests = assignmentStage === AssignmentStage.WAITING_FOR_CLIENT_TO_UPLOAD_DOCUMENTS

    return hasMessageRequests
  }, [allowAuthoritiesDocs, assignmentStage, isDocSendToClient, isDocumentInStageProgress, isDocumentProduct])

  return (
    <Stack width="100%" paddingTop={isMobileView ? '10rem' : '0'}>

      {/** ASSIGNMENT NOT DELIVERED TO CLIENT */}
      {isShowDefaultInProgressContent && !hasMessageRequests && (
        <ClientGalleryEmptyContent
          title={t('client_gallery.empty')}
          buttonText={t('client_gallery.empty_action')}
          onButtonClick={() => setActiveTab(Tab.ORDER_INFO)}
        />
      )}

      {/** ASSIGNMENT NOT DELIVERED TO CLIENT AND HAS REQUESTS. Documents */}
      {hasMessageRequests &&
        <ClientGalleryEmptyContent
          title={t('client_gallery.available_requests.title')}
          description={t('client_gallery.available_requests.description')}
          buttonText={t('client_gallery.available_requests.see_request')}
          onButtonClick={() => setActiveTab(Tab.REQUESTS)}
        />
      }

      {/** ASSIGNMENT DELIVERED TO CLIENT. Photo, staging, video... etc */}
      {isVisualSendToClient && !isVirtualVisit && !isDocumentProduct && <ClientGalleryPhoto />}

      {/** ASSIGNMENT DELIVERED TO CLIENT. Matterport - Virtual visit */}
      {isVisualSendToClient && isVirtualVisit && !!virtualVisitID && (
        <ClientGalleryVirtualVisit />
      )}

      {/** ASSIGNMENT WAITING FOR PoA UPLOAD. Documents */}
      {allowAuthoritiesDocs && isDocumentProduct && isWaitingPoADoc && requiresPowerOfAttorneyDocuments &&
        <ClientGalleryEmptyContent
          title={t('client_gallery.empty_waiting_power_of_attorney')}
          description={t('client_gallery.empty_waiting_power_of_attorney_description')}
          buttonText={t('client_gallery.empty_waiting_power_of_attorney_action')}
          onButtonClick={() => setActiveTab(Tab.ORDER_INFO)}
        />
      }

      {/** ASSIGNMENT DELIVERED TO CLIENT. Documents */}
      {allowAuthoritiesDocs && isDocSendToClient && isDocumentProduct && <ClientGalleryDocuments />}

    </Stack>
  )
}

import { useEffect, useMemo, useState } from 'react'

import { useFlag } from '@unleash/proxy-client-react'
import { useSnackbar } from 'components/contexts/SnackbarService.context'
import { useUserData } from 'components/contexts/UserDataContext'
import constate from 'constate'
import { useGetPropertyDetail } from 'dataQueries'
import { Nullable } from 'models/helpers'
import { PropertyType } from 'models/property'
import { GoogleAPIPlace } from 'models/purchaseFlow'
import { FeatureFlag } from 'utils/featureFlags'
import { useBkbnSearchParams } from 'utils/hooks/useBkbnSearchParams'
import { getPropertyAddressFromGooglePlace } from 'utils/location/googlePlaceUtils'

export const [PurchaseFlowRealEstatePropertyContextProvider, usePurchaseFlowRealEstateProperty] = constate(() => {
  const allowAddressStepForAllProducts = useFlag(FeatureFlag.ALLOW_ADDRESS_STEP_FOR_ALL_PRODUCTS)

  const { currentUserWorkspace, personalUserWorkspaces } = useUserData()
  const { spawnWarningToast } = useSnackbar()

  const params = useBkbnSearchParams()

  const requestedPropertyId = useMemo(() => params.get('realtyId') ?? null, [params])
  const propertyWorkspaceId = useMemo(() => (currentUserWorkspace ?? personalUserWorkspaces[0])?.id, [currentUserWorkspace, personalUserWorkspaces])

  const getPropertyQuery = useGetPropertyDetail(requestedPropertyId, propertyWorkspaceId, false)
  const requestedPropertyData = useMemo(() => getPropertyQuery.data, [getPropertyQuery.data])
  const [isPropertyPrefilled, setIsPropertyPrefilled] = useState<boolean | null>(null)

  const [selectedPropertyType, setSelectedPropertyType] = useState<Nullable<PropertyType>>(null)
  const [selectedAssignmentPlace, setSelectedAssignmentPlace] = useState<GoogleAPIPlace | null>(null)
  const [compassAddress, setCompassAddress] = useState<GoogleAPIPlace | null>(null)

  const realEstateProperty = useMemo(() => {
    let address: GoogleAPIPlace | null = null
    if (selectedAssignmentPlace) address = selectedAssignmentPlace
    if (!allowAddressStepForAllProducts && compassAddress) address = compassAddress

    return {
      propertyType: selectedPropertyType,
      address: getPropertyAddressFromGooglePlace(address ?? null),
    }
  }, [allowAddressStepForAllProducts, compassAddress, selectedAssignmentPlace, selectedPropertyType])

  useEffect(() => {
    if (isPropertyPrefilled !== null) return

    if (!requestedPropertyId) {
      setIsPropertyPrefilled(false)
      return
    }

    if (getPropertyQuery.isError) {
      setIsPropertyPrefilled(false)
      return
    }

    if (getPropertyQuery.isSuccess && requestedPropertyData) {
      const data = requestedPropertyData
      setSelectedPropertyType(data.propertyType)

      setIsPropertyPrefilled(true)
    }

    // Omit "isPropertyPrefilled"
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPropertyQuery.data, getPropertyQuery.error, getPropertyQuery.isError, getPropertyQuery.isSuccess, requestedPropertyData, requestedPropertyId, spawnWarningToast])

  return {
    compassAddress,
    setCompassAddress,
    selectedPropertyType,
    setSelectedPropertyType,
    selectedAssignmentPlace,
    setSelectedAssignmentPlace,
    realEstateProperty,
    isPropertyPrefilled,
    requestedPropertyData,
    getPropertyQuery,
  }
})

import { CirculationPumpsType, HeatTransferType, HeatingLineType } from '../_main/VentilationAndHeating.context'
import { DropdownInputTypes, MUIDropdownInput } from 'components/common/MUIDropdownInput/MUIDropdownInput.component'

import { Box } from '@mui/material'
import { GRAY_900 } from 'constants/styling/theme'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { MUIDivider } from 'components/common/MUIDivider'
import { MUIDropdown } from 'components/common/MUIDropdown'
import { MUIDropdownItem } from 'components/common/MUIDropdownItem'
import { MUITooltip } from 'components/common/MUITooltip'
import { PRIMARY_FIELD_WIDTH } from './_main/HeatingSystem.component'
import React from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { VentilationHeatingProps } from '../_main/VentilationAndHeating.component'
import { useTranslation } from 'react-i18next'
import { useVentilationAndHeating } from '../_main/VentilationAndHeating.context'

/**
 * @component HandoverAndPumping
 * @description Renders options for the user to provide information about handover and pumping.
 *
 * @example
 *   <HandoverAndPumping id="heating-system-1" />
 */
export const HandoverAndPumping: React.FC<VentilationHeatingProps> = ({ id }) => {

  const { t } = useTranslation(['purchase_flow'], { keyPrefix: 'energy_certificate_step.energy_heating_page.heating_system' })

  const {
    currentHeatingSystem,
    handleUpdateHeatingSystemItem,
  } = useVentilationAndHeating()

  const {
    heatTransferType,
    heatLineType,
    circulationPumpsType
  } = currentHeatingSystem(id) || {}

  return (
    <>
      <Box paddingTop={2} width="100%">
        <Typography color={GRAY_900} variant='text-md' fontWeight={600}>{t('handover_and_pumping')}</Typography>
        <MUIDivider margin={2} />
      </Box>

      {/* HEAT TRANSFER */}
      <Stack gap="1.8rem" width={{ xs: '100%', lg: PRIMARY_FIELD_WIDTH }} position="relative">
        <MUIDropdown
          sx={{ width: '100%' }}
          button={(isOpen, action) => (
            <MUIDropdownInput
              readOnly
              required
              label={t('heat_transfer')}
              type={DropdownInputTypes.SELECTOR}
              value={t(!!heatTransferType ? `heat_transfer_type.${heatTransferType}` : 'select_heat_transfer_option')}
              onClick={action}
            />
          )}
        >
          {Object.values(HeatTransferType).map(type => (
            <MUIDropdownItem key={type} onClick={() => handleUpdateHeatingSystemItem(id, { heatTransferType: type })}>
              <Typography variant="text-md" color={GRAY_900} fontWeight="medium">
                {t(`heat_transfer_type.${type}`)}
              </Typography>
            </MUIDropdownItem>
          ))}
        </MUIDropdown>
      </Stack>

      {/* HEAT LINE */}
      <Stack gap="1.8rem" width={{ xs: '100%', lg: PRIMARY_FIELD_WIDTH }} position="relative">
        <MUIDropdown
          sx={{ width: '100%' }}
          button={(isOpen, action) => (
            <MUIDropdownInput
              readOnly
              required
              label={t('heat_line')}
              type={DropdownInputTypes.SELECTOR}
              value={t(!!heatLineType ? `heat_line_type.${heatLineType}` : 'select_heat_line_option')}
              onClick={action}
            />
          )}
        >
          {Object.values(HeatingLineType).map(type => (
            <MUIDropdownItem key={type} onClick={() => handleUpdateHeatingSystemItem(id, { heatLineType: type })}>
              <Typography variant="text-md" color={GRAY_900} fontWeight="medium">
                {t(`heat_line_type.${type}`)}
              </Typography>
            </MUIDropdownItem>
          ))}
        </MUIDropdown>
      </Stack>

      {/* CIRCULATION PUMPS */}
      <Stack gap="1.8rem" width={{ xs: '100%', lg: PRIMARY_FIELD_WIDTH }} position="relative">
        <MUIDropdown
          sx={{ width: '100%' }}
          button={(isOpen, action) => (
            <MUIDropdownInput
              readOnly
              required
              label={t('circulation_pumps')}
              tooltip={
                <MUITooltip content={t('circulation_pumps_tooltip')}>
                  <InfoOutlinedIcon fontSize="medium" sx={{ paddingTop: '.4rem' }} />
                </MUITooltip>
              }
              type={DropdownInputTypes.SELECTOR}
              value={t(!!circulationPumpsType ? `circulation_pumps_type.${circulationPumpsType}` : 'select_circulation_pumps_option')}
              onClick={action}
            />
          )}
        >
          {Object.values(CirculationPumpsType).map(type => (
            <MUIDropdownItem key={type} onClick={() => handleUpdateHeatingSystemItem(id, { circulationPumpsType: type })}>
              <Typography variant="text-md" color={GRAY_900} fontWeight="medium">
                {t(`circulation_pumps_type.${type}`)}
              </Typography>
            </MUIDropdownItem>
          ))}
        </MUIDropdown>
      </Stack>
    </>
  )
}

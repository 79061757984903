import { ISODateString } from 'models/misc'

/** 
 * Enum representing the state of a message thread.
 * 
 * @property {string} OPEN - Indicates that the thread is currently open and active.
 * @property {string} CLOSED - Indicates that the thread is closed and no longer active.
 */
export enum MessageThreadState {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}

/** @interface MessageDTO Represents a message DTO. */
export interface MessageDTO {
  /** Unique identifier of the message. */
  id: string
  /** Timestamp when the message was created. */
  timestamp: string
  /** Identifier of the author of the message (optional). */
  authorId?: string
  /** Content of the message. */
  content: string
  /** Array of filenames of attachments to the message (optional). */
  attachmentFilenames: string[]
}

/** @interface MessageThreadDTO Represents a message thread DTO in the system. */
export interface MessageThreadDTO {
  /** Unique identifier of the message thread. */
  id: string
  /** Identifier of the user who authored the thread (optional). */
  authorUserId?: string
  /** The title of the message thread. */
  title: string
  /** ISO string representing the creation date of the thread. */
  createdAt: ISODateString
  /** ISO string representing the last update date of the thread. */
  updatedAt: ISODateString
  /** ISO string representing the date the thread was closed (optional). */
  closedAt?: ISODateString
  /** Array of user identifiers who are participants in the thread. */
  participants: string[]
  /** Identifier of the resource associated with the thread. */
  resourceId: string
  /** The current state of the message thread. */
  state: MessageThreadState
  /** Array of messages within the thread. */
  messages: MessageDTO[]
  /** ISO string representing the date of the last message in the thread (optional). */
  lastMessageDate?: ISODateString
}

/** @interface MessageCreateDTO DTO for creating a new message. */
export interface MessageCreateDTO {
  /** The content of the message to be created. */
  content: string
  /** Array of filenames of attachments to the message (optional). */
  attachmentFilenames: string[]
}

/** @interface MessageThreadCreateDTO DTO for creating a new message thread. */
export interface MessageThreadCreateDTO {
  /** Title of the message thread. */
  title: string
  /** Initial message to start the thread. */
  initialMessage: MessageCreateDTO
}

import React, { useMemo } from 'react'

import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded'
import Collapse from '@mui/material/Collapse'
import Fade from '@mui/material/Fade'
import { HeatingSystemItem } from './HeatingSystemItem'
import { MUIButton } from 'components/common/MUIButton'
import { MUIDivider } from 'components/common/MUIDivider'
import Stack from '@mui/material/Stack'
import { TransitionGroup } from 'react-transition-group'
import { useHeatingSystem } from './HeatingSystem.context'
import { useTranslation } from 'react-i18next'

/** Enum representing different types of fuel used in heating systems. */
export enum FuelType {
  HEATING_OIL = 'HEATING_OIL',
  ORGANIC_OIL = 'ORGANIC_OIL',
  NATURAL_GAS = 'NATURAL_GAS',
  BIOGAS = 'BIOGAS',
  LIQUID_GAS = 'LIQUID_GAS',
  HARD_COAL = 'HARD_COAL',
  LIGNITE = 'LIGNITE',
  PIECE_WOOD = 'PIECE_WOOD',
  WOOD_CHIPS = 'WOOD_CHIPS',
  WOOD_PELLETS = 'WOOD_PELLETS',
  NEAR_DISTRICT_HEATING = 'NEAR_DISTRICT_HEATING',
  ELECTRICITY = 'ELECTRICITY',
}

/** Enum representing different types of hot water systems. */
export enum HotWaterType {
  CENTRALLY_STANDARD = 'CENTRALLY_STANDARD', // Heating generates hot water centrally standard
  CONTINUES_OR_BOILER_WW = 'CONTINUES_OR_BOILER_WW', // Continues water heater or boiler WW
  DESCENTRALISED_ELECTRIC = 'DESCENTRALISED_ELECTRIC', // Decentralised electric
  INSTANTANEOUS_OR_SOLAR_POWERED = 'INSTANTANEOUS_OR_SOLAR_POWERED', // Instantaneous water or boiler with solar power
  KNOWN_CONSUMPTION = 'KNOWN_CONSUMPTION', // I know the hot water consumption in kWh
  DOES_NOT_CONTAIN = 'DOES_NOT_CONTAIN', // Plant does not contain hot water production
}

/** Enum representing the types of units that can be captured. */
export enum UnitCaptureType {
  KWH = 'KWH',
  KG = 'KG',
}

/**
 * @component HeatingSystem renders a list of heating system items and provides functionality
 * to add new items.
 *
 * @example
 *   <HeatingSystem />
 */
export const HeatingSystem: React.FC = () => {

  const { t } = useTranslation(['heating_system'])

  const { heatingSystemItems, handleAddHeatingSystemItem } = useHeatingSystem()

  /** Sorted heating system items by their IDs considering numeric values for proper ordering. */
  const sortedHeatingSystemItems = useMemo(() => {
    if (!heatingSystemItems) return
    const arrayHeatinngSystemItems = heatingSystemItems.toArray()

    return arrayHeatinngSystemItems.sort(([idA], [idB]) => idA.localeCompare(idB, undefined, { numeric: true }))
  }, [heatingSystemItems])

  return (
    <Fade in={true}>
      <Stack gap={2}>
        {/** Heating system items */}
        <TransitionGroup component={null}>
          {sortedHeatingSystemItems && sortedHeatingSystemItems.map(([id], index) =>
            <Collapse key={id}>
              <HeatingSystemItem id={id} index={index} />
            </Collapse>
          )}
        </TransitionGroup>

        {/** Add new items */}
        <MUIDivider>
          <MUIButton
            type='secondaryBorder'
            startIcon={<AddCircleOutlineRoundedIcon fontSize='large' />}
            onClick={() => handleAddHeatingSystemItem()}
          >
            {t('add_heating_system')}
          </MUIButton>
        </MUIDivider>

      </Stack>
    </Fade>
  )
}

import { CORAL_600, GRAY_400, GRAY_700, GRAY_800, GRAY_900 } from 'constants/styling/theme'
import { DropdownInputTypes, MUIDropdownInput } from 'components/common/MUIDropdownInput/MUIDropdownInput.component'
import { FuelType, HotWaterType, UnitCaptureType } from '../HeatingSystem.component'
import React, { useMemo, useState } from 'react'

import AddIcon from '@mui/icons-material/Add'
import { BillingPeriod } from '../BillingPeriod'
import { BorderBoxWrapper } from 'components/common/BorderBoxWrapper'
import Box from '@mui/material/Box'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import Grid from '@mui/material/Grid'
import Grow from '@mui/material/Grow'
import IconButton from '@mui/material/IconButton'
import { MUIButton } from 'components/common/MUIButton'
import { MUIDatePicker } from 'components/common/MUIDatePicker'
import { MUIDivider } from 'components/common/MUIDivider'
import { MUIDropdown } from 'components/common/MUIDropdown'
import { MUIDropdownItem } from 'components/common/MUIDropdownItem'
import { MUIRadio } from 'components/common/MUIRadio'
import { SectionedBorderBox } from 'components/common/SectionedBorderBox'
import Stack from '@mui/material/Stack'
import { TransitionGroup } from 'react-transition-group'
import Typography from '@mui/material/Typography'
import { useHeatingSystem } from '../HeatingSystem.context'
import { useTranslation } from 'react-i18next'

/** @interface Props for the HeatingSystemItem component. */
interface Props {
  /** The unique identifier for the heating system item. */
  id: string
  /** The index used to display the heating system numeration. */
  index: number
}

/**
 * @component
 * HeatingSystemItem represents a single heating system item within the consumption certificate step
 * of the purchase flow.
 *
 * @example
 * <HeatingSystemItem id="heating-system-1" index={0} />
 */
export const HeatingSystemItem: React.FC<Props> = ({ id, index }) => {

  const { t } = useTranslation(['heating_system'])

  const {
    heatingSystemItems,
    handleUpdateHeatingSystemItem,
    handleRemoveHeatingSystemItem,
    handleAddBillingPeriod,
  } = useHeatingSystem()

  const [showError, setShowError] = useState<boolean>(false)

  const currentHeatingSystem = useMemo(() => heatingSystemItems.get(id), [heatingSystemItems, id])

  /** Sorted billing period items by their IDs considering numeric values for proper ordering. */
  const sortedBillingPeriodItems = useMemo(() => {
    if (!currentHeatingSystem?.billingPeriodItems) return
    const arrayBillingPeriodItems = currentHeatingSystem.billingPeriodItems.toArray()

    return arrayBillingPeriodItems.sort(([idA], [idB]) => idA.localeCompare(idB, undefined, { numeric: true }))
  }, [currentHeatingSystem?.billingPeriodItems])

  const showUnitCaptureError = useMemo(() => showError && !currentHeatingSystem?.unitCaptureType, [currentHeatingSystem?.unitCaptureType, showError])

  // If any of the heating system fields are empty, show error for all of them.
  const handleOnInputBlur = () => {
    if (!showError) setShowError(true)
  }

  return (
    <SectionedBorderBox title={
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant='text-md' fontWeight={600} color={GRAY_900}>
          {`${t('heating_system_title')} #${index + 1}`}
        </Typography>

        {index > 0 &&
          <IconButton
            aria-label='delete-heating-system'
            sx={{ width: '3rem', height: '3rem' }}
            onClick={() => handleRemoveHeatingSystemItem(id)}
          >
            <CloseRoundedIcon fontSize='large' sx={{ color: GRAY_800 }} />
          </IconButton>
        }
      </Stack>
    }>

      <Stack alignItems="flex-start" gap={2}>

        <MUIDatePicker
          required
          views={['year']}
          label={t('generator_year_manufacture')}
          value={currentHeatingSystem?.yearOfManufacture}
          isError={showError && !currentHeatingSystem?.yearOfManufacture}
          onInputBlur={handleOnInputBlur}
          onChange={(newValue) => handleUpdateHeatingSystemItem(id, { yearOfManufacture: newValue })}
        />

        <Stack direction="row" flexWrap="wrap" width="100%" gap={2}>

          {/** FUEL */}
          <Box width={{ xs: '100%', sm: '40rem', lg: '50rem' }} position="relative">
            <MUIDropdown
              sx={{ width: '100%' }}
              button={(_, onClick) => (
                <MUIDropdownInput
                  required
                  label={t('fuel')}
                  type={DropdownInputTypes.SELECTOR}
                  value={t(!!currentHeatingSystem?.fuelType ? `fuel_type.${currentHeatingSystem?.fuelType}` : 'select_fuel_option')}
                  isError={showError && !currentHeatingSystem?.fuelType}
                  onClick={onClick}
                />
              )}
            >
              {Object.values(FuelType).map((fuelTypeItem) => (
                <MUIDropdownItem key={fuelTypeItem} onClick={() => handleUpdateHeatingSystemItem(id, { fuelType: fuelTypeItem })}>
                  <Typography variant="text-md" color={GRAY_900} fontWeight="medium">
                    {t(`fuel_type.${fuelTypeItem}`)}
                  </Typography>
                </MUIDropdownItem>
              ))}
            </MUIDropdown>
          </Box>

          {/** HOT WATER */}
          <Box width={{ xs: '100%', sm: '40rem', lg: '50rem' }} position="relative">
            <MUIDropdown
              sx={{ width: '100%' }}
              button={(_, onClick) => (
                <MUIDropdownInput
                  required
                  label={t('hot_water')}
                  type={DropdownInputTypes.SELECTOR}
                  value={t(!!currentHeatingSystem?.hotWaterType ? `hot_water_type.${currentHeatingSystem.hotWaterType}` : 'select_hot_water_option')}
                  isError={showError && !currentHeatingSystem?.hotWaterType}
                  onClick={onClick}
                />
              )}
            >
              <Box>
                {Object.values(HotWaterType).map((hotWaterTypeItem) => (
                  <MUIDropdownItem
                    key={hotWaterTypeItem}
                    onClick={() => handleUpdateHeatingSystemItem(id, { hotWaterType: hotWaterTypeItem })}
                  >
                    <Typography variant="text-md" color={GRAY_900} fontWeight="medium">
                      {t(`hot_water_type.${hotWaterTypeItem}`)}
                    </Typography>
                  </MUIDropdownItem>
                ))}
              </Box>
            </MUIDropdown>
          </Box>

        </Stack>

        {/** UNIT CAPTURE */}
        <Stack gap={0.6}>

          <Typography variant='text-sm' fontWeight='medium' color={showUnitCaptureError ? CORAL_600 : GRAY_700}>
            {t('unit_capture')}{'*'}
          </Typography>

          {Object.values(UnitCaptureType).map((unitCaptureTypeItem) => (
            <Stack key={unitCaptureTypeItem} gap=".8rem" flexDirection="row">
              <MUIRadio
                checked={currentHeatingSystem?.unitCaptureType === unitCaptureTypeItem}
                onChange={() => handleUpdateHeatingSystemItem(id, { unitCaptureType: unitCaptureTypeItem })}
              />

              <Typography
                variant="text-md"
                fontWeight={500}
                color={GRAY_900}
                sx={{ cursor: 'pointer' }}
                onClick={() => handleUpdateHeatingSystemItem(id, { unitCaptureType: unitCaptureTypeItem })}
              >
                {t(`unit_capture_type.${unitCaptureTypeItem}`)}
              </Typography>
            </Stack>
          ))}

        </Stack>

      </Stack>

      {/** CONSUMPTION DATA */}
      <Stack gap={0.6} marginTop={2}>

        <Typography variant="text-md" fontWeight={600} color={GRAY_900}>
          {t('consumption_data')}
        </Typography>

        <Typography variant="text-sm" color={GRAY_700}>
          {t('consumption_data_description')}
        </Typography>

        <MUIDivider margin={8} />

        {/* Billing period items */}
        <Box flexGrow={1}>
          <Grid container spacing={2}>

            <TransitionGroup component={null}>
              {sortedBillingPeriodItems && sortedBillingPeriodItems.map(([billingId, value], index) =>
                <Grow key={billingId} in={true} timeout={{ enter: 300, exit: 500 }}>
                  <Grid item xs={12} md={6}>
                    <BillingPeriod
                      id={billingId}
                      index={index}
                      heatingSystemId={id}
                      startDate={value.startDate}
                      endDate={value.endDate}
                      consumption={value.consumption}
                      showErrors={showError}
                      handleOnInputBlur={handleOnInputBlur}
                    />
                  </Grid>
                </Grow>
              )}
            </TransitionGroup>

            {/** Add new billing period - up to 4 items can be added */}
            <Grid item xs={12} md={6}>
              <BorderBoxWrapper
                borderColor={GRAY_400}
                sx={{
                  padding: 2,
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  minHeight: '18.8rem',
                  borderStyle: 'dashed',
                  justifyContent: 'center',
                }}
              >
                <MUIButton
                  size='md'
                  type='secondaryNoBorder'
                  disabled={sortedBillingPeriodItems && sortedBillingPeriodItems?.length >= 4}
                  startIcon={<AddIcon fontSize='large' sx={{ GRAY_700 }} />}
                  onClick={() => {
                    handleAddBillingPeriod(id)
                  }}
                >
                  {t('add_billing_period')}
                </MUIButton>
              </BorderBoxWrapper>
            </Grid>

          </Grid>
        </Box>

      </Stack>

    </SectionedBorderBox>
  )
}

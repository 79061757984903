import constate from 'constate'
import { Nullable } from 'models/helpers'
import { useState } from 'react'

export enum SystemType {
  SOLAR_THERMAL_ENERGY = 'SOLAR_THERMAL_ENERGY',
  HEAT_PUMP = 'HEAT_PUMP',
  SOLAR_THERMAL_AND_HEAT_PUMP = 'SOLAR_THERMAL_AND_HEAT_PUMP'
}

export enum UseType {
  HEATING = 'HEATING',
  HOT_WATER = 'HOT_WATER',
  HEATING_AND_HOT_WATER = 'HEATING_AND_HOT_WATER',
}

export enum EnergeticConditionType {
  HYDRAULIC_BALANCING = 'HYDRAULIC_BALANCING',
  LOW_TEMPERATURE_BOILER = 'LOW_TEMPERATURE_BOILER',
  CONDENSING_BOILER = 'CONDENSING_BOILER',
  WINDOWS_REPLACED_IN_LAST_25_YEARS = 'WINDOWS_REPLACED_IN_LAST_25_YEARS',
  SOLAR_THERMAL_DRINKING_WATER = 'SOLAR_THERMAL_DRINKING_WATER',
  SOLAR_THERMAL_HEATING = 'SOLAR_THERMAL_HEATING',
  INSULATION_EXTERNAL_FACADE = 'INSULATION_EXTERNAL_FACADE',
  INSULATION_CELLAR_CEILING_FLOOR = 'INSULATION_CELLAR_CEILING_FLOOR',
  INSULATION_ROOF = 'INSULATION_ROOF',
}

export const [ConsumptionInformationContextProvider, useConsumptionInformation] = constate(() => {
  const [systemType, setSystemType] = useState<SystemType>()
  const [useType, setUseType] = useState<Nullable<UseType>>(null)
  const [energeticConditionType, setEnergeticConditionType] = useState<Set<EnergeticConditionType>>(new Set([]))
  const [startDate, setStartDate] = useState<Nullable<moment.Moment>>(null)
  const [endDate, setEndDate] = useState<Nullable<moment.Moment>>(null)
  const [hasPropertyVacant, setHasPropertyVacant] = useState<boolean>(true)
  const [area, setArea] = useState<Nullable<number>>(null)

  return {
    systemType,
    setSystemType,
    useType,
    setUseType,
    energeticConditionType,
    setEnergeticConditionType,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    hasPropertyVacant,
    setHasPropertyVacant,
    area,
    setArea
  }
})

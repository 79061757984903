import { AreaCalculationTypes, FloorPlanCertificationPropertyTypes } from './FloorPlanCertification.constants'
import { Box, Fade } from '@mui/material'
import { DropdownInputTypes, MUIDropdownInput } from 'components/common/MUIDropdownInput/MUIDropdownInput.component'
import { EditingCategory, ProductKind } from 'constants/product'
import { FC, useState } from 'react'
import { GRAY_700, GRAY_900 } from 'constants/styling/theme'
import { Trans, useTranslation } from 'react-i18next'
import { usePurchaseFlowConfig, usePurchaseFlowProducts } from '../../../_main/contexts'

import { Currency } from 'constants/misc'
import { MUICheckCard } from 'components/common/MUICards'
import { MUIDropdown } from 'components/common/MUIDropdown'
import { MUIDropdownItem } from 'components/common/MUIDropdownItem'
import { MUIInputField } from 'components/common/MUIInputField'
import { MUIInputFieldCounter } from 'components/common/MUIInputFieldCounter'
import { MUIRadio } from 'components/common/MUIRadio'
import { ProductCardHeader } from 'components/pages/PurchaseFlow/common/ProductCardHeader'
import { SectionedBorderBox } from 'components/common/SectionedBorderBox'
import Stack from '@mui/material/Stack'
import { StepWrapper } from 'components/pages/PurchaseFlow/common/StepWrapper'
import { SystemMessage } from 'components/common/SystemMessage'
import Typography from '@mui/material/Typography'
import { useFloorPlanCertification } from './FloorPlanCertificationStep.context'

/**
 * Controller for handling floor plan certifications details and such things
 *
 * @example <FloorPlanCertificationStepController />
 */
export const FloorPlanCertificationStepController: FC = () => {

  const { t } = useTranslation(['purchase_flow'], { keyPrefix: 'floor_plan_certification_step' })

  const { catalogueCurrency, selectedCategory } = usePurchaseFlowConfig()
  const {
    architectStampProduct,
    addFloor,
    removeFloor,
    floorPlanCertificationInformation,
    setFloorPlanCertificationInformation,
    handleNumberOfRoomsChange
  } = useFloorPlanCertification()
  const { selectProduct, selectedProducts, unselectProduct } = usePurchaseFlowProducts()

  const { extraCatalogueOptionProduct } = usePurchaseFlowConfig()

  const [showError, setShowError] = useState<boolean>(false)

  const { numberOfFloors, propertyType, comment, areaCalculation, hasArchitectStamp, floors } = floorPlanCertificationInformation

  // If any of the floor info fields are empty or 0, show error.
  const handleOnInputBlur = () => {
    if (!showError) setShowError(true)
  }

  return (
    <StepWrapper
      title={t('step_title')}
      subtitle={t('step_subtitle')}
    >
      <Fade in={true}>
        <Stack gap={2}>

          <SystemMessage variant="system" message={t('liability_info')} />

          <Stack gap={2}>
            {/* PROPERTY INFO */}
            <Stack gap={2}>
              <SectionedBorderBox
                title={
                  <Typography color={GRAY_900} variant='text-lg' fontWeight={600}>
                    {t('property_information.property_information_section_title')}
                  </Typography>
                }
              >

                <Stack spacing={2} marginBottom={2}>

                  {/* PROPERTY TYPE */}
                  <Box sx={{ maxWidth: '70%' }}>
                    <MUIDropdown
                      button={(isOpen, action) => (
                        <MUIDropdownInput
                          readOnly
                          label={t('property_type')}
                          type={DropdownInputTypes.SELECTOR}
                          value={propertyType ? t(`certification_property_types.${propertyType}`) : propertyType}
                          onClick={action}
                        />
                      )}
                    >
                      <Box height="30rem">
                        {Object.values(FloorPlanCertificationPropertyTypes)?.map(propertyTypeItem => (
                          <MUIDropdownItem
                            key={propertyTypeItem}
                            onClick={() => setFloorPlanCertificationInformation(prevState => ({
                              ...prevState, propertyType: propertyTypeItem
                            }))}
                          >
                            <Typography variant="text-md" color={GRAY_900} fontWeight="medium">
                              {t(`certification_property_types.${propertyTypeItem}`)}
                            </Typography>
                          </MUIDropdownItem>
                        ))}
                      </Box>
                    </MUIDropdown>
                  </Box>

                  {/* NUMBER OF FLOORS */}
                  <MUIInputFieldCounter
                    required
                    isError={numberOfFloors === 0}
                    isCenterInput
                    counterValue={numberOfFloors ?? 0}
                    counterOnChange={(newCount: number) => {
                      newCount > numberOfFloors ? addFloor() : removeFloor()

                      setFloorPlanCertificationInformation(prevState => ({
                        ...prevState,
                        numberOfFloors: newCount
                      }))
                    }}
                    inputBoxWidth="14rem"
                    label={t('property_information.number_of_floors')}
                    hintText={
                      <Trans
                        t={t}
                        i18nKey={numberOfFloors >= 1 ? 'property_information.number_of_floors_hint' : ''}
                        values={{ price: extraCatalogueOptionProduct(ProductKind.EXTRA_CERTIFIED_FLOOR).price }}
                      />
                    }
                    onBlur={handleOnInputBlur}
                  />

                  {numberOfFloors > 0 && (
                    <Stack direction="row" flexWrap="wrap" gap={2} width={'100%'}>
                      {Array.from({ length: numberOfFloors }).map((_, index) => {
                        // we want to start floor number from 1, 2, etc.
                        const floorNumber = index + 1

                        return (
                          <SectionedBorderBox
                            key={floorNumber}
                            title={
                              <Typography color={GRAY_900} variant='text-lg' fontWeight={600}>
                                <Trans
                                  t={t}
                                  i18nKey={'property_information.floor'}
                                  values={{ floorNumber }}
                                />
                              </Typography>
                            }
                            sx={{
                              width: numberOfFloors === 1 ? '50%' : 'calc(50% - 1rem)',
                            }}
                          >
                            <Stack gap={1.6}>

                              <Stack>

                                {/* FLOOR NAME */}
                                <MUIInputField
                                  required
                                  isError={showError && !floors.get(floorNumber)?.name}
                                  errorText={t('property_information.floor_name_error')}
                                  placeholder={t('property_information.floor_name_example')}
                                  label={t('property_information.floor_name')}
                                  value={floors.get(floorNumber)?.name ?? ''}
                                  onChange={e => setFloorPlanCertificationInformation(prevState => ({
                                    ...prevState, floors: prevState.floors.update(floorNumber, prev => ({ ...prev!, name: e.target.value }))
                                  }))}
                                  onBlur={handleOnInputBlur}
                                />

                              </Stack>

                              {/* NUMBER OF ROOMS */}
                              <MUIInputFieldCounter
                                required
                                isError={floors.get(floorNumber)?.roomsQuantity === 0}
                                isCenterInput
                                counterValue={floors.get(floorNumber)?.roomsQuantity ?? 0}
                                counterOnChange={(roomsQuantity) => { handleNumberOfRoomsChange(floorNumber, roomsQuantity) }}
                                inputBoxWidth="14rem"
                                label={t('property_information.number_of_rooms')}
                                hintText={
                                  <Trans
                                    t={t}
                                    i18nKey={(numberOfFloors !== undefined && numberOfFloors >= 1) ? 'property_information.number_of_rooms_hint' : ''}
                                    values={{ price: extraCatalogueOptionProduct(ProductKind.EXTRA_CERTIFIED_ROOM).price }}
                                  />
                                }
                              />

                            </Stack>

                          </SectionedBorderBox>
                        )
                      })}

                    </Stack>
                  )}

                  {/* AREA CALCULATION */}
                  <Stack gap=".8rem">
                    <Typography variant="text-sm" fontWeight="medium" color={GRAY_700}>
                      {t('area_calculations.field_title')}
                    </Typography>

                    {Object.values(AreaCalculationTypes).map(calc => {
                      return (
                        <Stack key={calc} gap=".8rem" flexDirection="row">
                          <MUIRadio
                            checked={areaCalculation === calc}
                            onChange={() => setFloorPlanCertificationInformation(prevState => ({
                              ...prevState, areaCalculation: calc
                            }))}
                          />

                          <Typography variant="text-sm" fontWeight="medium" color={GRAY_900}>
                            {t(`area_calculations.${calc}`)}
                          </Typography>
                        </Stack>
                      )
                    })}

                  </Stack>
                </Stack>

              </SectionedBorderBox>
            </Stack>

            {/** ARCHITECT STAMP */}
            {!!architectStampProduct &&
              <MUICheckCard
                isHeaderClickable={true}
                showSelectElement={true}
                checked={!!selectedProducts[architectStampProduct.id]}
                onCheck={() => {
                  const selectedQuantity = selectedProducts[architectStampProduct.id]?.quantity
                  const willBeUnchecked = selectedQuantity && selectedQuantity > 0

                  if (willBeUnchecked) unselectProduct(architectStampProduct.id)
                  else selectProduct(architectStampProduct, 1)

                  setFloorPlanCertificationInformation(prevState => ({
                    ...prevState, hasArchitectStamp: !hasArchitectStamp
                  }))
                }}
              >
                {!!catalogueCurrency && !!selectedCategory &&
                  <ProductCardHeader
                    product={architectStampProduct}
                    typeKey={architectStampProduct.type}
                    disabled={false}
                    category={selectedCategory || EditingCategory.EDITING}
                    currency={catalogueCurrency || Currency.CHF}
                    quantity={undefined}
                    displayPrice={true}
                  />
                }
              </MUICheckCard>}

            {/* COMMENT */}
            <SectionedBorderBox title={t('property_information.comment')}>
              <MUIInputField
                hintText={t('property_information.comment_hint')}
                isMultiline
                value={comment}
                onChange={(e) => setFloorPlanCertificationInformation(prevState => ({
                  ...prevState, comment: e.target.value
                }))}
              />

            </SectionedBorderBox>
          </Stack>
        </Stack>
      </Fade>
    </StepWrapper>
  )
}

import constate from 'constate'
import { useSendAssignmentMessageThread } from 'dataQueries/messageThreads'
import { MessageThreadState } from 'models/messageThreads'
import { useState } from 'react'

export const [ThreadContextProvider, useThreads] = constate(() => {

  const [headerButtonText, setHeaderButtonText] = useState<string | null>(null)
  const [requestThreadTitle, setRequestThreadTitle] = useState<string>('')
  const [requestThreadStatus, setRequestThreadStatus] = useState<MessageThreadState | null>(null)
  const [requestThreadStartedDate, setRequestThreadStartedDate] = useState<string>('')
  const [requestThreadId, setRequestThreadId] = useState<string>('')

  const sendAssignmentMessageThread = useSendAssignmentMessageThread()

  /** Handles the submission of a message with attached files to a specific thread within an assignment. */
  const onSubmitMessage = (assignmentId: string, threadId: string, content: string, attachmentFilenames: string[]) => {
    sendAssignmentMessageThread.mutate({ assignmentId, threadId, content, attachmentFilenames })
  }

  /**
   * Handles the click event for the header button.
   * 
   * @todo Implement the actual action handling logic or even check possibility of change it as a prop.
   */
  const onHeaderButtonClick = () => {
    console.log('Header button clicked')
  }

  return {
    headerButtonText,
    requestThreadTitle,
    requestThreadStatus,
    requestThreadStartedDate,
    requestThreadId,
    sendAssignmentMessageThread,
    setRequestThreadTitle,
    setRequestThreadId,
    setRequestThreadStartedDate,
    setRequestThreadStatus,
    onSubmitMessage,
    onHeaderButtonClick,
    setHeaderButtonText,
  }
})

import { AddressWithCoordinates, GoogleAPIPlace, Place } from 'models/purchaseFlow'

import { Nullable } from 'models/helpers'
import { PropertyAddressDTO } from 'models/property'
import { getCityFromGooglePlace } from './cityFromGooglePlace'

export enum GooglePlaceComponent {
  street = 'route',
  streetNumber = 'street_number',
  city = 'city',
  postalCode = 'postal_code',
  country = 'country',
}

/** creates the redux store place object from Google place object */
export function createPlaceFromGooglePlace(googlePlace: GoogleAPIPlace | null): Place | undefined {
  if (!googlePlace) return undefined

  return {
    address: googlePlace.formatted_address,
    coordinate: {
      lat: googlePlace.geometry.location.lat,
      lng: googlePlace.geometry.location.lng,
    },
    countryCode: getFromGooglePlace(googlePlace, GooglePlaceComponent.country)
  } as Place
}

/** creates the country code string from Google place object */
export function getFromGooglePlace(googlePlace: GoogleAPIPlace | null, interest: GooglePlaceComponent): string {
  if (!googlePlace) return ''

  if (interest === GooglePlaceComponent.city) {
    return getCityFromGooglePlace(googlePlace) ?? ''
  }

  for (let component of googlePlace.address_components) {
    for (let type of component.types) {
      if (type === interest) return component.short_name
    }
  }

  return ''
}

/** Transforms Place object into AddressWithCoordinates */
export function getAddressWithCoordinatesFromPlace(place: Nullable<Place>): AddressWithCoordinates | undefined {
  if (!place) return undefined

  return {
    address: place.address,
    coordinates: place.coordinate,
  }
}

export function getPropertyAddressFromGooglePlace(googlePlace: GoogleAPIPlace | null): PropertyAddressDTO | null {
  if (!googlePlace) return null

  return {
    street: getFromGooglePlace(googlePlace, GooglePlaceComponent.street),
    streetNumber: getFromGooglePlace(googlePlace, GooglePlaceComponent.streetNumber),
    city: getFromGooglePlace(googlePlace, GooglePlaceComponent.city),
    postalCode: getFromGooglePlace(googlePlace, GooglePlaceComponent.postalCode),
    country: getFromGooglePlace(googlePlace, GooglePlaceComponent.country),
    coordinates: {
      lat: googlePlace.geometry.location.lat,
      lng: googlePlace.geometry.location.lng,
    }
  }
}

import { Box, Fade, Typography } from '@mui/material'
import { BuildingLocationType, BuildingType, usePropertyInformation } from './PropertyInformation.context'
import { DropdownInputTypes, MUIDropdownInput } from 'components/common/MUIDropdownInput/MUIDropdownInput.component'
import { FC, useState } from 'react'

import { CheckboxItem } from 'components/pages/PurchaseFlow/common/CheckboxItem'
import { GRAY_900 } from 'constants/styling/theme'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { MUIDropdown } from 'components/common/MUIDropdown'
import { MUIDropdownItem } from 'components/common/MUIDropdownItem'
import { MUIInputField } from 'components/common/MUIInputField'
import { MUIInputFieldCounter } from 'components/common/MUIInputFieldCounter'
import { MUITooltip } from 'components/common/MUITooltip'
import { SectionedBorderBox } from 'components/common/SectionedBorderBox'
import Stack from '@mui/material/Stack'
import { SystemMessage } from 'components/common/SystemMessage'
import { useTranslation } from 'react-i18next'

const InputMobileWidth = { xs: '100%', sm: '40rem', lg: '50rem' }

/**
 * Content for the PropertyInformation in the EnergyCertificate component.
 * 
 * @example
 * <PropertyInformation />
 */
export const PropertyInformation: FC = () => {
  const { t } = useTranslation(['purchase_flow'], { keyPrefix: 'energy_certificate_step.property_information_page' })

  const {
    buildingType,
    setBuildingType,
    buildingLocationType,
    setBuildingLocationType,
    residentialUnit,
    setResidentialUnit,
    constructionYear,
    setConstructionYear,
    livingSpace,
    setLivingSpace,
    commercialArea,
    setCommercialArea,
    commercialAreaType,
    setCommercialAreaType,
    isConstructionConfirmed,
    setIsConstructionConfirmed,
    isShowConstructionNote,
  } = usePropertyInformation()

  // Handle input change with regex to replace all non-digit characters in the string with an empty string
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, setter: (value: string) => void) => {
    const value = e.target.value.replace(/\D/g, '')
    setter(value)
  }

  const [showError, setShowError] = useState<boolean>(false)

  // If any of the required fields are empty, show error for all of them.
  const handleOnInputBlur = () => {
    if (!showError) setShowError(true)
  }

  return (
    <SectionedBorderBox title={t('title')}>
      <Stack gap="1.8rem" width={InputMobileWidth} position="relative">

        {/* BUILDING TYPE SELECTION */}
        <MUIDropdown
          sx={{ width: '100%' }}
          button={(isOpen, action) => (
            <MUIDropdownInput
              readOnly
              required
              label={t('property_type')}
              type={DropdownInputTypes.SELECTOR}
              value={t(!!buildingType ? `building_type.${buildingType}` : 'select_building_type_option')}
              onClick={action}
            />
          )}
        >
          {Object.values(BuildingType).map(type => (
            <MUIDropdownItem key={type} onClick={() => setBuildingType(type)}>
              <Typography variant="text-md" color={GRAY_900} fontWeight="medium">
                {t(`building_type.${type}`)}
              </Typography>
            </MUIDropdownItem>
          ))}
        </MUIDropdown>

        {/* BUILDING LOCATION TYPE SELECTION */}
        <MUIDropdown
          sx={{ width: '100%' }}
          button={(isOpen, action) => (
            <MUIDropdownInput
              readOnly
              required
              label={t('building_location')}
              type={DropdownInputTypes.SELECTOR}
              value={t(!!buildingLocationType ? `building_location_type.${buildingLocationType}` : 'select_building_location_option')}
              onClick={action}
            />
          )}
        >
          {Object.values(BuildingLocationType).map(type => (
            <MUIDropdownItem key={type} onClick={() => setBuildingLocationType(type)}>
              <Typography variant="text-md" color={GRAY_900} fontWeight="medium">
                {t(`building_location_type.${type}`)}
              </Typography>
            </MUIDropdownItem>
          ))}
        </MUIDropdown>

        {/* NUMBER OF RESIDENTIAL UNIT */}
        <MUIInputFieldCounter
          isCenterInput
          minValue={1}
          counterValue={residentialUnit}
          counterOnChange={setResidentialUnit}
          inputBoxWidth="14rem"
          isError={showError && !residentialUnit}
          label={t('residential_units')}
          onBlur={handleOnInputBlur}
        />

        {/* INPUT INFO SELECTION - CONSTRUCTION YEAR, LIVING SPACE, AND COMMERCIAL AREA IF MIXED BUILDING IS SELECTED. */}
        {buildingType === BuildingType.MIXED_BUILDING &&
          <Box width={{ xs: '100%', sm: '25rem' }}>
            <MUIInputField
              required
              label={t('commercial_area')}
              value={commercialArea}
              isError={showError && !commercialArea}
              onChange={(e) => handleInputChange(e, setCommercialArea)}
              onBlur={handleOnInputBlur}
            />

            <MUIInputField
              required
              label={t('commercial_area_type')}
              value={commercialAreaType}
              isError={showError && !commercialAreaType}
              onChange={(e) => setCommercialAreaType(e.target.value)}
              onBlur={handleOnInputBlur}
            />
          </Box>
        }

        <Box width={{ xs: '100%', sm: '25rem' }}>
          <MUIInputField
            required
            label={t('construction_year')}
            value={constructionYear}
            isError={showError && constructionYear.length < 4}
            onChange={(e) => handleInputChange(e, setConstructionYear)}
            onBlur={handleOnInputBlur}
          />
        </Box>

        {isShowConstructionNote &&
          <Fade in={isShowConstructionNote}>
            <Stack gap="1.6rem">

              <SystemMessage
                variant="warning"
                title={t('construction_note_title')}
                message={t('construction_note_content')}
              />
              <CheckboxItem
                title={t('construction_note_confirm')}
                checked={isConstructionConfirmed}
                onClick={() => setIsConstructionConfirmed(!isConstructionConfirmed)}
              />

            </Stack>
          </Fade>
        }

        {/* NUMBER OF LIVING SPACE */}
        <Box width={{ xs: '100%', sm: '25rem' }}>
          <MUIInputField
            required
            label={t('living_space')}
            value={livingSpace}
            isError={showError && !livingSpace}
            tooltip={
              <MUITooltip content={t('living_area_tooltip')}>
                <InfoOutlinedIcon fontSize="medium" sx={{ paddingTop: '.4rem' }} />
              </MUITooltip>
            }
            onChange={(e) => handleInputChange(e, setLivingSpace)}
            onBlur={handleOnInputBlur}
          />
        </Box>

      </Stack>
    </SectionedBorderBox>
  )
}

import { BuildingOrientation, OrientationType } from 'components/pages/PurchaseFlow/common/BuildingOrientation'
import { GRAY_700, GRAY_900 } from 'constants/styling/theme'
import { PhotovoltaicSystemType, useVentilationAndHeating } from './_main/VentilationAndHeating.context'
import React, { useState } from 'react'

import { BorderBoxWrapper } from 'components/common/BorderBoxWrapper'
import { Box } from '@mui/material'
import { MUINumberField } from 'components/common/MUINumberField'
import { PRIMARY_FIELD_WIDTH } from './HeatingSystem/_main/HeatingSystem.component'
import { SectionedBorderBox } from 'components/common/SectionedBorderBox'
import Stack from '@mui/material/Stack'
import { ToggleButton } from 'components/common/ToggleButton'
import Typography from '@mui/material/Typography'
import { VentilationHeatingProps } from './_main/VentilationAndHeating.component'
import { useTranslation } from 'react-i18next'

/**
 * @component PhotovoltaicSystem renders options for the user to provide information about the photovoltaics. 
 *
 * @example
 *   <PhotovoltaicSystem id="heating-system-1" />
 */
export const PhotovoltaicSystem: React.FC<VentilationHeatingProps> = ({ id }) => {

  const { t } = useTranslation(['purchase_flow'], { keyPrefix: 'energy_certificate_step.energy_heating_page' })

  const {
    currentHeatingSystem,
    handleUpdateHeatingSystemItem,
  } = useVentilationAndHeating()

  const {
    photovoltaicSystem,
    areaOfFacility,
    peakPower,
    orientationType,
  } = currentHeatingSystem(id) || {}

  const [showError, setShowError] = useState<boolean>(false)

  // If any of the required fields are empty, show error for all of them.
  const handleOnInputBlur = () => {
    if (!showError) setShowError(true)
  }

  const handleOrientationType: React.Dispatch<React.SetStateAction<OrientationType>> = (value) => {
    handleUpdateHeatingSystemItem(id, { orientationType: value as OrientationType })
  }

  return (
    <SectionedBorderBox
      title={
        <Typography variant='text-md' fontWeight={600} color={GRAY_900}>
          {t('photovoltaic_system.photovoltaic_system_title')}
        </Typography>
      }
    >
      <Stack
        sx={{
          flexDirection: { xs: 'column', lg: 'row' }
        }}
        spacing={2}
        width="100%"
      >
        <Stack alignItems="flex-start" gap={1.6} marginBottom={{ xs: '2.8rem', sm: 0 }}>

          <Typography variant='text-sm' fontWeight="medium" color={GRAY_700}>
            {t('photovoltaic_system.photovoltaic_system_title')}
          </Typography>

          {/* PHOTOVOLTAIC SYSTEM SECTION */}
          <Stack gap={0.8} flexDirection="row" flexWrap="wrap">
            {Object.values(PhotovoltaicSystemType).map((type) => (
              <ToggleButton
                key={type}
                isSelected={photovoltaicSystem === type}
                label={t(`photovoltaic_system.photovoltaic_system_type.${type}`)}
                sx={{ width: '6.4rem', height: '4rem', justifyContent: 'center' }}
                onClick={() => handleUpdateHeatingSystemItem(id, { photovoltaicSystem: type })}
                typographyVariant='text-md'

              />
            ))}
          </Stack>

          {photovoltaicSystem === PhotovoltaicSystemType.YES &&
            <BorderBoxWrapper padding={2} width='100%'>

              <Stack gap=".8rem">
                <Stack width="100%" gap={1.6}>
                  {/* AREA OF THE FACILITY  */}
                  <Box width={{ xs: '80%', lg: PRIMARY_FIELD_WIDTH }}>
                    <MUINumberField
                      min={0}
                      required
                      suffix='m²'
                      inputBoxWidth='100%'
                      label={t('photovoltaic_system.area_of_the_facility')}
                      value={areaOfFacility}
                      isError={showError && !areaOfFacility}
                      onBlur={handleOnInputBlur}
                      onChange={(value) => handleUpdateHeatingSystemItem(id, { areaOfFacility: value })}
                    />
                  </Box>

                  {/* PEAK POWER */}
                  <Box width={{ xs: '80%', lg: PRIMARY_FIELD_WIDTH }}>
                    <MUINumberField
                      min={0}
                      required
                      suffix='kWp'
                      inputBoxWidth='100%'
                      label={t('photovoltaic_system.peak_power_of_the_system')}
                      value={peakPower}
                      isError={showError && !peakPower}
                      onBlur={handleOnInputBlur}
                      onChange={(value) => handleUpdateHeatingSystemItem(id, { peakPower: value })}
                    />
                  </Box>

                  {/* PHOTOVOLTAIC SYSTEM ORIENTATION */}
                  <Stack marginTop={.8} width='fit-content'>
                    <BuildingOrientation onClick={handleOrientationType} type={orientationType ?? OrientationType.NORTH} />
                  </Stack>

                </Stack>
              </Stack>
            </BorderBoxWrapper>
          }

        </Stack>
      </Stack>
    </SectionedBorderBox>
  )
}

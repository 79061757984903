import { BEIGE_500, BEIGE_600, BLACK_TEXT, GRAY_400, GRAY_800, WHITE } from 'constants/styling/theme'
import React, { useMemo } from 'react'

import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined'
import { BorderBoxWrapper } from 'components/common/BorderBoxWrapper'
import { BoxProps } from '@mui/material/Box'
import { CircleIcon } from 'components/common/CircleIcon'
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { saveAs } from 'file-saver'

/**
 * Represents the type of a message in a thread.
 * 
 * @property {'sent'} sent - Indicates that the message was sent by the user.
 * @property {'received'} received - Indicates that the message was received by the user.
 */
export type MessageType = 'sent' | 'received'

/** Represents a file attached to a message. */
export type MessageAttachedFile = {
  /** The ID of the attached file. */
  id: string
  /** The name of the attached file. */
  fileName: string
  /** The URL to the attached file. */
  fileUrl: string
}

/** @interface ThreadMessageProps for the ThreadMessage component. */
export interface ThreadMessageProps extends BoxProps {
  /** The type of the displayed message either 'received' or 'sent'. */
  type: MessageType
  /** The text content of the message. */
  message: string
  /** An optional file attached to the message. */
  attachedFiles?: MessageAttachedFile[]
}

/**
 * @component
 * ThreadMessage renders a thread message with optional attached file and styled different according to the viewer.
 *
 * @example
 * <ThreadMessage type='sent' message='Hello, how are you?' attachedFile={fileName: "yes.pdf", fileUrl: 'https://via.placeholder.com/150'} />
 */
export const ThreadMessage: React.FC<ThreadMessageProps> = ({ message, type, attachedFiles, ...boxProps }) => {

  /** Custom border radius to display sharp border according to the type of displayed message. */
  const borderRadius = useMemo(() => {
    if (type === 'sent') return '0.8rem 0.8rem 0 0.8rem'
    return '0.8rem 0.8rem 0.8rem 0'
  }, [type])

  return (
    <BorderBoxWrapper
      {...boxProps}
      padding={1}
      borderColor={BEIGE_600}
      elevation={type === 'received' ? 'none' : 'xs'}
      backgroundColor={type === 'received' ? BEIGE_500 : WHITE}
      sx={{ borderRadius }}
    >

      <Typography variant="text-sm" color={BLACK_TEXT}>
        {message}
      </Typography>

      {attachedFiles && attachedFiles?.length > 0 &&
        <Stack>
          {attachedFiles.map((attachedFile) =>
            <BorderBoxWrapper
              gap={0.5}
              padding={0.5}
              marginTop={1}
              display="flex"
              flexDirection="row"
              alignItems="center"
              borderColor={GRAY_400}
              key={attachedFile.id}
            >

              <CircleIcon
                size="2.4rem"
                circleColor={BEIGE_600}
                icon={<ArticleOutlinedIcon fontSize="medium" sx={{ color: GRAY_800 }} />}
              />

              <Typography variant="text-sm" color={BLACK_TEXT} flex={1}>
                {attachedFile.fileName}
              </Typography>

              <IconButton onClick={() => saveAs(attachedFile.fileUrl, attachedFile.fileName)}>
                <CloudDownloadOutlinedIcon fontSize="medium" sx={{ color: GRAY_800 }} />
              </IconButton>

            </BorderBoxWrapper>
          )}
        </Stack>
      }

    </BorderBoxWrapper>
  )
}

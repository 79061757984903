import { EntityKeys, QueryType, getMutation } from 'utils/reactQuery'

import { useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { DocumentUploadType } from 'constants/documents'
import { AssignmentDocumentDTO } from 'models/assignment'
import { useAPI } from 'utils/API'

enum Endpoints {
  GET_ASSIGNMENT_DOCUMENTS = '/assignment/{assignmentId}/documents?type={type}',
  GET_ASSIGNMENT_DOCUMENT_FILE = '/assignment/{assignmentId}/documents/{filename}',
}

// QUERIES
export function useGetAssignmentDocuments(assignmentId: string, type: DocumentUploadType) {
  const api = useAPI<Endpoints>()

  return useQuery({
    queryKey: [EntityKeys.ASSIGNMENT, QueryType.GET, { assignmentId }],
    queryFn: () => api.get<AssignmentDocumentDTO[]>(
      Endpoints.GET_ASSIGNMENT_DOCUMENTS,
      {
        assignmentId,
        type,
      },
      true
    ),
    enabled: !!assignmentId,
  })
}

// MUTATIONS

export function useGetAssignmentDocumentFile() {
  const api = useAPI<Endpoints>()

  return getMutation<AxiosResponse<AssignmentDocumentDTO>, { assignmentId: string, filename: string }>(
    ({ assignmentId, filename }) => api.get(
      Endpoints.GET_ASSIGNMENT_DOCUMENT_FILE,
      { assignmentId, filename },
      true,
      {
        params: { type: DocumentUploadType.INPUT }
      }
    )
  )
}

import { AnalyticsEvent, logAnalyticsEvent } from 'utils/analytics'
import { AssignmentDTOIsAdministratorDTO, AssignmentDTOIsCreativeDTO } from 'utils/typeguards'
import { EditingCategory, ProductKind, ShootingCategory } from 'constants/product'
import { ReactNode, useCallback, useMemo } from 'react'
import { StageOrder, compareAssignmentStageOrder } from 'utils/stages'

import { AssignmentStage } from 'constants/assignment'
import { StagingType } from 'models/virtualStaging'
import { chooseProductContainingVisuals } from 'utils/product'
import constate from 'constate'
import { isShootingCategory } from 'utils/validators'
import { useAuth0 } from 'utils/auth'
import { useGalleryConstants } from './GalleryConstants.context'
import { useGetAssignment } from 'dataQueries'
import { useUserData } from 'components/contexts/UserDataContext'

/** Context provider for gallery assignment */
export const [GalleryAssignmentContextProvider, useGalleryAssignment] = constate(({
  assignmentId,
  children
}: {
  assignmentId: string
  children?: ReactNode
}) => {
  const assignment = useGetAssignment(assignmentId)

  const { roles } = useAuth0()
  const { stagesUnlockingDeliveredAssignment, stagesAfterCTSubmit } = useGalleryConstants()
  const {
    baseUserData,
    organizationData
  } = useUserData()

  const assignmentData = useMemo(() => assignment.data?.data, [assignment])
  const assignmentStart = useMemo(() => assignmentData?.shootingStartDateTime, [assignmentData])
  const assignmentRatings = useMemo(() => !!assignmentData && !AssignmentDTOIsCreativeDTO(assignmentData) && assignmentData.ratings || {}, [assignmentData])
  const creative = useMemo(() => (assignmentData && !AssignmentDTOIsCreativeDTO(assignmentData)) ? assignmentData.creative : undefined, [assignmentData])
  const product = useMemo(() => chooseProductContainingVisuals(assignmentData?.products), [assignmentData])
  const assignmentStage = useMemo(() => assignmentData?.stage, [assignmentData])
  const isProductKindStaging = useMemo(() => !!product && (product.kind === ProductKind.RESIDENTIAL_STAGING || product.kind === ProductKind.COMMERCIAL_STAGING), [product])
  const isProductKindMatterport = useMemo(() => !!product && (product.kind === ProductKind.MATTERPORT_FLOOR_PLAN_WITHOUT_VISIT || product.kind === ProductKind.MATTERPORT_FLOOR_PLAN_WITH_VISIT), [product])

  // Resolve product FeedbackType:
  // 1) product kind is staging and category is real state or staging 
  // or 2) product kind is Matterport floor plan with visit or Matterport floor plan without visit
  // or 3) product kind is Mobile application floor plan
  // or 4) product kind is 2D floor plan editing
  // or 5) product kind is 3D floor plan editing
  const isFeedbackButtonAllowed = useMemo(() => {
    const allowedProductKinds = [
      ProductKind.MOBILE_APPLICATION_FLOOR_PLAN,
      ProductKind.FLOOR_PLAN_EDITING_2D,
      ProductKind.FLOOR_PLAN_EDITING_3D,
    ]

    if ((roles.isClient || roles.isAdmin) && assignmentStage === AssignmentStage.VISUALS_SENT_TO_CLIENT) {
      if (isProductKindStaging && (product?.category === ShootingCategory.REAL_ESTATE
        || product?.category === EditingCategory.STAGING)) {
        return true
      } else if (isProductKindMatterport) {
        return true
      } else if (product && allowedProductKinds.includes(product.kind)) {
        return true
      }
    }

    return false
  }, [isProductKindStaging, product, isProductKindMatterport, assignmentStage, roles.isAdmin, roles.isClient])

  // Select visuals for staging
  const canSelectVisualsForStaging = useMemo(() => (roles.isClient || roles.isAdmin) && !!product && isShootingCategory(product.category) && isProductKindStaging, [roles, product, isProductKindStaging])
  const isAssignmentDelivered = useMemo(() => {
    if (!assignmentStage) return false
    const order = compareAssignmentStageOrder(assignmentStage, stagesUnlockingDeliveredAssignment)
    return (order === StageOrder.COMPARING_EQUAL_TO_COMPARED_TO || order === StageOrder.COMPARING_WITHIN_BOUNDS_OF_COMPARED_TO)
  }, [assignmentStage, stagesUnlockingDeliveredAssignment])
  const selectedFavouriteCreativeId = useMemo(() => !!assignmentData && AssignmentDTOIsAdministratorDTO(assignmentData) ? assignmentData.selectedFavouriteCreative?.creativeId : null, [assignmentData])
  const isRatingsFinished = useMemo(() => {
    const totalRatingBox = creative ? 3 : 2
    // Check both emoji & text ratings are finished
    let isAllRated = true
    for (const [, ratingWrap] of Object.entries(assignmentRatings)) {
      if (!ratingWrap?.rating || !ratingWrap?.ratingText) {
        isAllRated = false
        break
      }
    }
    return !!assignmentData && !AssignmentDTOIsCreativeDTO(assignmentData) && assignmentData.ratings && Object.keys(assignmentData.ratings).length === totalRatingBox && isAllRated
  }, [assignmentData, assignmentRatings, creative])

  // In some parts of product we require minimal rating all boxes with emojis to be finished
  const isMinimalRatingsFinished = useMemo(() => {
    const totalRatingBox = creative ? 3 : 2
    // Check all emoji ratings are finished
    let isAllRated = true
    for (const [, ratingWrap] of Object.entries(assignmentRatings)) {
      if (!ratingWrap?.rating) {
        isAllRated = false
        break
      }
    }
    return !!assignmentData && !AssignmentDTOIsCreativeDTO(assignmentData) && assignmentData.ratings && Object.keys(assignmentData.ratings).length === totalRatingBox && isAllRated
  }, [assignmentData, assignmentRatings, creative])

  const logGalleryEvent = useCallback((eventName: AnalyticsEvent, params?: {}) => {
    logAnalyticsEvent(eventName, {
      userId: baseUserData?.id,
      userEmail: baseUserData?.email,
      organizationId: organizationData?.id,
      assignmentId,
      ...params
    })
  }, [assignmentId, baseUserData, organizationData?.id])

  const isBKBNDecide = useMemo(() => {
    if (assignmentData && AssignmentDTOIsAdministratorDTO(assignmentData) && assignmentData.stagingDetails) {
      const stagingDetails = assignmentData.stagingDetails
      const hasBKBNtype = Object.values(stagingDetails).some(item => item?.type === StagingType.BKBN)

      return hasBKBNtype
    }
  }, [assignmentData])

  const isAssignmentSubmittedByCT = useMemo(() => assignmentStage && stagesAfterCTSubmit.has(assignmentStage), [assignmentStage, stagesAfterCTSubmit])

  return {
    assignment,
    assignmentData,
    assignmentStart,
    assignmentRatings,
    creative,
    logGalleryEvent,
    product,
    isRatingsFinished,
    isMinimalRatingsFinished,
    assignmentStage,
    canSelectVisualsForStaging,
    isAssignmentDelivered,
    selectedFavouriteCreativeId,
    isFeedbackButtonAllowed,
    isBKBNDecide,
    isAssignmentSubmittedByCT,
  }
})

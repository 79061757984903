import { BasementCeilingFloorType, BasementType, useConstructionMethod } from './_main/ConstructionMethod.context'
import { DropdownInputTypes, MUIDropdownInput } from 'components/common/MUIDropdownInput/MUIDropdownInput.component'
import { GRAY_700, GRAY_900 } from 'constants/styling/theme'

import { MUIDropdown } from 'components/common/MUIDropdown'
import { MUIDropdownItem } from 'components/common/MUIDropdownItem'
import { MUIRadio } from 'components/common/MUIRadio'
import React from 'react'
import { SectionedBorderBox } from 'components/common/SectionedBorderBox'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'

/**
 * @component Basement renders options for the user to select the type of basement and ceiling/floor type.
 *
 * @example
 *   <Basement />
 */
export const Basement: React.FC = () => {

  const { t } = useTranslation(['purchase_flow'], { keyPrefix: 'energy_certificate_step.construction_method_page' })

  const {
    basementType,
    setBasementType,
    basementCeilingFloorType,
    setBasementCeilingFloorType,
  } = useConstructionMethod()

  return (
    <SectionedBorderBox
      title={
        < Typography variant='text-md' fontWeight={600} color={GRAY_900} >
          {t('basement_title')}
        </Typography >
      }
    >
      <Stack
        sx={{
          flexDirection: { xs: 'column', lg: 'row' }
        }}
        spacing={2}
        width="100%"
      >
        <Stack alignItems="flex-start" gap={1.2} marginBottom={{ xs: '2.8rem', sm: 0 }}>

          {/* BASEMENT/CELLAR */}
          <Stack gap="1.8rem" width={{ xs: '100%', lg: '32rem' }} position="relative">
            <MUIDropdown
              sx={{ width: '100%' }}
              button={(isOpen, action) => (
                <MUIDropdownInput
                  readOnly
                  required
                  label={t('basement_cellar')}
                  type={DropdownInputTypes.SELECTOR}
                  value={t(!!basementType ? `basement_type.${basementType}` : 'select_basement_cellar_option')}
                  onClick={action}
                />
              )}
            >
              {Object.values(BasementType).map(type => (
                <MUIDropdownItem key={type} onClick={() => setBasementType(type)}>
                  <Typography variant="text-md" color={GRAY_900} fontWeight="medium">
                    {t(`basement_type.${type}`)}
                  </Typography>
                </MUIDropdownItem>
              ))}
            </MUIDropdown>
          </Stack>

          {/* BASEMENT CEILING FLOOR */}
          <Stack gap=".8rem">
            <Typography variant="text-sm" fontWeight="medium" color={GRAY_700}>
              {t('basement_ceiling_floor')}
            </Typography>

            {Object.values(BasementCeilingFloorType).map(type => {
              return (
                <Stack key={type} gap=".8rem" flexDirection="row">
                  <MUIRadio
                    checked={basementCeilingFloorType === type}
                    onChange={() => setBasementCeilingFloorType(type)}
                  />

                  <Typography variant="text-sm" fontWeight="medium" color={GRAY_900}>
                    {t(`basement_ceiling_floor_type.${type}`)}
                  </Typography>
                </Stack>
              )
            })}

          </Stack>

        </Stack>
      </Stack>

    </SectionedBorderBox>
  )
}

import './TopBar.sass'

import { AnalyticsEvent, logAnalyticsEvent } from 'utils/analytics'
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import Button from '../Button/Button'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { ContactUsPopup } from './HelpCenter/ContactUsPopup.component'
import { HelpCenter } from './HelpCenter/HelpCenter.component'
import { LanguageDropdown } from './LanguageDropdown'
import { LanguagePopup } from 'components/common/TopBar/LanguagePopup/LanguagePopup.component'
import Logo from '../Logo/Logo'
import { MUIBadge } from '../MUIBadge'
import { MUIDivider } from 'components/common/MUIDivider/MUIDivider.component'
import MenuRoundedIcon from '@mui/icons-material/MenuRounded'
import { Path } from 'constants/router'
import { Stack } from '@mui/material'
import { TopBarDropdown } from './TopBarDropdown'
import { TopBarDropdownMobile } from './TopBarDropdownMobile'
import { useAppMeta } from 'components/higher-order/App/appMeta.context'
import { useAuth0 } from 'utils/auth'
import { useTranslation } from 'react-i18next'
import { useUserData } from 'components/contexts/UserDataContext'

/**
 * @interface Props for TopBar component
 */
export interface Props {
  /** The additional classes to append */
  className?: string
}

/**
 * @component A component usually display at the top of the page containing a logo, login/sign-up buttons and profile info as well as menu links for logged in users. Contains also mobile version of a menu.
 * @example <TopBar className="class" />
 */
const TopBar: React.FC<Props> = ({
  className = '',
}) => {
  const { t } = useTranslation(['topbar', 'authentication', 'gallery', 'category', 'product', 'dashboard_client'])
  const { isAuthenticated, loading, logout, user, roles } = useAuth0()
  const {
    activeSubscribedUserWorkspaces,
    hasUserActiveSubscription,
    currentUserWorkspace,
    clientData
  } = useUserData()
  const { isUserSet } = useAppMeta()
  const location = useLocation()
  const navigate = useNavigate()
  const [isLanguageOpen, setIsLanguageOpen] = useState(false)
  const [isContactUsOpen, setIsContactUsOpen] = useState(false)
  const invitationWorkspaceName = useMemo(() => activeSubscribedUserWorkspaces.find(item => item.hasOwnProperty('invitationId'))?.name, [activeSubscribedUserWorkspaces])

  /** logout action handle */
  const handleLogout = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.preventDefault()
    logout()
    closeMenuHandler(e)
  }

  // Open / Close logic
  /** inner menu element reference */
  const menuRef = useRef<HTMLDivElement>(null)
  const [showMenu, setShowMenu] = useState(false)

  /** action called upon mobile menu closing */
  const closeMenuAction = useCallback((e: any) => {
    if (menuRef.current?.contains(e.target)) return

    setShowMenu(false)
    document.removeEventListener('click', closeMenuAction)
  }, [])

  /** action called upon mobile menu opening */
  const openMenuAction = useCallback((e: React.MouseEvent<Element, MouseEvent>) => {
    e.preventDefault()
    if (showMenu) return

    setShowMenu(true)
    window.setTimeout(() => {
      document.addEventListener('click', closeMenuAction)
    }, 0)
  }, [showMenu, closeMenuAction])

  /** timeline for close animations */
  const closeMenuHandler = useCallback((e: React.MouseEvent<Element, MouseEvent>) => {
    document.removeEventListener('click', closeMenuAction)
    setShowMenu(false)
  }, [closeMenuAction])

  // Unsubscribe event listener
  useEffect(() => {
    return () => {
      document.removeEventListener('click', closeMenuAction)
    }
  }, [closeMenuAction])

  /** handle menu when window resize */
  useEffect(() => {
    const resizeHandler = (e: any) => {
      const screenWidth = window.innerWidth
      if (screenWidth > 800) {
        closeMenuHandler(e)
      }
    }
    window.addEventListener('resize', resizeHandler)
    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [closeMenuAction, closeMenuHandler])

  if (!isAuthenticated && (location.pathname === '' || location.pathname === '/')) className += ' index-notlogged'

  /** Show workspace name if client has only one active non-personal workspace and no multi-space invitation */
  const isShowWorkspaceName = useMemo(
    () => roles.isClient
      && (hasUserActiveSubscription && !!currentUserWorkspace && activeSubscribedUserWorkspaces.length === 1)
      || !!invitationWorkspaceName,
    [
      invitationWorkspaceName,
      activeSubscribedUserWorkspaces.length,
      currentUserWorkspace,
      hasUserActiveSubscription,
      roles.isClient
    ]
  )

  const handleLogAnalyticsEvent = useCallback((eventName: string, options?: {}) => {
    logAnalyticsEvent(eventName, {
      userEmail: clientData?.email,
      organizationId: clientData?.organizationId,
      ...options
    })
  }, [clientData?.email, clientData?.organizationId])

  return (
    <Fragment>

      <header className={`topbar ${className} ${!(isAuthenticated && user) && 'login-topbar'}`.replace(/\s+/igm, ' ').trim()}>

        <Link
          to={Path.INDEX}
          className="logo-link"
          onClick={() => handleLogAnalyticsEvent(AnalyticsEvent.CLICK_BACKBONE_LOGO)}
        >
          <Logo />
        </Link>

        {isShowWorkspaceName &&
          <Link
            to={Path.WORKSPACES_PROFILE.replace(':id', currentUserWorkspace?.id ?? '')}
            onClick={(e) => {
              handleLogAnalyticsEvent(AnalyticsEvent.CLICK_BACKBONE_PLAN_NAME, { planId: currentUserWorkspace?.id })
              closeMenuHandler(e)
            }}
          >
            <div className='workspace'>
              <MUIDivider margin={12} orientation="vertical" />
              <div className='name'>{invitationWorkspaceName || currentUserWorkspace?.name}</div>
              {!!invitationWorkspaceName &&
                <Stack marginLeft={2}>
                  <MUIBadge color="coral" size="sm" label={t('pending')} />
                </Stack>
              }
            </div>
          </Link>
        }

        <div className="push-right onlymobile">
          {!(isAuthenticated && user) ?
            <LanguageDropdown />
            :
            <Button
              className="burger-menu-button"
              type="secondary"
              onClick={(e) => {
                handleLogAnalyticsEvent(AnalyticsEvent.CLICK_MAIN_MENU)
                showMenu ? closeMenuHandler(e) : openMenuAction(e)
              }}
            >
              {user && <img src={user.picture} alt={t('profile')} />}
              {showMenu ? <CloseRoundedIcon /> : <MenuRoundedIcon />}
            </Button>
          }
        </div>

        {!loading &&
          <Fragment>
            {isAuthenticated && user ?
              <div className="profile-block push-right">

                {!roles.isAdmin && isUserSet && <HelpCenter isContactUsOpen={isContactUsOpen} setIsContactUsOpen={setIsContactUsOpen} />}

                {(roles.isClient || roles.isAdmin) &&
                  <Button
                    type="primary"
                    className='order-visuals-button'
                    onClick={() => {
                      handleLogAnalyticsEvent(AnalyticsEvent.CLICK_ORDER_NEW_VISUALS)
                      navigate(Path.PURCHASE_FLOW)
                    }}
                  >
                    {t(roles.isClient ? 'client_order_button' : 'admin_order_button')}
                  </Button>
                }

                {<LanguagePopup isLanguageOpen={isLanguageOpen} closeCallback={() => setIsLanguageOpen(!isLanguageOpen)} />}

                {<ContactUsPopup isContactUsOpen={isContactUsOpen} setIsContactUsOpen={setIsContactUsOpen} />}

                {<TopBarDropdown
                  invitationWorkspaceName={invitationWorkspaceName}
                  setIsLanguageOpen={() => setIsLanguageOpen(!isLanguageOpen)}
                  handleLogout={handleLogout}
                  closeMenuHandler={closeMenuHandler}
                  handleLogAnalyticsEvent={handleLogAnalyticsEvent}
                />}

              </div>
              :
              <div className="login-buttons push-right">
                <LanguageDropdown />
              </div>
            }
          </Fragment>
        }
      </header>

      {<TopBarDropdownMobile
        showMenu={showMenu}
        isShowWorkspaceName={isShowWorkspaceName}
        invitationWorkspaceName={invitationWorkspaceName}
        setIsLanguageOpen={() => setIsLanguageOpen(!isLanguageOpen)}
        setIsContactUsOpen={() => setIsContactUsOpen(!isContactUsOpen)}
        handleLogout={handleLogout}
        closeMenuHandler={closeMenuHandler}
        handleLogAnalyticsEvent={handleLogAnalyticsEvent}
      />}

    </Fragment>
  )
}

export default TopBar

import { Nullable } from 'models/helpers'
import { MonetaryValueDTO } from 'models/misc/monetaryValue'

/** Real estate property type */
export enum PropertyType {
  COMMERCIAL = 'COMMERCIAL',
  RESIDENTIAL = 'RESIDENTIAL'
}

export enum PropertyFloor {
  BASEMENT = 'BASEMENT',
  GROUND = 'GROUND',
  UPPER = 'UPPER',
  ATTIC = 'ATTIC',
}

/** Real estate property address */
export interface PropertyAddressDTO {
  /** Street name */
  street: string,
  /** Building number */
  streetNumber: string,
  /** Postal code */
  postalCode: string,
  /** City */
  city: string,
  /** Country */
  country: string,
  /** GEO coordinates of the address */
  coordinates?: {
    lat: number,
    lng: number,
  }
}

export interface PropertyFloorInfo {
  floor: PropertyFloor
  description: string
}

/** Real estate property DTO. */
export interface RealEstatePropertyPurchaseFlowDTO {
  /** Type of property - COMMERCIAL or RESIDENTIAL. */
  propertyType?: Nullable<PropertyType>,
  address?: Nullable<PropertyAddressDTO>
}

export interface RealEstatePropertyDTO {
  id: string
  brokerId: string
  title: string
  externalInfo: string
  address: PropertyAddressDTO
  floorInfo: PropertyFloorInfo
  objectReferenceId: string
  propertyType: PropertyType
  price: MonetaryValueDTO
  workspaceId: string
  orderIds: Array<string>
}

import { BEIGE_600, GRAY_700, GRAY_900, WHITE } from 'constants/styling/theme'
import { BadgeColor, MUIBadge } from 'components/common/MUIBadge'
import { FileRejection, useDropzone } from 'react-dropzone'
import { MessageAttachedFile, MessageType, ThreadMessage } from './ThreadMessage'
import { MessageDTO, MessageThreadDTO, MessageThreadState } from 'models/messageThreads'
import { Options, useFileAPI } from 'components/common/FileAPI'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import Box from '@mui/material/Box'
import { DraggableAreaView } from '../DraggableAreaView'
import { ImmutableMap } from 'models/helpers'
import { MUIDivider } from 'components/common/MUIDivider'
import { QueryStatus } from 'components/common/QueryStatus'
import { SectionedBorderBox } from 'components/common/SectionedBorderBox'
import Stack from '@mui/material/Stack'
import { ThreadInput } from './ThreadInput'
import Typography from '@mui/material/Typography'
import { acceptedMimetypesAny } from 'constants/misc'
import moment from 'moment-timezone'
import { useGetAssignmentDocumentFile } from 'dataQueries/assignmentDocuments.query'
import { useSnackbar } from 'components/contexts/SnackbarService.context'
import { useThreads } from '../context'
import { useTranslation } from 'react-i18next'

/** Represents an item in a thread message. */
export interface ThreadMessageItem extends MessageDTO {
  /** An optional file attached to the message. */
  attachedFilenames?: string[]
}

/** @interface Props for the Thread component extends. */
export interface Props extends MessageThreadDTO {
  /** ID of the assignment associated with the thread.  */
  assignmentId: string
  /** The messages in the thread. */
  messages: ThreadMessageItem[]
  /** Options for the file upload API handler. */
  fileUploadOptions: Options
}

const MAX_NUMBER_OF_FILES = 5

/** StatusBadge is a memoized component that renders a badge styled based on the provided thread status. */
const StatusBadge: React.FC<{ status: MessageThreadState }> = React.memo(({ status }) => {
  const { t } = useTranslation(['threads'])
  let color: BadgeColor

  switch (status) {
    case MessageThreadState.OPEN:
      color = 'green'
      break

    case MessageThreadState.CLOSED:
      color = 'gray'
      break

    default:
      color = 'green'
  }

  return <MUIBadge label={t(`status.${status}`)} color={color} size='sm' />
})

/**
 * @component
 * Thread component that represents a discussion thread.
 * 
 * @example
 * <Thread
 *   id="1"
 *   title="Discussion Thread"
 *   state="open"
 *   messages={[{ timestamp: new Date(), content: "Hello", attachedFiles: [], authorId: "123" }]}
 *   createdAt={new Date()}
 *   assignmentId="assignment-1"
 * />
 */
export const Thread: React.FC<Props> = ({
  id,
  title,
  state,
  messages,
  createdAt,
  assignmentId,
  fileUploadOptions,
}) => {
  const { t } = useTranslation(['threads', 'upload_files'])
  const { sendAssignmentMessageThread } = useThreads()
  const { spawnWarningToast } = useSnackbar()

  const [isLoading, setIsLoading] = useState(true)
  const [messageFiles, setMessageFiles] = useState<ImmutableMap<string, MessageAttachedFile[]>>(ImmutableMap([]))

  const scope = useMemo(() => `thread-${id.toString()}`, [id])

  /** Initializes a file upload handler for a thread component using the `useFileAPI` hook.  */
  const threadFileUpload = useFileAPI(scope, { ...fileUploadOptions })

  /**
   * Handles the drop event for file uploads.
   * 
   * This function performs the following tasks:
   * 1. Checks for file rejections and displays appropriate warnings if any.
   * 2. Filters out duplicate file names from the accepted files.
   * 3. Displays a warning if there are duplicate file names.
   * 4. Initiates the upload process for the accepted files.
   */
  const onDrop = useCallback(async (acceptedFiles: File[], fileRejections: FileRejection[]) => {

    const duplicateFileNames: string[] = []
    const okFiles: File[] = []

    if (fileRejections && fileRejections.length > 0) {
      if (fileRejections.length > 1) {
        spawnWarningToast(
          t('upload_files:unsupported_upload_max_number_files', { MAX_NUMBER_OF_FILES }),
          { timeout: 5000 }
        )
        return
      }

      const formats: string[] = []
      for (let fileRejection of fileRejections) {
        const split = fileRejection.file.name.split('.')
        if (split.length < 2) formats.push(fileRejection.file.name)
        else formats.push(split.slice(1).join('.'))
      }

      const formatsString = Array.from(new Set(formats).keys()).join(', ')
      spawnWarningToast(
        `${t('upload_files:unsupported_file_format')}\n${formatsString}`,
        { timeout: 5000 }
      )
    }

    for (let file of acceptedFiles) {
      if (!threadFileUpload.allFilesArray.find(foundFile => foundFile.originalFilename === file.name)) {
        okFiles.push(file)
      } else duplicateFileNames.push(file.name)
    }

    if (duplicateFileNames.length > 0) {
      spawnWarningToast(
        t('upload_files:duplicate_file_alert', { files: duplicateFileNames.join(', ') }),
        { timeout: 5000 }
      )
      return
    }

    threadFileUpload.uploadFiles(acceptedFiles)

  }, [spawnWarningToast, t, threadFileUpload])

  const { getRootProps, getInputProps, isDragActive, inputRef } = useDropzone({
    noClick: true,
    accept: acceptedMimetypesAny,
    maxFiles: MAX_NUMBER_OF_FILES,
    disabled: state === MessageThreadState.CLOSED,
    onDrop,
  })

  /** Handles the click event from ThreadInput submitting an attachment file. */
  const handleSubmitAttachmentClick = () => {
    if (inputRef.current) {
      inputRef.current.click()
    }
  }

  const assignmentDocumentFile = useGetAssignmentDocumentFile()

  // Store the message files into state to be used in the message component based on the message id.
  useEffect(() => {
    setIsLoading(true)
    if (messages.length > 0) {
      // Fetch all the message files urls.
      messages.forEach((message) => {
        if (message.attachmentFilenames.length === 0) return

        const fetchFiles = async () => {
          try {
            const promises = message.attachmentFilenames.map((filename) => assignmentDocumentFile.mutateAsync({ assignmentId, filename }))
            const results = await Promise.all(promises)

            const updatedFiles: MessageAttachedFile[] = results.map((result) => ({
              id: result.data.name,
              fileName: result.data.originalName,
              fileUrl: result.data.signedUrl.signedURL,
            }))

            // Store the files data in the state.
            setMessageFiles((prevItems) => prevItems.update(message.id, (items) => {
              if (!items) return updatedFiles
              const existingFileNames = new Set(items.map(item => item.fileName))
              const filteredUpdatedFiles = updatedFiles.filter(file => !existingFileNames.has(file.fileName))
              return [...items, ...filteredUpdatedFiles]
            }))

          } catch (error) {
            console.error('Error fetching files:', error)
          }
        }
        fetchFiles()
      })

      setIsLoading(false)
    }
    // Only care about the messages state.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages])


  return (
    <SectionedBorderBox
      borderColor={BEIGE_600}
      sx={{
        padding: 0,
        height: '100%',
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
      }}
      title={
        <Stack direction="row" justifyContent="space-between" padding="1rem 2rem 0">

          <Stack direction="row" alignItems="center" gap={0.5}>
            <Typography variant="text-md" fontWeight={600} color={GRAY_900}>{title}</Typography>

            {state && <StatusBadge status={state} />}
          </Stack>

          {createdAt &&
            <Stack direction="row" alignItems="center" gap={0.5}>
              <Typography variant="text-sm" fontWeight={500} color={GRAY_700}>
                {`${t('started')}:`}
              </Typography>

              <Typography variant="text-sm" color={GRAY_900}>
                {moment(createdAt).format('DD/MM/YYYY')}
              </Typography>

            </Stack>
          }

        </Stack>
      }
    >
      <Box position="relative" {...getRootProps()}>

        <Stack
          sx={{
            flexGrow: 1,
            maxHeight: '40rem',
            overflowY: 'auto',
            padding: '0 2rem 2rem 2rem',
            marginTop: '-1.2rem'
          }}
        >

          {/** MESSAGES */}
          {!isLoading && messages.length > 0 && messages.map(({ timestamp, content, authorId, id }) => {
            // Determine the type of message (sent or received). If there is no authorId, it is a received message.
            const type: MessageType = !authorId ? 'received' : 'sent'

            return (
              <Stack key={timestamp} gap={1.5} marginTop={1.5} alignItems={type === 'received' ? 'flex-start' : 'flex-end'}>

                <Typography variant="text-sm" color={GRAY_700} alignSelf={{ xs: 'center', xl: 'auto' }}>
                  {moment(timestamp).format('dddd DD MMM, HH:mm')}
                </Typography>

                <ThreadMessage
                  type={type}
                  message={content}
                  attachedFiles={messageFiles.get(id)}
                  width={{ xs: '95%', sm: '80%', md: '45rem' }}
                />

              </Stack>
            )
          })}
        </Stack>

        {/** MESSAGE ERROR (current thread) */}
        {sendAssignmentMessageThread.isError && sendAssignmentMessageThread.variables?.threadId === id &&
          <QueryStatus
            query={sendAssignmentMessageThread}
            showStates={['error']}
            onPurge={() => sendAssignmentMessageThread.reset()}
          />
        }

        {/** FOOTER */}
        {messages.length > 0 &&
          <Box sx={{
            bottom: 0,
            zIndex: 1,
            position: 'sticky',
            backgroundColor: WHITE,
            borderRadius: '0 0 .8rem .8rem',
          }}>
            <MUIDivider margin={2} />

            <Box padding={1}>
              <ThreadInput
                threadId={id}
                assignmentId={assignmentId ?? ''}
                isDisabled={state === MessageThreadState.CLOSED || isDragActive}
                attachedFiles={threadFileUpload.allFilesArray}
                isSendingMessage={sendAssignmentMessageThread.isPending && sendAssignmentMessageThread.variables?.threadId === id}
                onUploadClick={handleSubmitAttachmentClick}
                onDeleteFile={(filesId) => threadFileUpload.deleteFiles(filesId)}
                onPurgeFiles={() => threadFileUpload.allFilesArray.length > 0 && threadFileUpload.purgeFilesScope(scope)}
              />
            </Box>
          </Box>
        }

        {/** DRAG AND DROP OVERLAY (only if thread is OPEN) */}
        {isDragActive && <DraggableAreaView threadName={title} />}
        <input {...getInputProps()} />

      </Box>
    </SectionedBorderBox>
  )
}

import { FC, useMemo } from 'react'

import { AssignmentDocumentsEndpoint } from 'constants/API'
import { ClientGalleryDocumentTypeFilter } from '../ClientGalleryOrderTypeFilter/ClientGalleryDocumentTypeFilter.module'
import { DocumentUploadType } from 'constants/documents'
import { Options } from 'components/common/FileAPI'
import { RequestThread } from 'components/common/Threads'
import { Stack } from '@mui/material'
import { useClientGallery } from '../_main/contexts/ClientGallery.context'
import { useGetAssignmentMessageThreads } from 'dataQueries/messageThreads'
import { useTranslation } from 'react-i18next'

/**
 * Client gallery requests content to display the threads for each assignment.
 *
 * @example <ClientGalleryRequests />
 */
export const ClientGalleryRequests: FC = () => {

  const { t } = useTranslation(['threads'])

  const { assignmentId } = useClientGallery()

  const currentAssignmentThreads = useGetAssignmentMessageThreads(assignmentId)

  const assignmentThreadsData = useMemo(() => currentAssignmentThreads.data?.data, [currentAssignmentThreads?.data])

  const fileAPIOptions: Options = {
    uploadUrlResolver: (fileEntry, api) => {
      return api.post(
        AssignmentDocumentsEndpoint.GET_UPLOAD_URL,
        { assignmentId },
        {
          filename: fileEntry.fileObject.name,
          contentType: fileEntry.fileObject.type,
          type: DocumentUploadType.INPUT,
        },
        true
      )
    },
    deleteHandler: (fileEntry, api) => api.delete(
      AssignmentDocumentsEndpoint.DELETE_DOCUMENT,
      { assignmentId },
      true,
      {
        params: {
          type: DocumentUploadType.INPUT,
          filename: fileEntry.gcFilename,
        }
      }
    )
  }

  return (
    <Stack width="100%">

      <ClientGalleryDocumentTypeFilter />

      {/** Content changes according to the selected assignment from the tabs. */}
      <Stack width={{ sx: '100%', md: '80%' }} marginTop="3rem">

        <RequestThread
          assignmentId={assignmentId}
          title={t('request_docestate_title')}
          threadMessageItems={assignmentThreadsData}
          fileUploadOptions={fileAPIOptions}
        />

      </Stack>

    </Stack>
  )
}
